import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  CraftLeftWrapper,
  CraftRightWrapper,
  CraftingWrapper,
  SuccessfulCraftView
} from './styles';
import { CardPreviewSection, IdentityMatchListSection } from '../../../modules';
import {
  Button,
  CraftIdentityModal,
  IconArrowDown,
  IdentityCard
} from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { craftingIdentity } from '../../../actions';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context';
import { ICelebrity } from '../../../models/celebrity';
import { INftCardDayMonth } from '../../../models/nft_card_day_month';
import { INftCardYear } from '../../../models/nft_card_year';
import { INftCardCategory } from '../../../models/nft_card_category';
import { INftCardCrafting } from '../../../models/nft_card_crafting';
import { IdentityCraftSection } from '../../../modules/crafting/IdentityCraftSection';
import { CloseButton } from '../../../components/Modals/styles';
import { OpenPreview } from '../../../modules/crafting/styles';
import { getMyNftCardCrafting } from '../../../actions/nft_card_crafting';
import { getMyNftCardCategory } from '../../../actions/nft_card_category';
import { getMyNftCardYear } from '../../../actions/nft_card_year';
import { getMyNftCardDayMonth } from '../../../actions/nft_card_day_month';

export const CraftingIdentitesPage: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { authContext } = useAuthContext();
  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [selectedCraft, setSelectedCraft] = useState('dayMonth');

  const [clickedCraft, setClickedCraft] = useState('dayMonth');
  const [clickedCard, setClickedCard] = useState<number | string | null>(-1);
  const [selectedCard, setSelectedCard] = useState<number | string | null>(-1);
  const [selectedCards, setSelectedCards] = useState<{
    crafting: INftCardCrafting | null;
    year: INftCardYear | null;
    dayMonth: INftCardDayMonth | null;
    category: INftCardCategory | null;
  }>({
    crafting: null,
    category: null,
    dayMonth: null,
    year: null
  });

  const [confirm, setConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCelebrity, setSelectedCelebrity] = useState<ICelebrity | null>(
    null
  );
  const [craftedCard, setCraftedCard] = useState<any>(null);

  const [myNfts, setMyNfts] = useState<{
    crafting: INftCardCrafting[] | null;
    category: INftCardCategory[] | null;
    dayMonth: INftCardDayMonth[] | null;
    year: INftCardYear[] | null;
  }>({
    crafting: null,
    category: null,
    dayMonth: null,
    year: null
  });
  const [clickedNft, setClickedNft] = useState<
    INftCardCrafting | INftCardCategory | INftCardDayMonth | INftCardYear
  >();

  const [playVideo, setPlayVideo] = useState(false);
  const [rarity, setRarity] = useState<number>(0);

  const handleVideoEnded = () => {
    setPlayVideo(false);
  };

  useEffect(() => {
    const { category, crafting, dayMonth, year } = selectedCards;
    if (
      typeof category?.rarity === 'number' &&
      typeof crafting?.rarity === 'number' &&
      typeof dayMonth?.rarity === 'number' &&
      typeof year?.rarity === 'number'
    ) {
      const temp = Math.floor(
        (category?.rarity +
          crafting?.rarity +
          dayMonth?.rarity +
          year?.rarity) /
          4
      );
      setRarity(temp);
    }
  }, [selectedCards]);

  const loadMyNfts = async () => {
    try {
      let crafting = await getMyNftCardCrafting(null);
      if (!crafting.data) {
        throw 'Error getting crafting cards';
      }

      let category = await getMyNftCardCategory(null);
      if (!category.data) {
        throw 'Error getting category cards';
      }

      let dayMonth = await getMyNftCardDayMonth(null);
      if (!dayMonth.data) {
        throw 'Error getting day-month cards';
      }

      let year = await getMyNftCardYear(null);
      if (!year.data) {
        throw 'Error getting year cards';
      }

      setMyNfts({
        crafting: crafting.data,
        category: category.data,
        dayMonth: dayMonth.data,
        year: year.data
      });
    } catch (e: any) {
      toast.error(e);
    }
  };

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
    loadMyNfts();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (
      params.get('id') &&
      params.get('type') &&
      myNfts.crafting &&
      myNfts.category &&
      myNfts.dayMonth &&
      myNfts.year
    ) {
      const allNfts: any[] = [
        ...myNfts.crafting,
        ...myNfts.category,
        ...myNfts.dayMonth,
        ...myNfts.year
      ];
      const id = params.get('id');
      const type = params.get('type');
      for (const nft of allNfts) {
        if (Number(id) === nft.id) {
          if (type === 'dayMonth') {
            setSelectedCards((prev) => ({ ...prev, dayMonth: nft }));
            setSelectedCard(nft.id);
            return;
          } else if (type === 'year') {
            setSelectedCards((prev) => ({ ...prev, year: nft }));
            setSelectedCard(nft.id);
            return;
          } else if (type === 'category') {
            setSelectedCards((prev) => ({ ...prev, category: nft }));
            setSelectedCard(nft.id);
            return;
          } else if (type === 'crafting') {
            setSelectedCards((prev) => ({ ...prev, crafting: nft }));
            setSelectedCard(nft.id);
            return;
          } else return;
        }
      }
    }
  }, [location.search, myNfts]);

  const handleCardClick = (
    key: string | number | null,
    item: INftCardCrafting | INftCardCategory | INftCardDayMonth | INftCardYear
  ) => {
    if (key === clickedCard) {
      setClickedCard(-1);
      setClickedNft(undefined);
    } else {
      setClickedCard(key);
      setClickedNft(item);
      setClickedCraft(selectedCraft);
    }
  };

  const handleCardSelected = (id: string | number | null, craft: string) => {
    setSelectedCards((prev) => ({ ...prev, [craft]: id }));
    setSelectedCard(id);
  };

  const handleSelectCardCategory = (card: INftCardCategory) => {
    setSelectedCards((prev) => ({ ...prev, category: card }));
  };

  const handleSelectCardCrafting = (card: INftCardCrafting) => {
    setSelectedCards((prev) => ({ ...prev, crafting: card }));
  };

  const handleSelectCardDayMonth = (card: INftCardDayMonth) => {
    setSelectedCards((prev) => ({ ...prev, dayMonth: card }));
  };

  const handleSelectCardYear = (card: INftCardYear) => {
    setSelectedCards((prev) => ({ ...prev, year: card }));
  };

  const handleCraft = () => {
    // craftIdentity();
    setConfirm(true);
  };

  const craftIdentity = async () => {
    if (selectedCards.dayMonth === null) {
      toast.error('Select a Day-Month card');
      return;
    }

    if (selectedCards.year === null) {
      toast.error('Select a Year card');
      return;
    }

    if (selectedCards.category === null) {
      toast.error('Select a Category card');
      return;
    }

    if (selectedCards.crafting === null) {
      toast.error('Select a Crafting card');
      return;
    }

    let newCraft: {
      nft_card_day_month_id: number;
      nft_card_year_id: number;
      nft_card_crafting_id: number;
      nft_card_category_id: number;
      celebrity_id: number | null;
    } = {
      nft_card_day_month_id: Number(selectedCards.dayMonth.id),
      nft_card_year_id: Number(selectedCards.year.id),
      nft_card_crafting_id: Number(selectedCards.crafting?.id),
      nft_card_category_id: Number(selectedCards.category?.id),
      celebrity_id: null
    };

    if (selectedCelebrity !== null) {
      newCraft.celebrity_id = Number(selectedCelebrity?.id);
    }

    const res = await craftingIdentity(newCraft);
    if (res.success) {
      toast.success('Crafted Successfully.');
      setCraftedCard(res.data);
      setRarity(res.data.rarity);
      setConfirm(false);
      setPlayVideo(true);
    } else {
      toast.error(res.message);
    }
    return;
  };

  useEffect(() => {
    if (playVideo) {
      const timeout = setTimeout(() => {
        // setPlayVideo(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [playVideo]);

  const handleSelectCelebrity = (c: ICelebrity | null) => {
    setSelectedCelebrity(c);
  };

  return (
    <AppLayout noFooter>
      <OpenPreview onClick={() => setIsOpen(true)}>
        <IconArrowDown />
      </OpenPreview>

      <CraftIdentityModal
        selectCelebrity={handleSelectCelebrity}
        open={confirm}
        onClose={() => setConfirm(false)}
        onCraft={craftIdentity}
        selectedCards={selectedCards}
        selectedCelebrity={selectedCelebrity}
        rarity={rarity}
      />

      {playVideo ? (
        <video
          width="100%"
          height="100%"
          playsInline
          autoPlay
          onEnded={handleVideoEnded}
        >
          {rarity === 0 && (
            <>
              <source src="/assets/videos/identity-core.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </>
          )}
          {rarity === 1 && (
            <>
              <source
                src="/assets/videos/identity-uncommon.mp4"
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </>
          )}
          {rarity === 2 && (
            <>
              <source src="/assets/videos/identity-rare.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </>
          )}
        </video>
      ) : (
        <>
          {craftedCard ? (
            <SuccessfulCraftView>
              <h2>Craft Successful</h2>
              <div className="card-container">
                <IdentityCard
                  day={craftedCard?.day}
                  month={craftedCard?.month}
                  category={craftedCard?.category}
                  rarity={craftedCard?.rarity}
                  year={craftedCard?.year}
                  icon={craftedCard?.icon}
                  iconText={craftedCard?.iconText}
                  celebrity_name={craftedCard?.celebrity_name}
                  image={craftedCard?.image}
                  cardType="identity"
                />
              </div>
              <div className="button-wrapper" style={{ paddingTop: '12px' }}>
                <Button
                  className="button"
                  onClick={() => navigate('/dashboard/identities')}
                >
                  See Inventory
                </Button>
                <Button
                  className="button"
                  onClick={() => {
                    setCraftedCard(null);
                    navigate(0);
                  }}
                >
                  Craft Again
                </Button>
              </div>
            </SuccessfulCraftView>
          ) : (
            <CraftingWrapper>
              {authContext?.isAuthenticated ? (
                <>
                  <CraftLeftWrapper>
                    <IdentityCraftSection
                      onCraft={handleCraft}
                      onCraftChanged={setSelectedCraft}
                      selectedCards={selectedCards}
                      selectedCraft={selectedCraft}
                      clickedCard={clickedCard}
                      selectedCard={selectedCard}
                      onCardClicked={handleCardClick}
                      onSelectCardCrafting={handleSelectCardCrafting}
                      onSelectCardCategory={handleSelectCardCategory}
                      onSelectCardDayMonth={handleSelectCardDayMonth}
                      onSelectCardYear={handleSelectCardYear}
                      myNfts={myNfts}
                      setMyNfts={setMyNfts}
                    />
                    {/* <IdentitySelectCardSection
                  clickedCard={clickedCard}
                  selectedCard={selectedCard}
                  selectedCraft={selectedCraft}
                  onCardClicked={handleCardClick}
                  onSelectCardCrafting={handleSelectCardCrafting}
                  onSelectCardCategory={handleSelectCardCategory}
                  onSelectCardDayMonth={handleSelectCardDayMonth}
                  onSelectCardYear={handleSelectCardYear}
                  myNfts={myNfts}
                  setMyNfts={setMyNfts}
                /> */}
                  </CraftLeftWrapper>
                  <CraftRightWrapper open={isOpen ? 'true' : undefined}>
                    <CloseButton
                      className="close-button"
                      onClick={() => setIsOpen(false)}
                    >
                      &times;
                    </CloseButton>
                    <IdentityMatchListSection
                      page="identity"
                      myNfts={myNfts}
                      selectedCards={selectedCards}
                      onSelectCardDayMonth={handleSelectCardDayMonth}
                      onSelectCardYear={handleSelectCardYear}
                      onSelectCardCategory={handleSelectCardCategory}
                    />
                    <CardPreviewSection
                      page="identity"
                      clickedCard={clickedCard}
                      selectedCraft={clickedCraft}
                      clickedNft={clickedNft}
                      myNfts={myNfts}
                    />
                  </CraftRightWrapper>
                </>
              ) : (
                <div className="unAuth-display">
                  <p>
                    Identities are cards crafted by combining a Day-Month card,
                    a Year card and a Category card
                  </p>
                  <h4>Log in to start playing.</h4>
                  <Button
                    className="login-button"
                    onClick={() => navigate('/signin')}
                  >
                    Login Now
                  </Button>
                </div>
              )}
            </CraftingWrapper>
          )}
        </>
      )}
    </AppLayout>
  );
};
