import { useState, useEffect, useRef } from 'react';
import { ClaimModalProps } from '../../typeroot/types';
import { Modal as ModalWrapper } from './Modal';
import { ButtonGroup, ClaimModalWrapper, ClaimSwiper } from './styles';
import { Button } from '../Button';
import { PredictionModalCard } from '../PredictionCard/PredictionModalCard';
import { submitClaim } from '../../actions';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { TriggerCard } from '../TriggerCard';

export const ClaimSubmitModal: React.FC<ClaimModalProps> = ({
  open,
  onClose,
  cardPrediction
}) => {
  const [triggerId, setTriggerId] = useState(-1);
  const [isClaimSubmitted, setIsClaimSubmitted] = useState(false);
  // const [readyConfirmed, setReadyConfirmed] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [message, setMessage] = useState('');
  const [claimStatus, setClaimStatus] = useState('');
  const apiRef = useRef(false);

  useEffect(() => {
    if (!cardPrediction?.nft_card_triggers) {
      return;
    }

    if (
      cardPrediction.nft_card_triggers?.length === 1 &&
      cardPrediction.nft_card_triggers[0]?.id
    ) {
      setTriggerId(cardPrediction.nft_card_triggers[0].id);
    } else if (cardPrediction.nft_card_triggers?.length > 1) {
      setTriggerId(cardPrediction.nft_card_triggers[activeSlide].id!);
    }
  }, [cardPrediction, activeSlide]);

  const handleClaim = async (predictionId: number, triggerId: number) => {
    if (apiRef.current) return;
    apiRef.current = true;
    const res = await submitClaim(predictionId, triggerId);
    apiRef.current = false;
    if (res.success) {
      setClaimStatus('Claim Submitted');
      setMessage(
        'Your claim was submitted successfully, and will be reviewed shortly.'
      );
    } else {
      setClaimStatus('Claim Failed');
      setMessage('You claim was not submitted. Please try again');
    }
    setIsClaimSubmitted(true);
  };

  const handleSubmitButton = () => {
    if (cardPrediction?.id && triggerId !== -1) {
      handleClaim(cardPrediction.id, triggerId);
    }
  };

  console.log('cardPrediction', cardPrediction);
  return (
    <>
      {cardPrediction?.nft_card_triggers &&
        cardPrediction.nft_card_triggers?.length > 1 && (
          <ModalWrapper open={open} onClose={onClose}>
            <ClaimModalWrapper>
              <div>
                <h3>{isClaimSubmitted ? claimStatus : 'Submit Claim'}</h3>
                <p>
                  {isClaimSubmitted
                    ? message
                    : `Your identity has multiple Triggers. Pick the one you would
                  like to claim.`}
                </p>
              </div>
              {!isClaimSubmitted &&
                cardPrediction.nft_card_triggers?.length > 1 && (
                  <ClaimSwiper>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={5}
                      navigation={true}
                      modules={[Navigation]}
                      onSlideChange={(swiper) =>
                        setActiveSlide(swiper.activeIndex)
                      }
                      // breakpoints={{
                      //   640: {
                      //     slidesPerView: 2,
                      //     spaceBetween: 20,
                      //   },
                      //   768: {
                      //     slidesPerView: 2,
                      //     spaceBetween: 30,
                      //   },
                      //   1024: {
                      //     slidesPerView: 2,
                      //     spaceBetween: 42,
                      //   },
                      // }}
                      className="claimSwiper"
                    >
                      {cardPrediction.nft_card_triggers.map(
                        (nftCardTrigger, key) => (
                          <SwiperSlide key={key}>
                            <TriggerCard
                              image=""
                              tier={nftCardTrigger?.tier || ''}
                              trigger={nftCardTrigger.trigger || ''}
                              rarity={nftCardTrigger.rarity || 0}
                              item={nftCardTrigger}
                              {...nftCardTrigger}
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </ClaimSwiper>
                )}
              <ButtonGroup>
                {isClaimSubmitted && (
                  <Button
                    onClick={() => {
                      setClaimStatus('');
                      setMessage('');
                      setIsClaimSubmitted(false);
                      onClose();
                    }}
                  >
                    Done
                  </Button>
                )}
                {!isClaimSubmitted && (
                  <>
                    <Button onClick={handleSubmitButton}>Confirm</Button>
                    <Button
                      onClick={() => {
                        setClaimStatus('');
                        setMessage('');
                        setIsClaimSubmitted(false);
                        onClose();
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </ClaimModalWrapper>
          </ModalWrapper>
        )}
      {cardPrediction?.nft_card_triggers?.length === 1 && (
        <ModalWrapper open={open} onClose={onClose} width={391}>
          <ClaimModalWrapper>
            <div>
              <h3>{isClaimSubmitted ? claimStatus : 'Submit Claim'}</h3>
              <p>
                {isClaimSubmitted
                  ? message
                  : `You are submitting a claim of ${cardPrediction?.nft_card_triggers?.find(
                      (value) => value.id === triggerId
                    )?.trigger} for this Prediction`}
              </p>
            </div>
            <ButtonGroup>
              {isClaimSubmitted && (
                <Button
                  onClick={() => {
                    setClaimStatus('');
                    setMessage('');
                    setIsClaimSubmitted(false);
                    onClose();
                  }}
                >
                  Done
                </Button>
              )}
              {!isClaimSubmitted && (
                <>
                  <Button onClick={handleSubmitButton}>Confirm</Button>
                  <Button
                    onClick={() => {
                      setClaimStatus('');
                      setMessage('');
                      setIsClaimSubmitted(false);
                      onClose();
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </ButtonGroup>
          </ClaimModalWrapper>
        </ModalWrapper>
      )}
    </>
  );
};
