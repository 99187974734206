import React, { useState } from 'react';
import { TrashIcon, PencilAlt, IconArrowUp } from '../../../../../components';
import {
  Card,
  CardHeader,
  CardBody,
  CardTable,
  CardTableHead,
  CardTableBody,
  CardTableTr,
  CardTableTd,
  CardTableTh,
  SaveButton
} from '../styles';
import { ICardCollection } from '../../../../../models/collection';

// Define the row item type
type RowItem = {
  rowId: number;
  triggerType: string;
  prizePoolAmount: number;
};

// CollapsibleRow component for each row
const CollapsibleRow = ({
  rowData,
  isEditing,
  startEditing,
  updateRowData
}: {
  rowData: RowItem;
  isEditing: boolean;
  startEditing: (rowId: number | null) => void;
  updateRowData: (rowId: number, updatedData: RowItem) => void;
}) => {
  const { rowId, triggerType, prizePoolAmount } = rowData;

  // Initialize form data with the prizePoolAmount
  const [formData, setFormData] = useState({
    prizePoolAmount: prizePoolAmount
  });

  // Handle input change
  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // Handle save button click
  const handleSave = () => {
    // Update the parent component's state with the modified data
    updateRowData(rowId, {
      ...rowData,
      prizePoolAmount: formData.prizePoolAmount
    });
    startEditing(null);
  };

  return (
    <CardTableTr>
      <CardTableTd>{triggerType}</CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-number"
            type="number"
            value={formData?.prizePoolAmount}
            onChange={handleSelectChange}
            name="prizePoolAmount"
          />
        ) : (
          prizePoolAmount
        )}
      </CardTableTd>
      <CardTableTd className="col-action">
        {isEditing ? (
          <>
            <SaveButton className="ml-auto" onClick={handleSave}>
              Save
            </SaveButton>
          </>
        ) : (
          <>
            <button
              className="svg-btn ml-auto"
              onClick={() => startEditing(rowId)}
            >
              <PencilAlt />
            </button>
          </>
        )}
      </CardTableTd>
    </CardTableTr>
  );
};

// TriggerPrizePool component that contains the list of rows
const TriggerPrizePool = ({ collection }: { collection: ICardCollection }) => {
  const [editingRow, setEditingRow] = useState<number | null>(null);

  // Sample data for your rows
  const rowDataList1: RowItem[] = [
    {
      rowId: 1,
      triggerType: 'Minor Tier 1',
      prizePoolAmount: 6
    },
    {
      rowId: 2,
      triggerType: 'Minor Tier 2',
      prizePoolAmount: 13
    },
    {
      rowId: 3,
      triggerType: 'Major 1',
      prizePoolAmount: 6
    },
    {
      rowId: 4,
      triggerType: 'Major 2',
      prizePoolAmount: 13
    }
  ];

  // Update row data in the parent component's state
  const updateRowData = (rowId: number, updatedData: RowItem) => {
    // Find the index of the row to be updated
    const rowIndex = rowDataList.findIndex(
      (rowData) => rowData.rowId === rowId
    );

    if (rowIndex !== -1) {
      // Create a copy of the original data array
      const updatedDataList = [...rowDataList];
      // Update the specific row with the modified data
      updatedDataList[rowIndex] = updatedData;

      // Update the state with the new data
      setRowDataList(updatedDataList);
    }
  };

  // State to hold the row data
  const [rowDataList, setRowDataList] = React.useState(rowDataList1);

  return (
    <Card>
      <CardHeader>
        Trigger prize pool amounts <IconArrowUp />
      </CardHeader>
      <CardBody>
        <CardTable>
          <CardTableHead>
            <CardTableTr>
              <CardTableTh className="TPPool-th">TRIGGER TYPE</CardTableTh>
              <CardTableTh className="TPPool-th">Prize Pool Amount</CardTableTh>
              <CardTableTh></CardTableTh>
            </CardTableTr>
          </CardTableHead>
          <CardTableBody>
            {rowDataList.map((rowData) => (
              <CollapsibleRow
                key={rowData.rowId}
                rowData={rowData}
                isEditing={rowData.rowId === editingRow}
                startEditing={setEditingRow}
                updateRowData={updateRowData}
              />
            ))}
          </CardTableBody>
        </CardTable>
      </CardBody>
    </Card>
  );
};

export default TriggerPrizePool;
