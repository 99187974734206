import React, { useState, useEffect } from 'react';
import { ViewDateCardProps } from '../../../typeroot/types';
import {
  CloseButton,
  PreviewCardWrapper,
  PropertiesContent,
  PropertiesHeader,
  PropertiesWrapper,
  PropertyItem,
  ViewDateCardContainer,
  ViewDateCardWrapper
} from './styles';
import {
  DateCard,
  IconArrowDown,
  PredictionCard,
  CardPack,
  IdentityCard,
  CraftingCard
} from '../../../components';
import { TriggerCard } from '../../../components/TriggerCard';
import { CategoryCard } from '../../../components/CategoryCard';
import {
  useMonthContext,
  useTriggersContext,
  useTriggersByNameContext
} from '../../../context';
import { ITrigger } from '../../../models/trigger';

export const ViewDateCardSection: React.FC<ViewDateCardProps> = ({
  item,
  cardType,
  isView,
  onClose
}) => {
  const { monthContext } = useMonthContext();
  const { triggersContext } = useTriggersContext();
  const { triggersByNameContext } = useTriggersByNameContext();
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedTwo, setCollapsedTwo] = useState(false);

  let triggerCategories: any = null;
  if (item && triggersByNameContext && item.trigger) {
    triggerCategories = (triggersByNameContext as Map<any, any>).get(
      item?.trigger
    );
  }

  const [filteredTriggers, setFilteredTriggers] = useState<ITrigger[] | null>(
    null
  );

  useEffect(() => {
    if (item) {
      if (cardType === 'prediction') {
        let filtered: ITrigger[] = [];
        if (triggersContext) {
          (triggersContext as Map<number, ITrigger>).forEach((v: ITrigger) => {
            if (item) {
              if (item.triggers) {
                if (item.triggers.includes(v.name)) {
                  filtered.push(v);
                }
              }
            }
          });
          if (filtered.length !== 0) {
            setFilteredTriggers(filtered);
          }
        }
      }
    }
  }, [triggersContext, item]);

  const collapseMenu = (state: boolean, setState: any) => {
    setState(!state);
  };

  const cardSeriesNew: any = item;

  const coreCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['0']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['0']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['0']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['0']?.year) || 0);

  const uncommonCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['1']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['1']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['1']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['1']?.year) || 0);

  const rareCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['2']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['2']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['2']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['2']?.year) || 0);

  return (
    <ViewDateCardWrapper
      isview={isView ? 'true' : undefined}
      key={`view-date-card-${item?.id}`}
    >
      <ViewDateCardContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>
          View{' '}
          {cardType === 'trigger'
            ? 'Trigger'
            : cardType === 'identity'
            ? 'Identity'
            : cardType === 'prediction'
            ? 'Prediction'
            : cardType === 'category'
            ? 'Category'
            : cardType === 'cardPacks'
            ? 'Card Pack'
            : cardType === 'crafting'
            ? 'Crafting'
            : 'Date Card'}
        </h2>
        <PreviewCardWrapper>
          {cardType === 'trigger' ? (
            <TriggerCard
              tier={item?.tier}
              image={item?.image}
              trigger={item?.trigger}
              rarity={item?.rarity}
              isNotHover={true}
            />
          ) : cardType === 'identity' ? (
            <IdentityCard
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              year={item?.year}
              icon={item?.icon}
              iconText={item?.iconText}
              celebrity_name={item?.celebrity_name}
              image={item?.image}
              cardType={cardType}
            />
          ) : cardType === 'prediction' ? (
            <PredictionCard
              item={item}
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              year={item?.year}
              icon={item?.icon}
              iconText={item?.iconText}
              celebrity_name={item?.celebrity_name}
              image={item?.image}
              cardType={cardType}
            />
          ) : cardType === 'category' ? (
            <CategoryCard
              item={item}
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              image={item?.image}
              position=""
            />
          ) : cardType === 'cardPacks' ? (
            <CardPack
              item={item}
              rarity={item?.rarity}
              image={item?.image}
              tier={item?.tier}
            />
          ) : cardType === 'crafting' ? (
            <CraftingCard
              item={item}
              day={item?.day}
              month={item?.month}
              image={item?.image}
              category={item?.category}
              rarity={item?.rarity}
              isNotHover={true}
              position=""
            />
          ) : (
            <DateCard
              item={item}
              day={item?.day}
              month={item?.month}
              image={item?.image}
              category={item?.category}
              rarity={item?.rarity}
              isNotHover={true}
              position=""
            />
          )}
        </PreviewCardWrapper>
        <PropertiesWrapper>
          <PropertiesHeader
            onClick={() => collapseMenu(collapsed, setCollapsed)}
          >
            <span>Properties</span>
            <div className={collapsed ? 'collapsed' : ''}>
              <IconArrowDown />
            </div>
          </PropertiesHeader>
          {!collapsed && (
            <PropertiesContent>
              {cardType === 'prediction' || cardType === 'identity' ? (
                <PropertyItem>
                  <p>Celebrity</p>
                  <span>{item?.celebrity_name}</span>
                </PropertyItem>
              ) : null}

              <PropertyItem>
                <p>{cardType === 'cardPacks' ? 'Pack Type' : 'Rarity'}</p>
                <span>
                  {(item?.rarity === 0 && 'Core') ||
                    (item?.tier === 0 && 'Standard')}
                  {(item?.rarity === 1 && 'Uncommon') ||
                    (item?.tier === 1 && 'Premium')}
                  {(item?.rarity === 2 && 'Rare') ||
                    (item?.tier === 2 && 'Elite')}
                </span>
              </PropertyItem>

              {cardType === 'identity' ? (
                <PropertyItem>
                  <p>Month/Day</p>
                  <span>{`${
                    monthContext &&
                    (monthContext as Map<number, string>).get(item?.month)
                  } ${item?.day}`}</span>
                </PropertyItem>
              ) : cardType === 'date' ? (
                <PropertyItem>
                  <p>Type</p>
                  <span>{item?.day ? 'Month/Day' : 'Year'}</span>
                </PropertyItem>
              ) : null}

              {cardType === 'trigger' ? (
                <PropertyItem>
                  <p>Tier</p>
                  <span>{item?.tier}</span>
                </PropertyItem>
              ) : cardType === 'identity' ? (
                <PropertyItem>
                  <p>Year</p>
                  <span>{item?.year}</span>
                </PropertyItem>
              ) : cardType === 'date' ? (
                <PropertyItem>
                  <p>{item?.day ? 'Month/Day' : 'Year'}</p>
                  <span>
                    {item?.day
                      ? `${monthContext.get(item?.month) || ''} ${item?.day}`
                      : item?.year}
                  </span>
                </PropertyItem>
              ) : cardType === 'category' ? (
                <PropertyItem>
                  <p>Category</p>
                  <span>{item?.category}</span>
                </PropertyItem>
              ) : null}

              {/* <PropertyItem>
                <p>
                  {cardType === 'trigger'
                    ? 'Trigger'
                    : cardType === 'identity'
                    ? 'Category'
                    : cardType === 'cardPacks'
                    ? 'Collection'
                    : ''}
                </p>
                <span>
                  {cardType === 'trigger'
                    ? item?.trigger
                    : cardType === 'identity'
                    ? item?.category
                    : item?.card_series_id === 1
                    ? 'Genesis'
                    : ''}
                </span>
              </PropertyItem> */}

              {cardType === 'trigger' && (
                <PropertyItem>
                  <p>Trigger</p>
                  <span>{item?.trigger}</span>
                </PropertyItem>
              )}

              <PropertyItem>
                <p>Collection</p>
                <span>Genesis</span>
              </PropertyItem>

              {cardType === 'prediction' && filteredTriggers && (
                <>
                  <PropertiesHeader noborder={'true'}>
                    <span>Triggers</span>
                    <span>{filteredTriggers.length}</span>
                  </PropertiesHeader>
                  {filteredTriggers.map((item: ITrigger, key: number) => (
                    <PropertyItem key={key} nfttrigger={'true'}>
                      <p>{item.tier}</p>

                      <span>{item.name}</span>
                    </PropertyItem>
                  ))}
                </>
              )}
            </PropertiesContent>
          )}
          {cardType === 'cardPacks' && (
            <>
              <PropertiesHeader onClick={() => setCollapsedTwo(!collapsedTwo)}>
                <span>Pack Contents</span>
                <div className={collapsedTwo ? 'collapsed' : ''}>
                  <IconArrowDown />
                </div>
              </PropertiesHeader>
              {!collapsedTwo && (
                <PropertiesContent>
                  <PropertyItem>
                    <p>{rareCardInfo} Rare Cards</p>
                  </PropertyItem>
                  <PropertyItem>
                    <p>{uncommonCardInfo} Uncommon Cards</p>
                  </PropertyItem>
                  <PropertyItem>
                    <p>{coreCardInfo} Core Cards</p>
                  </PropertyItem>
                  <PropertyItem>
                    <p>{item?.crafting_cards} Crafting Card</p>
                  </PropertyItem>
                </PropertiesContent>
              )}
            </>
          )}
        </PropertiesWrapper>
      </ViewDateCardContainer>
    </ViewDateCardWrapper>
  );
};
