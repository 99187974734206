import React from 'react';
import { AuthLayout } from '../../../layout';
import {
  TermWrapper,
  TermPageTitle,
  TermPageContainer,
  TermPageContent,
  TermPeragraph,
  TermSubUnderlineHeading
} from './styles';
export const TermConditions: React.FC = () => {
  return (
    <AuthLayout>
      <TermWrapper>
        <TermPageContainer>
          <TermPageTitle>
            <h3>Term conditions</h3>
          </TermPageTitle>
          <TermPageContent>
            <h4>Last Updated on October 8th, 2023</h4>

            <TermPeragraph>
              <p>
                Welcome to the Terms of Use (these <strong>“Terms”</strong>) for
                the website www.twotwentyk.io (the <strong>“Website”</strong>),
                a gaming platform for, among other things, prediction games,
                lineup games and collection games (the{' '}
                <strong>“Platform”</strong>), and all other applications,
                content, smart contracts, tools, features, and functionality
                offered on or through the Website and the Platform (collectively
                with the Website and the Platform, the{' '}
                <strong>“Services”</strong>), in each case operated by or on
                behalf of 10XChi, Inc.{' '}
                <strong>(“10XChi,” “Company,” “we,” “us,” or “our”)</strong>.
              </p>
              <p>
                BY (I) USING THE SERVICES OR ANY PART OF THEM, OR <br /> (II)
                BUYING, SELLING OR OTHERWISE ACQUIRING OR TRANSFERRING A
                COLLECTIBLE (AS DEFINED BELOW), YOU ARE CONFIRMING THAT YOU
                UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
              </p>
              <p>
                Any changes to these Terms will be in effect as of the “Last
                Updated” date referred to at the top of this page. You should
                review these Terms before using the Services or purchasing any
                Collectible. Your continued use of the Services after the “Last
                Updated” date will constitute your acceptance of and agreement
                to such changes.
              </p>
              <br />
              <br />
              <p>
                By signing up for an Account (as defined in Section 5) on the
                Platform, connecting your cryptocurrency wallet to the Website,
                using a custodial cryptocurrency wallet provided by Us, or
                otherwise using or accessing the Services, you acknowledge that
                you have read and agree to these Terms and any terms and
                conditions incorporated herein by reference, and affirm that you
                are of legal age to enter into these Terms, that you accept and
                are bound by these Terms, and if you are using the Services on
                behalf of an organization or company, that you have the legal
                authority to bind any such organization or company to these
                Terms.
              </p>
              <p>
                THESE TERMS CONTAIN AN ARBITRATION AND CLASS ACTION WAIVER
                PROVISION (SEE SECTION 17). PLEASE REVIEW THE ARBITRATION AND
                CLASS ACTION WAIVER PROVISION CAREFULLY, SINCE IT AFFECTS YOUR
                RIGHTS. BY USING THE SERVICES OR ANY PART OF THEM, YOU
                UNDERSTAND AND AGREE TO BE BOUND BY THE ARBITRATION AND CLASS
                ACTION WAIVER PROVISION.
              </p>
              <p>
                You may not use the Services if you: (i) do not agree to these
                Terms; (ii) are under the age of 18; or (iii) are prohibited
                from accessing or using the Services or any of this Platform’s
                contents, products or services by applicable law.
              </p>
            </TermPeragraph>

            <TermPeragraph>
              <h4>1. DEFINITIONS</h4>
              The following definitions are used in these Terms: (i){' '}
              <strong>“Collectibles”</strong> means digital assets created by us
              or you, the ownership of which is tracked via the Polygon
              blockchain, and the ownership of which will give you certain
              rights and privileges within the Services.
              <br />
              (ii) <strong>"Content"</strong> means content, materials, art,
              design, and drawings, in any form or media, including, without
              limitation, images, photographs, audio, or videos.
              <br />
              (iii) <strong>“Marketplace”</strong> means the online marketplace
              offered through the Platform, which provides various Marketplace
              Offerings.
              <br />
              (iv) <strong>“Marketplace Offerings”</strong> include various
              Services-related offerings, including, but not limited to, the
              facilitation of peer-to-peer sales conducted via decentralized or
              third-party exchanges or protocols whereby buyers and sellers can
              buy, sell, and trade Collectibles with each other.
              <br />
              (v) <strong>“NFTs”</strong> means non-fungible tokens.
              <br />
            </TermPeragraph>
            <TermPeragraph>
              <h4>2. SERVICES; USE OF THE PLATFORM; SECURITY</h4>
              <TermSubUnderlineHeading>
                (i) Description of the Services.
              </TermSubUnderlineHeading>
                The Services leverage a polygon blockchain-enabled game , and as
              further described on the Website.
              <br />
              <TermSubUnderlineHeading>
                (ii) Collectibles.
              </TermSubUnderlineHeading>
               You can purchase, earn, collect, showcase, transfer, and trade
              Collectibles, and use them in the Platform in various ways that we
              make available on the Platform. We make no promises or guarantees
              regarding the functionality, utility, or value of any
              Collectibles, and such Collectibles may evolve and change as we
              update and modify the Services and the Platform.  You can also buy
              Collectibles from other Collectible owners within the Platform.
              You are responsible for all payments, fees, and costs when
              engaging in any transactions involving Collectibles, including,
              without limitation, the purchase price, transaction fees, fees
              associated with your secondary or subsequent sales of
              Collectibles, and all other fees associated with your use of the
              Collectibles via the Services.
              <br />
              <br />
              We reserve the right to modify, delete, and/or replace the Content
              linked to, or associated with, your Collectibles at any time with
              or without notice, as follows
              <br /> (i) adding or removing third party brand names, logos, or
              other intellectual property; <br />
              (ii) updating factual information; (iii) changing designations
              associated with the Collectible; (iv) modifications at the request
              or demand of a third party; or (v) correcting any errors in the
              Content.
              <br />
              <TermSubUnderlineHeading>
                (iii) Marketplace.
              </TermSubUnderlineHeading>
                We provide the Marketplace to enable Marketplace Offerings. We
              may charge one or more fees, including but not limited to a
              transaction fee and a prize pool replenishment fee on all
              peer-to-peer sales that occur via the Marketplace or that
              otherwise involve Collectibles (which may be calculated as a
              percentage of the overall sale price of the Collectibles). To
              facilitate transactions via the Marketplace, we may integrate with
              one or more third-party exchanges and services, or decentralized
              smart contract protocols. These protocols facilitate the exchange
              of cryptocurrency and other assets through the Polygon blockchain.
              We do not own or control these third-party or decentralized
              services, or the Polygon blockchain, we do not control the
              transfer of cryptocurrency using these third-party services or
              decentralized protocols, and we do not have the ability to cancel
              or reverse certain transactions. Except as may otherwise be
              provided in these Terms, we do not control or endorse purchases or
              sales of Collectibles or other Marketplace Offerings outside of
              the Services. With respect to Collectibles that are not sold
              directly by us, we are not a party to any agreement entered into
              between an end user buyer and a seller on the Marketplace. Other
              than with respect to items sold directly by us, we have no control
              over the conduct of buyers, sellers, and any other users of the
              Marketplace. We accept no liability for any aspect of the buyer
              and seller peer-to-peer interaction on the Marketplace.
              <br />
              <TermSubUnderlineHeading>
                (iv) Promotional Codes.
              </TermSubUnderlineHeading>
               We may offer certain promotional codes, referral codes, discount
              codes, coupon codes, or similar offers{' '}
              <strong>(“Promotional Codes”)</strong> that may be redeemed for
              discounts on future Marketplace Offerings, or other features or
              benefits related to the Services, subject to any additional terms
              that we establish. You agree that Promotional Codes: (a) must be
              used in a lawful manner; (b) must be used for the intended
              audience and purpose; (c) may not be duplicated, sold, or
              transferred in any manner, or made available by you to the general
              public (whether posted to a public forum, coupon collecting
              service, or otherwise), unless expressly permitted by us; (d) may
              be disabled or have additional conditions applied to them by us,
              at any time for any reason without liability to us; (e) may only
              be used pursuant to the specific terms that we establish for such
              Promotional Code; (f) are not valid for cash or other credits or
              points; and (g) may expire prior to your use.
              <br />
              <TermSubUnderlineHeading>
                (v) Changes and Pricing.
              </TermSubUnderlineHeading>
               We may, at any time, revise or change the pricing, availability,
              specifications, content, descriptions, or features of any
              Marketplace Offerings sold by us, and to correct pricing errors
              that may inadvertently occur (and to cancel any orders in our sole
              discretion that were purchased with pricing errors). All such
              changes shall be effective immediately upon posting of such new
              Marketplace Offerings prices to the Platform. While we attempt to
              be as accurate as we can in our descriptions for Marketplace
              Offerings, we do not warrant that Marketplace Offerings
              descriptions are accurate, complete, reliable, current, or
              error-free. The inclusion of any Marketplace Offerings for
              purchase through the Services at a particular time does not imply
              or warrant that the Marketplace Offerings will be available at any
              other time. We reserve the right to change prices for Marketplace
              Offerings displayed on the Services at any time.
              <br />
              <TermSubUnderlineHeading>
                (vi) Purchases Made for Consumptive Use.
              </TermSubUnderlineHeading>
               You acknowledge and affirm that you are acquiring Marketplace
              Offerings, whether through the Marketplace or otherwise through
              the Services, for purposes of use in connection with the Services
              and, in the case of Collectibles, to play the game or for other
              collectible purposes, and in each case not for any investment or
              speculative purposes. Any economic benefit that may be derived
              from appreciation in the value of an Offering or Collectible is
              incidental to obtaining it for its use in connection with the
              Services or its collectible purpose.
              <br />
              <TermSubUnderlineHeading>
                {' '}
                (vii) ALL SALES FINAL.
              </TermSubUnderlineHeading>
               ALL PURCHASES MADE THROUGH THE SERVICES ARE FINAL AND
              NON-REFUNDABLE, EXCEPT AS REQUIRED BY APPLICABLE LAW OR REGULATION
              (AND IN SUCH INSTANCES WHERE REQUIRED BY APPLICABLE LAW, OUR
              ABILITY TO ISSUE ANY SUCH REFUND IS LIMITED TO WHETHER THE
              APPLICABLE SMART CONTRACT ENABLES US TO REASONABLY REVERSE OR
              CANCEL A TRANSACTION). NEVERTHELESS, YOU ACKNOWLEDGE THAT WE DO
              NOT IN MOST CASES HAVE THE ABILITY TO REVERSE OR CANCEL A
              TRANSACTION AND IS NOT REQUIRED OR OBLIGATED TO PROVIDE A REFUND
              OF ANY PURCHASE FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE
              MONEY OR OTHER COMPENSATION FOR TIME, ITEMS, AMOUNTS, OR VALUES IN
              A GAME ACCOUNT (INCLUDING ANY UNUSED OR PARTIAL USED MEMBERSHIP
              TIME), EVEN IF AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
              VOLUNTARY OR INVOLUNTARY.
              <br />
              <TermSubUnderlineHeading>
                (viii) Digital Wallet.
              </TermSubUnderlineHeading>
              To use certain of the Services, you need to link to the Services
              via a non-custodial digital wallet made available by a third party
              and that is compatible with the Polygon network and the Services
              (a <strong>“Digital Wallet”</strong>). A list of supported wallets
              will be displayed on the Website. Linking a Digital Wallet to the
              Services is required to be able to purchase, store, and engage in
              transactions using the Services, and to connect your Collectibles,
              with the Services. Assets stored in your Digital Wallet are owned
              and controlled solely by you. You are responsible for the security
              of your Digital Wallets. Digital Wallets are not associated with,
              maintained by, controlled by, or affiliated with us. We may not be
              able to help you recover assets stored in your Digital Wallet, as
              these are non-custodial wallets in your control. We accept no
              responsibility or liability to you in connection with your use of
              a Digital Wallet and make no representations or warranties
              regarding how the Services will operate with any specific Digital
              Wallet. The private keys, credentials, passwords, seed phrases, or
              secret recovery passphrases necessary to decrypt or gain access to
              a Digital Wallet are held solely by you, and not by us. If you
              choose to transfer items from one Digital Wallet to another, such
              transfers will occur on the public blockchain and we accept no
              liability or responsibility for any such transfer, including for
              any loss, data corruption, or other negative impact that may occur
              to Marketplace Offerings or Digital Wallets or other assets when
              attempting to transfer assets between Digital Wallets. We reserve
              the right, with or without prior notice and in our sole and
              complete discretion, to discontinue, modify, or limit the
              operation of any Digital Wallet with the Services. You represent
              and warrant that you are the lawful owner of any Digital Wallet
              and that you are using that Digital Wallet under the terms and
              conditions of the applicable provider of the Digital Wallet. If
              you have any issues with your Digital Wallet, please contact your
              Digital Wallet provider.
              <br />
              <TermSubUnderlineHeading>
                (xi) Cash Withdrawls
              </TermSubUnderlineHeading>
              You may withdraw cash from your Account by using the "Withdrawal"
              option on the Marketplace. You may be requested to complete an
              affidavit of eligibility and a liability/publicity release (unless
              prohibited by law) and/or appropriate tax forms and forms of
              identification, including but not limited to a driver's license,
              proof of residence, and/or any information relating to
              payment/deposit accounts as we may reasonably request in order to
              complete the withdrawal of deposits. Failure to comply with this
              requirement may result in disqualification and forfeiture of any
              deposits. Disqualification or forfeiture of any deposits may also
              occur in the event it is determined that you did not comply, in
              any manner, with these Terms of Use.
            </TermPeragraph>

            <TermPeragraph>
              <h4>3. LOCATION OF OUR PRIVACY POLICY AND OTHER TERMS</h4>
              <TermSubUnderlineHeading>
                (i) Privacy Policy.
              </TermSubUnderlineHeading>
               Our Privacy Policy describes how we handle the information you
              provide to us when you use the Services. For an explanation of our
              privacy practices, please visit our Privacy Policy located at
              ________________. <br />
              <TermSubUnderlineHeading>
                (ii) Other Terms.
              </TermSubUnderlineHeading>
               Reserved.
            </TermPeragraph>
            <TermPeragraph>
              <h4>4. REWARDS AND PRIZES.</h4>
              <TermSubUnderlineHeading>
                (i) Rewards.
              </TermSubUnderlineHeading>{' '}
              Cash rewards will be paid in USDC, and deposited into your Digital
              Wallet. USDC will automatically be converted to fiat currencywhen
              you withdraw funds.
              <br />
              <TermSubUnderlineHeading>
                {' '}
                (ii) Reward Calculations.
              </TermSubUnderlineHeading>{' '}
              Rewards will be calculated as set forth on the Website.
              <br />
              <TermSubUnderlineHeading>
                (iii) Reward Payments.
              </TermSubUnderlineHeading>{' '}
              Rewards shall be delivered to the wallet holding the (nft eligible
              for the reward) within three (3) business days following the
              validation of a prediction.
            </TermPeragraph>
            <TermPeragraph>
              <h4>5. ELIGIBILITY; RIGHTS WE GRANT YOU; USAGE LIMITATIONS</h4>
              <TermSubUnderlineHeading>
                (i) Eligibility.
              </TermSubUnderlineHeading>
               You must be 18 years of age or older to use the Services.
              Additional aspects of the Services may have further age
              restrictions that will be presented to you in connection with
              those Services. In addition, you cannot use the Services if (1)
              the Office of Foreign Assets Control of the United States Treasury
              Department lists you as a specially designated national and/or
              blocked person; (2) the Bureau of Industry and Security of the
              United States Department of Commerce lists you on its denied
              persons list or lists of parties of concern; or (3) you’re on any
              similar list promulgated by an official agency or department of
              the United States government. Further, if you use the Services,
              you may not transfer or provide to us any currency, digital
              assets, or other items that have been derived from any illegal or
              unlawful activity. By using the Services, you represent and
              warrant that you meet these all requirements.
              <br />
              <TermSubUnderlineHeading>
                (ii) User Account.
              </TermSubUnderlineHeading>
               Though your linked Digital Wallet will function as a unique
              identifier for you in respect of your use of the Services, we may
              additionally require you to create an account in order to use
              certain portions of the Services (to the extent applicable, your{' '}
              <strong>“Account”</strong>). To the extent that we require you to
              create an Account, we will provide you with instructions on how to
              do so, and once created, how to access, edit, and update your
              Account. In your creation, editing, or updating of any such
              Account, you agree to provide us with accurate, complete, and
              updated information. You are solely responsible for any activity
              on your Account and for maintaining the confidentiality and
              security of your password. We are not liable for any acts or
              omissions by you in connection with your Account.
            </TermPeragraph>
            <TermPeragraph>
              <h4>6. PAYMENT, GAS FEES, AND TAXES</h4>
              <TermSubUnderlineHeading>
                (i) Financial Transactions on Platform.
              </TermSubUnderlineHeading>{' '}
              Any payments or financial transactions that you engage in via the
              Services will be conducted solely through the Polygon blockchain. 
              We have no control over these payments or transactions, nor do we
              have the ability to reverse any payments or transactions. We have
              no liability to you or to any third party for any claims or
              damages that may arise as a result of any payments or transactions
              that you engage in via the Services, or any other payment or
              transactions that you conduct via the Polygon blockchain.
              <strong>
                 We do not provide refunds for any purchases that you might make
                on or through the Platform – whether for Collectibles or
                anything else.
              </strong>{' '}
              <br />
              <TermSubUnderlineHeading>
                (ii) Gas Fees.
              </TermSubUnderlineHeading>{' '}
              Every transaction on the Polygon blockchain requires the payment
              of a transaction fee (each, a “Gas Fee”). The Gas Fees fund the
              network of computers that run the decentralized Polygon
              blockchain. This means that you will need to pay a Gas Fee for
              each transaction that you instigate via the Platform. Except as
              otherwise expressly set forth in these Terms, you will be solely
              responsible to pay any Gas Fee for any transaction that you
              instigate via the Platform. <br />
              <TermSubUnderlineHeading>
                {' '}
                (iii) Responsibility for Taxes.
              </TermSubUnderlineHeading>{' '}
              You will be solely responsible to pay any and all sales, use,
              value-added and other taxes, duties, and assessments (except taxes
              on our net income) now or hereafter claimed or imposed by any
              governmental authority (collectively, the “Taxes”) associated with
              your use of the Services.
            </TermPeragraph>
            <TermPeragraph>
              <h4>7. OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS</h4>
              YOUR OWNERSHIP OF COLLECTIBLES WILL ONLY BE RECOGNIZED BY US IF
              YOU HAVE PURCHASED OR OTHERWISE RIGHTFULLY ACQUIRED SUCH
              COLLECTIBLES FROM A LEGITIMATE SOURCE AND NOT THROUGH ANY OF THE
              CATEGORY B PROHIBITED ACTIVITIES (AS DEFINED BELOW). For the
              purposes of this{' '}
              <TermSubUnderlineHeading>Section 7</TermSubUnderlineHeading>,{' '}
              <strong>“Own”</strong> means, with respect to a Collectible, a
              Collectible that you have purchased or otherwise rightfully
              acquired from a legitimate source (and not through any of the
              Category B Prohibited Activities (as defined below)), where proof
              of such purchase is recorded on the Polygon blockchain. <br />
              <TermSubUnderlineHeading>
                (i) Ownership of Collectible.
              </TermSubUnderlineHeading>
                Because each Collectible is an NFT on the Polygon blockchain,
              when you purchase a Collectible in accordance with these Terms
              (and not through any of the Category B Prohibited Activities), you
              own the underlying NFT. This means that you have the right to swap
              your Collectible, sell it, or give it away. Ownership of the
              Collectible is mediated entirely by the Polygon blockchain. Except
              as otherwise permitted by these Terms, such as in cases where we
              determine that the Collectible has not been rightfully acquired
              from a legitimate source (including, without limitation, through
              any of the Category B Prohibited Activities), at no point will we
              seize, freeze, or otherwise modify the ownership of any
              Collectible. <br />
              <TermSubUnderlineHeading>
                (ii) We Own the Platform.
              </TermSubUnderlineHeading>
                You acknowledge and agree that we own, control or license, all
              legal right, title and interest in and to all other elements of
              the Platform and the Services, and all intellectual property
              rights therein (including, without limitation, all designs,
              systems, methods, information, computer code, software, services,
              “look and feel”, organization, compilation of the content, code,
              data, and all other elements of the Platform (collectively, the{' '}
              <strong>“Platform Materials”</strong>)). You acknowledge that the
              Platform Materials are protected by copyright, trade dress,
              patent, and trademark laws, international conventions, other
              relevant intellectual property and proprietary rights, and
              applicable laws. All Platform Materials are the copyrighted
              property of us, our licensees, partners, or affiliates. All
              trademarks, service marks, and trade names associated with the
              Services or otherwise contained in the Platform Materials are
              proprietary to us, our licensees, partners, or affiliates. <br />
              <TermSubUnderlineHeading>
                {' '}
                (iii) No User License or Ownership of Platform Materials.
              </TermSubUnderlineHeading>{' '}
              Your use of the Services does not grant you ownership of or any
              other rights with respect to any content, code, data, or other
              Platform Materials that you may access on or through the Services.
              We reserve all rights in and to the Platform Materials that are
              not expressly granted to you in these Terms. <br />
              <TermSubUnderlineHeading>
                (iv) Further User Ownership Acknowledgements.
              </TermSubUnderlineHeading>{' '}
              For the sake of clarity, you understand and agree: (a) that your
              purchase of a Collectible, whether via the Services or otherwise,
              does not give you any rights or licenses in or to the Platform
              Materials other than those expressly contained in these Terms; (b)
              that you do not have the right, except as otherwise set forth in
              these Terms, to reproduce, distribute, or otherwise commercialize
              any elements of the Platform Materials without the prior written
              consent of our Chief Executive Officer, in each case, which
              consent may be withheld in our sole and absolute discretion; and
              (c) that you will not apply for, register, or otherwise use or
              attempt to use any of our trademarks or service marks, or any
              confusingly similar marks, anywhere in the world, without the
              prior written consent of our Chief Executive Officer, in each
              case, which consent may withheld at our sole and absolute
              discretion. <br />
              <TermSubUnderlineHeading>
                (v) User Feedback.
              </TermSubUnderlineHeading>
                You may choose to submit comments, bug reports, ideas or other
              feedback about the Services, including without limitation about
              how to improve the Services (collectively,{' '}
              <strong>“Feedback”</strong>). By submitting any Feedback, you
              agree that we are free to use such Feedback at our discretion and
              without any compensation to you, and to disclose such Feedback to
              third parties (whether on a non-confidential basis, or otherwise).
              You hereby grant us a perpetual, irrevocable, nonexclusive,
              worldwide license under all rights necessary for us to incorporate
              and use your Feedback for any purpose. <br />
            </TermPeragraph>

            <TermPeragraph>
              <h4>8. CONDITIONS OF USE AND PROHIBITED ACTIVITIES</h4>
              YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE
              ACCESSING OR USING THE SERVICES, AND FOR ANY CONSEQUENCES THEREOF.
              YOU AGREE TO USE THE SERVICES ONLY FOR PURPOSES THAT ARE LEGAL,
              PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY APPLICABLE LAWS
              OR REGULATIONS. <br />
              <TermSubUnderlineHeading>
                (i) User Warranties.
              </TermSubUnderlineHeading>
                Without limiting the foregoing, you warrant and agree that your
              use of the Services will not (and will not allow any third party
              to): <br />
              (a) in any manner: <br />
              (1)  involve the sending, uploading, distributing or disseminating
              any unlawful, defamatory, harassing, abusive, fraudulent, obscene,
              or otherwise objectionable content; <br />
              (2)  involve the distribution of any viruses, worms, defects,
              Trojan horses, corrupted files, hoaxes, or any other items of a
              destructive or deceptive nature; <br />
              (3)  involve the uploading, posting, transmitting or otherwise
              making available through the Services any content that infringes
              the intellectual proprietary rights of any party; <br />
              (4)  involve using the Services to violate the legal rights (such
              as rights of privacy and publicity) of others; <br />
              (5) involve engaging in, promoting, or encouraging illegal
              activity (including, without limitation, money laundering); <br />
              (6)  involve interfering with other users’ enjoyment of the
              Services; <br />
              (7)  involve exploiting the Services for any unauthorized
              commercial purpose; <br />
              (8)  involve modifying, adapting, translating, or reverse
              engineering any portion of the Services;
              <br />
              (9) involve removing any copyright, trademark or other proprietary
              rights notices contained in or on the Platform or any part of it;
              <br />
              (10) involve reformatting or framing any portion of the Services;
              <br />
              (11) involve displaying any content on the Platform, or using the
              Services, that contains any hate-related or violent content or
              contains any other material, products or services that violate or
              encourage conduct that would violate any criminal laws, any other
              applicable laws, or any third party rights;
              <br />
              (12) involve using any spider, site search/retrieval application,
              or other device to retrieve or index any portion of the Platform
              or the content posted on the Platform, or to collect information
              about its users for any unauthorized purpose;
              <br />
              (13) involve accessing or using the Services for the purpose of
              creating a product or service that is competitive with any of our
              products or services;
              <br />
              (14) involve abusing, harassing, or threatening another user of
              the Services or any of our authorized representatives, customer
              service personnel, chat board moderators, or volunteers
              (including, without limitation, filing support tickets with false
              information, sending excessive emails or support tickets,
              obstructing our employees from doing their jobs, refusing to
              follow the instructions of our employees, or publicly disparaging
              us by implying favoritism by our employees or otherwise); or{' '}
              <br />
              (15) involve using any abusive, defamatory, ethnically or racially
              offensive, harassing, harmful, hateful, obscene, offensive,
              sexually explicit, threatening or vulgar language when
              communicating with another user of the Services or any of our
              authorized representatives, customer service personnel, chat board
              moderators, or volunteers (each, a{' '}
              <strong>“Category A Prohibited Activity</strong>); and/or <br />
              (b) in any manner: (1)  involve the impersonation of another
              person (via the use of an email address or otherwise); <br />
              (2)  involve using, employing, operating, or creating a computer
              program to simulate the human behavior of a user{' '}
              <strong>(“Bots”)</strong>“;
              <br />
              (3)  involve using, employing, or operating Bots or other similar
              forms of automation to engage in any activity or transaction on
              the Platform;
              <br />
              (4)  involve acquiring Collectibles through inappropriate or
              illegal means (including, among other things, using a payment
              mechanism that you do not have the right to use, or purchasing a
              Collectible and then attempting to charge the cost back to your
              payment method while still maintaining ownership or control of the
              Collectible or selling, gifting or trading the Collectible to
              someone else);
              <br />
              (5)  otherwise involve or result in the wrongful seizure or
              receipt of any Collectibles or other digital assets; <br />
              <TermSubUnderlineHeading>
                {' '}
                (ii) Effect of Your Breaches.
              </TermSubUnderlineHeading>{' '}
              If you engage in any of the Prohibited Activities, we may, at our
              sole and absolute discretion, without notice or liability to you,
              and without limiting any of our other rights or remedies at law or
              in equity, immediately delete your Collectibles’ images and
              descriptions from the Platform. If we delete your Collectibles’
              images and/or descriptions from the Platform, such deletion will
              not affect your ownership rights in any Collectibles that you
              already Own, but you will not receive a refund of any amounts you
              paid for those Collectibles.
              <br />
              NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE
              THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED
              ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY DELETE YOUR
              COLLECTIBLES’ IMAGES AND DESCRIPTIONS FROM THE PLATFORM, WE ALSO
              RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, WITHOUT
              NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE FOLLOWING
              ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE
              RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO; AND/OR (B) TO
              IMMEDIATELY CONFISCATE ANY COLLECTIBLES (INCLUDING THEIR
              UNDERLYING NFTS) THAT WERE PURCHASED OR ACQUIRED AS THE RESULT OF
              SUCH ACTIVITIES.
            </TermPeragraph>

            <TermPeragraph>
              <h4> 9. TERMINATION </h4>
              <TermSubUnderlineHeading>
                {' '}
                (i) You Terminate.
              </TermSubUnderlineHeading>{' '}
              You may terminate these Terms by discontinuing your access to and
              use of the Services. If you terminate these Terms, you will not
              receive any refunds for any purchases that you might make on or
              through the Services – whether for Collectibles or anything else.{' '}
              <br />
              <TermSubUnderlineHeading>
                (ii) We Terminate.
              </TermSubUnderlineHeading>{' '}
              You agree that we, in our sole discretion and for any or no
              reason, may terminate these Terms without the provision of prior
              notice. You agree that any suspension or termination of your
              access to the Services may be without prior notice, and that we
              will not be liable to you or to any third party for any such
              suspension or termination. <br />
              <TermSubUnderlineHeading>
                (iii) Other Remedies Available.
              </TermSubUnderlineHeading>{' '}
              If we terminate these Terms or suspend or terminate your access to
              or use of the Services due to your breach of these Terms or any
              suspected fraudulent, abusive, or illegal activity (including,
              without limitation, if you engage in any of the Prohibited
              Activities), then termination of these Terms will be in addition
              to any other remedies we may have at law or in equity. <br />
              <TermSubUnderlineHeading>
                (iv) Referral to Governmental Authority.
              </TermSubUnderlineHeading>{' '}
              We have the right, without provision of prior notice, to take
              appropriate legal action, including, without limitation, referral
              to law enforcement or regulatory authority, or notifying the
              harmed party of any illegal or unauthorized use of the Services.
              Without limiting the foregoing, we have the right to fully
              cooperate with any law enforcement authorities or court order
              requesting or directing us to disclose the identity or other
              information of anyone using the Services. <br />
              <TermSubUnderlineHeading>
                (v) Effect of Termination.
              </TermSubUnderlineHeading>{' '}
              Upon any termination or expiration of these Terms, whether by you
              or us, you may no longer have access to information that you have
              posted on the Platform, and you acknowledge that we will have no
              obligation to maintain any such information in our databases or to
              forward any such information to you or to any third party.
              Sections 2 and 4 through 18 will survive the termination or
              expiration of these Terms for any reason. <br />
              YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES, AFFILIATES,
              SERVICE PROVIDERS, CONTRACTORS, LICENSEES, LICENSORS, SUPPLIERS,
              AND SUCCESSORS, AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
              EMPLOYEES, AND AGENTS, HARMLESS FROM ANY AND ALL CLAIMS RESULTING
              FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES
              RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY
              LAW ENFORCEMENT AUTHORITIES.
            </TermPeragraph>
            <TermPeragraph>
              <h4> 10. DISCLAIMERS </h4>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
              THE SERVICES IS AT YOUR SOLE RISK, AND THAT THE SERVICES ARE
              PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY
              KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
              PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AND
              AFFILIATES MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL
              IMPLIED WARRANTIES REGARDING THE SERVICES AND ANY PART OF THEM,
              INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR
              RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE,
              OUR PARENT, SUBSIDIARIES, AND AFFILIATES DO NOT REPRESENT OR
              WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SERVICES
              WILL MEET YOUR REQUIREMENTS; (II) YOUR ACCESS TO OR USE OF THE
              SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR;
              (III) USAGE DATA PROVIDED THROUGH THE SERVICES WILL BE ACCURATE;
              (IV) THE SERVICES OR ANY CONTENT, SERVICES, OR FEATURES MADE
              AVAILABLE ON OR THROUGH THE SERVICES ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS; OR (V) THAT ANY DATA THAT YOU DISCLOSE WHEN
              YOU USE THE SERVICES WILL BE SECURE. SOME JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH
              CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO
              YOU. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
              INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT
              WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY
              UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE. WE WILL NOT BE
              RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE
              RESULT OF YOUR USE OF THE POLYGON BLOCKCHAIN, OR YOUR ELECTRONIC
              WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS
              ARISING FROM: <br /> (I) USER ERROR, SUCH AS FORGOTTEN PASSWORDS
              OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS;
              <br /> (II) SERVER FAILURE OR DATA LOSS; <br /> (III) CORRUPTED
              WALLET FILES; OR <br />
              (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES,
              INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, PHISHING,
              BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE PLATFORM, THE
              POLYGON BLOCKCHAIN, OR ANY ELECTRONIC WALLET. COLLECTIBLES ARE
              INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE
              OWNERSHIP RECORD MAINTAINED IN THE POLYGON BLOCKCHAIN. ALL SMART
              CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER
              WITHIN THE POLYGON BLOCKCHAIN. WE HAVE NO CONTROL OVER AND MAKE NO
              GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
              COLLECTIBLES ARE INTENDED FOR CONSUMER ENJOYMENT, USE AND
              CONSUMPTION ONLY. A COLLECTIBLE IS NOT A “SECURITY,” AS DEFINED
              UNDER THE SECURITIES ACT OF 1933, AS AMENDED, THE SECURITIES
              EXCHANGE ACT OF 1934, AS AMENDED, THE INVESTMENT COMPANY ACT OF
              1940, AS AMENDED, OR UNDER THE SECURITIES LAWS OF ANY U.S. STATE.
              WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER
              FEATURES OF THE POLYGON BLOCKCHAIN, OR ANY ELECTRONIC WALLET,
              INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
              REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE
              BLOCKCHAIN SUPPORTING THE POLYGON BLOCKCHAIN, INCLUDING FORKS,
              TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A
              RESULT.
            </TermPeragraph>
            <TermPeragraph>
              <h4>11. LIMITATION OF LIABILITY</h4>
              YOU UNDERSTAND AND AGREE THAT WE AND OUR PARENT, SUBSIDIARIES,
              AFFILIATES, SERVICE PROVIDERS, CONTRACTORS, LICENSEES, LICENSORS,
              SUPPLIERS, AND SUCCESSORS, AND OUR AND THEIR RESPECTIVE DIRECTORS,
              OFFICERS, EMPLOYEES, AND AGENTS, WILL NOT BE LIABLE TO YOU OR TO
              ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER
              CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT
              LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
              INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF
              DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
              DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT
              OUR AND OUR PARENTS’, SUBSIDIARIES’, AND AFFILIATES’ TOTAL,
              AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF
              OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR
              INABILITY TO ACCESS OR USE) ANY PORTION OF THE SERVICES, WHETHER
              IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS
              LIMITED TO THE GREATER OF
              <br /> (I) THE AMOUNTS YOU HAVE ACTUALLY AND LAWFULLY PAID US
              UNDER THESE TERMS IN THE TWO <br />
              (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR <br />
              (II) $250 U.S. DOLLARS. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE
              MADE THE SERVICES AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN
              RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES, DISCLAIMERS AND
              LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A
              REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM
              AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT
              BE ABLE TO PROVIDE THE SERVICES TO YOU WITHOUT THESE LIMITATIONS.
              GAMBLING AND FANTASY SPORTS DISCLAIMERS IT IS POSSIBLE THAT
              CERTAIN JURISDICTIONS MAY CONSIDER THAT CERTAIN OF THE SERVICES
              CONSTITUTE FANTASY SPORTS OR GAMBLING AND ARE GOVERNED BY
              APPLICABLE STATE STATUTES AND REGULATIONS. WHEN PARTICIPANTS
              TRADE, SELL, STAKE OR POOLTHEIR COLLECTIBLES, THEY ARE UTILIZING
              SKILL TO CHOOSE THE COLLECTIBLE AND VARIOUS OTHER FACTORS. GAMES
              OF SKILL ARE PERMITTED IN MOST JURISDICTIONS AND DO NOT CONSTITUTE
              GAMBLING (WHICH GENERALLY REQUIRES A GAME OF CHANCE, PRIZE, AND
              CONSIDERATION TO PLAY). SOME JURISDICTIONS LIMIT GAMES OF SKILL
              WHERE PAYMENTS ARE INVOLVED. IT IS YOUR RESPONSIBILITY TO
              DETERMINE WHETHER THE JURISDICTION IN WHICH YOU ARE LOCATED
              PERMITS SKILL-BASED COMPETITIONS. WHEN YOU PARTICIPATE IN
              COMPETITIONS, THESE TERMS SHALL APPLY.
            </TermPeragraph>
            <TermPeragraph>
              <h4> 12. ASSUMPTION OF RISK </h4>
              <TermSubUnderlineHeading>
                (i) Value and Volatility.
              </TermSubUnderlineHeading>{' '}
              The prices of both collectible and non-collectible blockchain
              assets are extremely volatile and subjective and collectible
              blockchain assets have no inherent or intrinsic value.
              Fluctuations in the price of other digital assets could materially
              and adversely affect the value of your Collectibles, which may
              also be subject to significant price volatility. We cannot
              guarantee that any Collectibles purchased, will retain their
              original value, as the value of collectibles is inherently
              subjective and factors occurring outside of the Platform may
              materially impact the value and desirability of any particular
              Collectible.
              <br />
              <TermSubUnderlineHeading>
                (ii) Tax Calculations.
              </TermSubUnderlineHeading>{' '}
              You are solely responsible for determining what, if any, taxes
              apply to your Collectible-related transactions. We are not
              responsible for determining the taxes that apply to your
              transactions, both on and off the Platform. <br />
              <TermSubUnderlineHeading>
                (iii) Use of Blockchain.
              </TermSubUnderlineHeading>{' '}
              The Platform does not store, send, or receive Collectibles. This
              is because Collectibles exist only by virtue of the ownership
              record maintained on the Polygon blockchain. Any transfer of
              Collectibles occurs within the Polygon blockchain, and not on the
              Platform.
              <br />
              <TermSubUnderlineHeading>
                (iv) Inherent Risks with Internet Currency.
              </TermSubUnderlineHeading>{' '}
              There are risks associated with using an Internet-based currency,
              including, but not limited to, the risk of hardware, software and
              Internet connections, the risk of malicious software introduction,
              and the risk that third parties may obtain unauthorized access to
              information stored within your electronic wallet. You accept and
              acknowledge that we will not be responsible for any communication
              failures, disruptions, errors, distortions or delays you may
              experience when using the Polygon blockchain, however caused.
              <br />
              <TermSubUnderlineHeading>
                (v) Regulatory Uncertainty.
              </TermSubUnderlineHeading>{' '}
              The regulatory regime governing blockchain technologies and
              digital assets, is uncertain, and new regulations or policies may
              materially adversely affect the development of the Platform, your
              use and access of the Platform, and therefore the potential
              utility or value of your Collectibles.
              <br />
              <TermSubUnderlineHeading>
                (vi) Software Risks.
              </TermSubUnderlineHeading>{' '}
              Upgrades to the Polygon blockchain, a hard fork in the Polygon
              blockchain, or a change in how transactions are confirmed on the
              Polygon blockchain may have unintended, adverse effects any
              network on the Polygon blockchain, including the Platform.
            </TermPeragraph>
            <TermPeragraph>
              <h4> 13. INDEMNIFICATION </h4>
              You agree to hold harmless and indemnify us and our parent,
              subsidiaries, affiliates, partners, suppliers, service providers,
              and each of their respective, officers, directors, employees and
              agents, from and against any claim, liability, loss, damage
              (actual and consequential) of any kind or nature, suit, judgment,
              litigation cost and attorneys' fees arising out of or in any way
              related to: (i) your breach of these Terms; (ii) your misuse of
              the Services; or (iii) your violation of applicable laws, rules or
              regulations in connection with your access to or use of the
              Services. You agree that we will have control of the defense or
              settlement of any such claims.
            </TermPeragraph>
            <TermPeragraph>
              <h4> 14. THIRD-PARTY SERVICES, MATERIALS AND EXTERNAL SITES </h4>
              We may, through our Services, display, include, or make available
              third party content, applications, advertising, data, and
              statistics <strong>(“Third-Party Materials”)</strong>, or provide
              links to certain third-party websites (collectively, the “External
              Sites”), which are provided solely as a convenience to our users.
              By using the Services, you acknowledge and agree that we are not
              responsible for examining or evaluating the content, accuracy,
              completeness, availability, timeliness, validity, copyright
              compliance, legality, decency, quality, or any other aspect of
              such Third-Party Materials or External Sites, and has no control
              over the same. You acknowledge and agree that we are not
              responsible for the availability of any External Sites, and that
              we do not endorse any advertising, products or other Third Party
              Materials on or made available from or through any External Sites.
              Furthermore, you acknowledge and agree that we are not liable for
              any loss or damage which may be incurred as a result of the
              availability or unavailability of the External Sites, or as a
              result of any reliance placed by you upon the completeness,
              accuracy or existence of any advertising, products or other Third
              Party Materials on the Platform, or on or made available from any
              External Sites. Third-Party Materials and links to External Sites
              are provided solely as a convenience to you.  
            </TermPeragraph>

            <TermPeragraph>
              <h4> 15. FORCE MAJEURE </h4>
              <TermSubUnderlineHeading>
                (i) Force Majeure Events.
              </TermSubUnderlineHeading>{' '}
              We will not be liable or responsible to the you, nor be deemed to
              have defaulted under or breached these Terms, for any failure or
              delay in fulfilling or performing any of these Terms, when and to
              the extent such failure or delay is caused by or results from the
              following force majeure events{' '}
              <strong>(“Force Majeure Event(s)”)</strong>: (a) acts of God;
              <br /> (b) flood, fire, earthquake, epidemics, pandemics,
              including the 2019 novel coronavirus pandemic (COVID-19), tsunami,
              explosion;
              <br /> (c) war, invasion, hostilities (whether war is declared or
              not), terrorist threats or acts, riot or other civil unrest;{' '}
              <br /> (d) government order, law, or action; (e) embargoes or
              blockades in effect on or after the date of this agreement;
              <br /> (f) strikes, labor stoppages or slowdowns or other
              industrial disturbances;
              <br /> (g) shortage of adequate or suitable Internet connectivity,
              telecommunication breakdown or shortage of adequate power or
              electricity; and <br />
              (h) other similar events beyond our control. <br />
              <TermSubUnderlineHeading>
                (ii) Performance During Force Majeure Events.
              </TermSubUnderlineHeading>{' '}
              If we suffer a Force Majeure Event, we will use reasonable efforts
              to promptly notify you of the Force Majeure Event, stating the
              period of time the occurrence is expected to continue. We will use
              diligent efforts to end the failure or delay and ensure the
              effects of such Force Majeure Event are minimized. We will resume
              the performance of our obligations as soon as reasonably
              practicable after the removal of the cause. In the event that our
              failure or delay remains uncured for a period of forty-five (45)
              consecutive days following written notice given by us under this
              Section, we may thereafter terminate these Terms upon fifteen (15)
              days' written notice.
            </TermPeragraph>
            <TermPeragraph>
              <h4> 16. CHANGES TO THE SERVICES; UPDATES AND PATCHES </h4>
              <TermSubUnderlineHeading>
                (i) Changes to the Services.
              </TermSubUnderlineHeading>{' '}
              We are constantly innovating the Services to help provide the best
              possible experience. You acknowledge and agree that the form and
              nature of the Services, and any part of them, may change from time
              to time without prior notice to you, and that we may add new
              features and change any part of the Services at any time without
              notice. <br />
              <TermSubUnderlineHeading>
                (ii) Updates and Patches.
              </TermSubUnderlineHeading>{' '}
              We (or a third party on our behalf) may deploy or provide patches,
              updates, and modifications to the Services that must be installed
              for you to continue to use the Services. We may update the
              Services remotely, including, without limitation, any aspect of
              the Services residing on your computer or game machine, without
              your knowledge or consent, and you hereby grant us your consent to
              deploy and apply such patches, updates, and modifications as we,
              in our sole discretion, deems necessary or advisable.
            </TermPeragraph>
            <TermPeragraph>
              <h4>
                {' '}
                17. DISPUTE RESOLUTION; BINDING ARBITRATION; CLASS ACTION WAIVER{' '}
              </h4>
              IN THIS SECTION 17, YOU ARE AGREEING TO GIVE UP RIGHTS TO LITIGATE
              CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO
              COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
              YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
              YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER
              OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED
              TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
              <TermSubUnderlineHeading>
                (i) Binding Arbitration.
              </TermSubUnderlineHeading>{' '}
              All disputes arising out of or in connection with this contract,
              or in respect of any defined legal relationship associated
              therewith or derived therefrom, shall be referred to and finally
              resolved by arbitration administered by JAMS under its Streamlined
              Arbitration Rules and Procedures and the JAMS Consumer Arbitration
              Minimum Standards (together, the <strong>“Rules”</strong>). The
              most recent version of the Rules is available at
              www.jamsadr.com/rules-download and are hereby incorporated by
              reference. The place of arbitration shall be in New York, New
              York, unless the arbitrator determines that the dispute can be
              resolved on the submission of written papers or you exercise your
              right to an in-person hearing in your hometown area.
              <br />
              <TermSubUnderlineHeading>
                (ii) Arbitration Fees.
              </TermSubUnderlineHeading>{' '}
              You and we agree that for any arbitration you initiate, you will
              pay the filing fee (up to a maximum of $250 if you are a
              consumer), and we will pay the remaining JAMS fees and costs. For
              any arbitration initiated by us, we will pay all JAMS fees and
              costs.
              <br />
              <TermSubUnderlineHeading>
                (iii) Award Enforcement.
              </TermSubUnderlineHeading>{' '}
              The award of the arbitrator will be final and binding, and any
              judgment on the award rendered by the arbitrator may be entered in
              any court of competent jurisdiction. The parties agree that they
              will not appeal any arbitration decision to any court.
              <br />
              <TermSubUnderlineHeading>
                (iv) Additional Remedies.
              </TermSubUnderlineHeading>{' '}
              Notwithstanding the foregoing, we may seek and obtain injunctive
              relief in any jurisdiction in any court of competent jurisdiction,
              and you agree that these Terms are specifically enforceable by us
              through injunctive relief and other equitable remedies without
              proof of monetary damages. <br />
              <TermSubUnderlineHeading>
                (v) Opt Out.{' '}
              </TermSubUnderlineHeading>{' '}
              <strong>
                {' '}
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this Section 17
                by submitting a request at support.10XChi.io. In order to be
                effective, the opt-out notice must include your full name and
                address and clearly indicate your intent to opt out of binding
                arbitration.
              </strong>
              <br />
              <TermSubUnderlineHeading>
                (vi) Confidentiality.
              </TermSubUnderlineHeading>{' '}
              You and us agree that any claim that has been submitted to
              arbitration, and all related proceedings, including any settlement
              agreement, shall be kept confidential. In the event you or we file
              the arbitration award with any court of competent jurisdiction in
              order to have that award confirmed in a court order or judgment,
              or any other proceeding under the Federal Arbitration Act, then
              each party shall take measures to the extent permitted by
              applicable law to redact or file under seal any highly sensitive
              business or personal information that appear in the award and any
              other supporting documents filed in such a proceeding.
              <br />
              <TermSubUnderlineHeading>
                (vii) Severability.
              </TermSubUnderlineHeading>{' '}
              If any portion of this Section is found to be unenforceable or
              unlawful for any reason, (a) the unenforceable or unlawful
              provision shall be severed from these Terms; (b) severance of the
              unenforceable or unlawful provision shall have no impact
              whatsoever on the remainder of this Section or the parties'
              ability to compel arbitration of any remaining claims on an
              individual basis pursuant to this Section ; and (c) to the extent
              that any claims must therefore proceed on a class, collective,
              consolidated, or representative basis, such claims must be
              litigated in a civil court of competent jurisdiction and not in
              arbitration, and the parties agree that litigation of those claims
              shall be stayed pending the outcome of any individual claims in
              arbitration. Further, if any part of this Section is found to
              prohibit an individual claim seeking public injunctive relief,
              that provision will have no effect to the extent such relief is
              allowed to be sought out of arbitration, and the remainder of this
              section will be enforceable. <br />
            </TermPeragraph>
            <TermPeragraph>
              <h4> 18. GENERAL </h4>
              <TermSubUnderlineHeading>
                (i) Entire Agreement.
              </TermSubUnderlineHeading>
                These Terms and our Privacy Policy constitute the entire legal
              agreement between you and us and will be deemed to be the final
              and integrated agreement between you and us, and govern your
              access to and use of the Services, and completely replace any
              prior or contemporaneous agreements between you and us related to
              your access to or use of the Services, whether oral or written.{' '}
              <br />
              <TermSubUnderlineHeading>
                (ii) Third-Party Beneficiaries.
              </TermSubUnderlineHeading>{' '}
              Other than as otherwise expressly set forth herein, these Terms do
              not and are not intended to confer any rights or remedies upon any
              person or entity other than you. <br />
              <TermSubUnderlineHeading>
                (iii) Interpretation.
              </TermSubUnderlineHeading>{' '}
              The language in these Terms will be interpreted as to its fair
              meaning, and not strictly for or against any party. <br />
              <TermSubUnderlineHeading>
                (iv) Severability.
              </TermSubUnderlineHeading>{' '}
              Should any part of these Terms be held invalid, illegal, void or
              unenforceable, that portion will be deemed severed from these
              Terms and will not affect the validity or enforceability of the
              remaining provisions of these Terms. <br />
              <TermSubUnderlineHeading>
                (v) No Waivers.
              </TermSubUnderlineHeading>{' '}
              Our failure or delay to exercise or enforce any right or provision
              of these Terms will not constitute or be deemed a waiver of future
              exercise or enforcement of such right or provision. The waiver of
              any right or provision of these Terms will be effective only if in
              writing and signed for and on behalf of us by a duly authorized
              representative. <br />
              <TermSubUnderlineHeading>
                (vi) Governing Law.
              </TermSubUnderlineHeading>{' '}
              All matters arising out of or relating to these Terms will be
              governed by and construed in accordance with the laws of the state
              of Florida and the federal laws of the United States applicable
              therein without giving effect to any choice or conflict of law
              provision or rule (whether of the state of Florida or any other
              jurisdiction). <br />
              <TermSubUnderlineHeading>
                (vii) Venue.
              </TermSubUnderlineHeading>{' '}
              Subject to Section 17 of these Terms, any legal action or
              proceeding arising under these Terms will be brought exclusively
              in state or federal courts located in Boca Raton, Florida, and we
              and you irrevocably consent and attorn to the personal
              jurisdiction and venue there.
              <br />
              <TermSubUnderlineHeading>
                (viii) Notices.
              </TermSubUnderlineHeading>{' '}
              We may provide you with any notices (including, without limitation
              those regarding changes to these Terms) by email or postings on
              the Platform. By providing us with your email address, you consent
              to our using the email address to send you any notices. Notices
              sent by email will be effective when we send the email, and
              notices we provide by posting will be effective upon posting. It
              is your responsibility to keep your email address current. <br />
              <TermSubUnderlineHeading>
                (ix) Assignment.
              </TermSubUnderlineHeading>{' '}
              You may not assign any of your rights or obligations under these
              Terms, whether by operation of law or otherwise, without our prior
              written consent. We may assign our rights and obligations under
              these Terms in our sole discretion to an affiliate, or in
              connection with an acquisition, sale or merger. These Terms shall
              be binding upon and inure to the benefit of the applicable parties
              and their successors and permitted assigns. <br />
              <TermSubUnderlineHeading>
                (x) California Residents.
              </TermSubUnderlineHeading>
               If you are a California resident, in accordance with Cal. Civ.
              Code § 1789.3, you may report complaints to the Complaint
              Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs by contacting them in
              writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
              95834, or by telephone at (800) 952-5210.
              <br />
              <TermSubUnderlineHeading>
                (xi) Export Laws.
              </TermSubUnderlineHeading>
               You agree that you will not export or re-export, directly or
              indirectly, the Services and/or other information or materials
              provided by us hereunder, to any country for which the United
              States or any other relevant jurisdiction requires any export
              license or other governmental approval at the time of export
              without first obtaining such license or approval. In particular,
              but without limitation, the Services may not be exported or
              re-exported <br />
              (a) into any U.S. embargoed countries or any country that has been
              designated by the U.S. Government as a “terrorist supporting”
              country, or <br /> (b) to anyone listed on any U.S. Government
              list of prohibited or restricted parties, including the U.S.
              Treasury Department’s list of Specially Designated Nationals or
              the U.S. Department of Commerce Denied Person’s List or Entity
              List. You warrant and represent that you’re not located in, under
              the control of, or a national or resident of any embargoed
              country. By using the Services, you represent and warrant that you
              are not located in any such country or on any such list. You are
              responsible for and hereby agree to comply at your sole expense
              with all applicable United States export laws and regulations.
              <br />
              <TermSubUnderlineHeading>
                How to Contact Us.
              </TermSubUnderlineHeading>
               You may contact us regarding the Services or these Terms at:
              10XChi, Inc., 3200 N Federal Hwy, Suite 118, Boca Raton, FL.
              33431, United States, by phone at ________, or by e-mail
              at admin@220k.io.
            </TermPeragraph>
          </TermPageContent>
        </TermPageContainer>
      </TermWrapper>
    </AuthLayout>
  );
};
