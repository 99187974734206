import React, { useState, useEffect } from 'react';
import {
  CloseButton,
  PreviewCardWrapper,
  PriceItem,
  PropertiesContent,
  PropertiesHeader,
  PropertiesWrapper,
  PropertyItem,
  SetPriceWrapper,
  ViewDateCardContainer,
  ViewDateCardWrapper
} from './styles';
import { SellDateCardProps } from '../../../typeroot/types';
import {
  Button,
  DateCard,
  IconArrowDown,
  IconArrowUp,
  Input,
  PredictionCard,
  CardPack,
  IdentityCard,
  CraftingCard
} from '../../../components';
import { TriggerCard } from '../../../components/TriggerCard';
import { CategoryCard } from '../../../components/CategoryCard';
import { ITrigger } from '../../../models/trigger';

import { useMonthContext, useTriggersContext } from '../../../context';
import {
  NFT_TYPE_ID_CATEGORY,
  NFT_TYPE_ID_CRAFTING,
  NFT_TYPE_ID_DAY_MONTH,
  NFT_TYPE_ID_IDENTITY,
  NFT_TYPE_ID_PREDICTION,
  NFT_TYPE_ID_TRIGGER,
  NFT_TYPE_ID_YEAR
} from '../../../models/nft';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PriceInputWrapper, SubmitButton } from '../../marketplace/styles';
import { ErrorMessage } from '@hookform/error-message';

type Inputs = {
  price: string;
};

export const SellDateCardSection: React.FC<SellDateCardProps> = ({
  item,
  cardType,
  isView,
  onClose,
  onSellConfirm
}) => {
  const prizePoolFee = 0.05;
  const marketplaceFee = 0.05;
  const { monthContext } = useMonthContext();
  const { triggersContext } = useTriggersContext();
  const [price, setPrice] = useState(0);

  // console.log(item);
  const [totalPrice, setTotalPrice] = useState<number>(1);
  const [nftCollectionId, setNftCollectionID] = useState<number>(0);
  const [showHideProp, setShowHideProp] = useState<boolean>(true);
  const [showHideSummary, setShowHideSummary] = useState<boolean>(true);
  const [collapsedTwo, setCollapsedTwo] = useState<boolean>(false);

  const toggleShowHideProp = () => setShowHideProp(!showHideProp);
  const toggleShowHideSummary = () => setShowHideSummary(!showHideSummary);
  const toggleCollapsedTwo = () => setCollapsedTwo(!collapsedTwo);


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>({ defaultValues: { price: '0.00' } });

  const watchPrice = watch('price');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    onSellConfirm(item.id, nftCollectionId, parseFloat(parseFloat(data.price).toFixed(2)));
  };

  const calculateMarketplaceFee = (priceValue: string) => {
    const amountWithoutDollarSign = priceValue;
    const priceNumber = parseFloat(amountWithoutDollarSign);
    const fee = (priceNumber * marketplaceFee).toFixed(2);
    return fee;
  };

  const calculatePrizePoolFee = (priceValue: string) => {
    const amountWithoutDollarSign = priceValue;
    const priceNumber = parseFloat(amountWithoutDollarSign);
    const fee = (priceNumber * prizePoolFee).toFixed(2);
    return fee;
  };

  //get price
  useEffect(() => {
    const marketplaceFee = calculateMarketplaceFee(watchPrice);
    const prizePoolFee = calculatePrizePoolFee(watchPrice);
    const totalPrice =
      parseFloat(watchPrice) -
      parseFloat(marketplaceFee) -
      parseFloat(prizePoolFee);
    setTotalPrice(Number(totalPrice.toFixed(2)));
  }, [watchPrice]);

  //get collection id
  useEffect(() => {
    if (item) {
      if (item.day || item.month) {
        setNftCollectionID(NFT_TYPE_ID_DAY_MONTH);
      } else if (item.year) {
        setNftCollectionID(NFT_TYPE_ID_YEAR);
      } else if (item.category) {
        setNftCollectionID(NFT_TYPE_ID_CATEGORY);
      } else if (item.crafting) {
        setNftCollectionID(NFT_TYPE_ID_CRAFTING);
      } else if (item.trigger) {
        setNftCollectionID(NFT_TYPE_ID_TRIGGER);
      } else if (item.identity) {
        setNftCollectionID(NFT_TYPE_ID_IDENTITY);
      } else if (item.prediction) {
        setNftCollectionID(NFT_TYPE_ID_PREDICTION);
      }
    }
  }, [item]);

  const [filteredTriggers, setFilteredTriggers] = useState<ITrigger[] | null>(
    null
  );
  useEffect(() => {
    if (cardType === 'prediction') {
      let filtered: ITrigger[] = [];
      if (triggersContext) {
        (triggersContext as Map<number, ITrigger>).forEach((v: ITrigger) => {
          if (item) {
            if (item.triggers) {
              if (item.triggers.includes(v.name)) {
                filtered.push(v);
              }
            }
          }
        });
        if (filtered.length !== 0) {
          setFilteredTriggers(filtered);
        }
      }
    }
  }, [triggersContext, item]);

  const showHidePropFC = () => {
    setShowHideProp(!showHideProp);
  };

  const showHideSummaryFC = () => {
    setShowHideSummary(!showHideSummary);
  };


  const cardSeriesNew: any = item;

  const coreCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['0']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['0']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['0']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['0']?.year) || 0);

  const uncommonCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['1']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['1']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['1']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['1']?.year) || 0);

  const rareCardInfo =
    (Number(cardSeriesNew?.guaranteed?.['2']?.category) || 0) +
    (Number(cardSeriesNew?.guaranteed?.['2']?.day_month) || 0) +
    (cardSeriesNew?.guaranteed?.['2']?.trigger?.length || 0) +
    (Number(cardSeriesNew?.guaranteed?.['2']?.year) || 0);
  return (
    <ViewDateCardWrapper
      isview={isView ? 'true' : undefined}
      key={`sell-date-card-${item?.id}`}
    >
      <ViewDateCardContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>
          Sell{' '}
          {cardType === 'trigger'
            ? 'Trigger'
            : cardType === 'identity'
            ? 'Identity'
            : cardType === 'prediction'
            ? 'Prediction'
            : cardType === 'category'
            ? 'Category'
            : cardType === 'cardPacks'
            ? 'Card Pack'
            : cardType === 'crafting'
            ? 'Crafting'
            : 'Date Card'}
        </h2>
        <PreviewCardWrapper>
          {cardType === 'trigger' ? (
            <TriggerCard
              tier={item?.tier}
              image={item?.image}
              trigger={item?.trigger}
              rarity={item?.rarity}
              isNotHover={true}
            />
          ) : cardType === 'identity' ? (
            <IdentityCard
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              year={item?.year}
              icon={item?.icon}
              iconText={item?.iconText}
              celebrity_name={item?.celebrity_name}
              image={item?.image}
              cardType={cardType}
            />
          ) : cardType === 'prediction' ? (
            <PredictionCard
              item={item}
              {...item}
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              year={item?.year}
              icon={item?.icon}
              iconText={item?.iconText}
              celebrity_name={item?.celebrity_name}
              image={item?.image}
              cardType={cardType}
            />
          ) : cardType === 'category' ? (
            <CategoryCard
              item={item}
              day={item?.day}
              month={item?.month}
              category={item?.category}
              rarity={item?.rarity}
              image={item?.image}
              position=""
            />
          ) : cardType === 'cardPacks' ? (
            <CardPack
              item={item}
              rarity={item?.rarity}
              image={item?.image}
              tier={item?.tier}
            />
          ) : cardType === 'crafting' ? (
            <CraftingCard
              item={item}
              day={item?.day}
              month={item?.month}
              image={item?.image}
              category={item?.category}
              rarity={item?.rarity}
              isNotHover={true}
              position=""
            />
          ) : (
            <DateCard
              item={item}
              day={item?.day}
              month={item?.month}
              image={item?.image}
              category={item?.category}
              rarity={item?.rarity}
              isNotHover={true}
              position=""
            />
          )}
        </PreviewCardWrapper>
        <PropertiesWrapper>
          <PropertiesHeader onClick={toggleShowHideProp}>
            <span>Properties</span>
            {showHideProp ? <IconArrowDown /> : <IconArrowUp />}
          </PropertiesHeader>
          {showHideProp && (
            <PropertiesContent>
              {cardType === 'identity' || cardType === 'prediction' ? (
                <PropertyItem>
                  <p>Celebrity</p>
                  <span>{item?.celebrity_name}</span>
                </PropertyItem>
              ) : null}
              <PropertyItem>
                <p>{cardType === 'cardPacks' ? 'Pack Type' : 'Rarity'}</p>
                <span>
                  {(item?.rarity === 0 && 'Core') ||
                    (item?.tier === 0 && 'Standard')}
                  {(item?.rarity === 1 && 'Uncommon') ||
                    (item?.tier === 1 && 'Premium')}
                  {(item?.rarity === 2 && 'Rare') ||
                    (item?.tier === 2 && 'Elite')}
                </span>
              </PropertyItem>

              {cardType === 'identity' ? (
                <PropertyItem>
                  <p>Month/Day</p>
                  <span>{`${
                    monthContext &&
                    (monthContext as Map<number, string>).get(item?.month)
                  } ${item?.day}`}</span>
                </PropertyItem>
              ) : cardType === 'date' ? (
                <PropertyItem>
                  <p>Type</p>
                  <span>{item?.day ? 'Month/Day' : 'Year'}</span>
                </PropertyItem>
              ) : null}
              {cardType === 'trigger' ? (
                <PropertyItem>
                  <p>Tire</p>
                  <span>{item?.tier}</span>
                </PropertyItem>
              ) : cardType === 'identity' ? (
                <PropertyItem>
                  <p>Year</p>
                  <span>{item?.year}</span>
                </PropertyItem>
              ) : cardType === 'date' ? (
                <PropertyItem>
                  <p>{item?.day ? 'Month/Day' : 'Year'}</p>
                  <span>
                    {item?.day
                      ? `${
                          monthContext &&
                          (monthContext as Map<number, string>).get(item?.month)
                        } ${item?.day}`
                      : item?.year}
                  </span>
                </PropertyItem>
              ) : cardType === 'category' ? (
                <PropertyItem>
                  <p>Category</p>
                  <span>{item?.category}</span>
                </PropertyItem>
              ) : null}

              <PropertyItem>
                <p>
                  {cardType === 'trigger'
                    ? 'Trigger'
                    : cardType === 'identity'
                    ? 'Category'
                    : 'Collection'}
                </p>
                <span>
                  {cardType === 'trigger'
                    ? item?.trigger
                    : cardType === 'identity'
                    ? item?.category
                    : 'Genesis'}
                </span>
              </PropertyItem>

              {/* <PropertyItem>
                <p>Collection</p>
                <span>{'Genesis'}</span>
              </PropertyItem> */}

              {cardType === 'prediction' && filteredTriggers && (
                <>
                  <PropertiesHeader noborder={'true'}>
                    <span>Triggers</span>
                    <span>{filteredTriggers?.length}</span>
                  </PropertiesHeader>
                  {filteredTriggers.map((item: ITrigger, key: number) => (
                    <PropertyItem key={key} nfttrigger={'true'}>
                      <p>{item.tier}</p>

                      <span>{item.name}</span>
                    </PropertyItem>
                  ))}
                </>
              )}
              {cardType === 'cardPacks' && (
                <>
                  <PropertiesHeader onClick={toggleCollapsedTwo}>
                    <span>Pack Contents</span>
                    <div className={collapsedTwo ? 'collapsed' : ''}>
                      <IconArrowDown />
                    </div>
                  </PropertiesHeader>
                  {!collapsedTwo && (
                    <PropertiesContent>
                      <PropertyItem>
                        <p>{rareCardInfo} Rare Cards</p>
                      </PropertyItem>
                      <PropertyItem>
                        <p>{uncommonCardInfo} Uncommon Cards</p>
                      </PropertyItem>
                      <PropertyItem>
                        <p>{coreCardInfo} Core Cards</p>
                      </PropertyItem>
                      <PropertyItem>
                        <p>{item?.crafting_cards} Crafting Card</p>
                      </PropertyItem>
                    </PropertiesContent>
                  )}
                </>
              )}
            </PropertiesContent>
          )}
        </PropertiesWrapper>

        {/* <Input
            value={priceValue}
            onChange={(e) => {
              let result = e.target.value.replace(/[a-z]/gi, '');
              if (result == '') {
                handlePriceChange('$1');
              } else handlePriceChange(result);
            }}
          /> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <SetPriceWrapper>
            <p>Enter your offering price</p>
            <PriceInputWrapper>
              <input
                type="number"
                step="0.01"
                {...register('price', {
                  valueAsNumber: true
                })}
              />
              <div className="currency">USD</div>
            </PriceInputWrapper>
            <p className="errorText">
              <ErrorMessage errors={errors} name="price" />
            </p>
          </SetPriceWrapper>

          <PropertiesWrapper>
            <PropertiesHeader onClick={toggleShowHideSummary}>
              <span>Price Summary</span>
              {showHideSummary ? <IconArrowDown /> : <IconArrowUp />}
            </PropertiesHeader>
            {showHideSummary && (
              <PropertiesContent>
                <PriceItem>
                  <p>Asking price</p>
                  <p className="hello">
                    {`$${String(
                      Number(watchPrice).toFixed(2) || (0).toFixed(2)
                    )}`}{' '}
                    USD
                  </p>
                </PriceItem>
                <PriceItem>
                  <p>Marketplace fee (5%)</p>
                  <span className="weak">{`$${calculateMarketplaceFee(
                    String(watchPrice || 0)
                  )} USD`}</span>
                </PriceItem>
                <PriceItem>
                  <p>Prize Pool Replenishment Fee (5%)</p>
                  <span className="weak">{`$${calculatePrizePoolFee(
                    String(watchPrice || 0)
                  )} USD`}</span>
                </PriceItem>
              </PropertiesContent>
            )}
            <PropertiesContent>
              <PriceItem>
                <p>Net amount to seller</p>
                <span>{`$${
                  totalPrice ? totalPrice.toFixed(2) : (0).toFixed(2)
                } USD`}</span>
              </PriceItem>
            </PropertiesContent>
          </PropertiesWrapper>

          {/* <Button
            className="sell-confirm-button"
            onClick={() => onSellConfirm(item.id, nftCollectionId, totalPrice)}
          >
            Confirm
          </Button> */}
          <SubmitButton className="sell-confirm-button" type="submit">
            Confirm
          </SubmitButton>
        </form>
      </ViewDateCardContainer>
    </ViewDateCardWrapper>
  );
};
