import { MyOfferCardContainer } from './styles';
import { IdentityCard } from '../IdentityCard';
import {
  acceptMarketplaceOffer,
  rejectMarketplaceOffer
} from '../../actions/marketplace_offer';
import { CardImgWrapper } from '../MarketCard/styles';
import { PredictionCard } from '../PredictionCard';
import { CategoryCard } from '../CategoryCard';
import { DateCard } from '../DateCard';
import { CraftingCard } from '../CraftingCard';
import { TriggerCard } from '../TriggerCard';
import { CardPack } from '../CardPack';
import { toast } from 'react-toastify';

const MyOfferCard = ({ item, loadOffers }: any) => {
  const handleAcceptOffer = async (id: any) => {
    const res = await acceptMarketplaceOffer(id);
  };

  const handleRejectOffer = async (id: any) => {
    await rejectMarketplaceOffer(id);
    loadOffers();
    toast.success('Offer rejected!');
  };

  return (
    <MyOfferCardContainer>
      {item.nft_card_category && (
        <CategoryCard
          item={item.nft_card_category}
          {...item.nft_card_category}
        />
      )}
      {item.nft_card_day_month && (
        <DateCard
          position="right"
          item={item.nft_card_day_month}
          {...item.nft_card_day_month}
        />
      )}
      {item.nft_card_year && (
        <DateCard
          position="right"
          item={item.nft_card_year}
          {...item.nft_card_year}
        />
      )}
      {item.nft_card_crafting && (
        <CraftingCard
          position="right"
          item={item.nft_card_crafting}
          {...item.nft_card_crafting}
        />
      )}
      {item.nft_card_trigger && (
        <TriggerCard
          tier={item?.nft_card_trigger.tier}
          item={item.nft_card_trigger}
          {...item.nft_card_trigger}
        />
      )}
      {/* <CardPack
        item={item}
        {...item}
        // onOpen={onCraft}
        // onView={onView}
        // onSell={onSell}
        // onCancel={onCancel}
      /> */}
      {item.nft_card_prediction && (
        <PredictionCard
          cardType="prediction"
          height={293}
          isNotHover={true}
          item={item.nft_card_prediction}
          {...item.nft_card_prediction}

          // onCraft={onCraft}
          // onView={onView}
          // onSell={onSell}
          // onClaimSubmit={onClaimSubmit}
          // onCancel={onCancel}
        />
      )}
      {item.nft_card_identity && (
        <IdentityCard
          cardType="identity"
          height={293}
          isNotHover={true}
          item={item.nft_card_identity}
          {...item.nft_card_identity}

          // onCraft={onCraft}
          // onView={onView}
          // onSell={onSell}
          // onClaimSubmit={onClaimSubmit}
        />
      )}
      {/* <IdentityCard
        isNotHover={true}
        item={{
          card_series_id: 1,
          category: 'Musician',
          created_at: '2023-11-30T15:44:42.153882Z',
          day: 9,
          id: 372,
          is_crafted: true,
          marketplace_listing: null,
          month: 9,
          owner_id: 6,
          rarity: 1,
          year: 1987
        }}
        {...{
          card_series_id: 1,
          category: 'Musician',
          celebrity_name: 'afrojack',
          created_at: '2023-11-30T15:44:42.153882Z',
          day: 9,
          id: 372,
          is_crafted: true,
          marketplace_listing: null,
          month: 9,
          owner_id: 6,
          rarity: 1,
          year: 1987
        }}
        cardType="identity"
      /> */}
      <div className="offer-text-block">
        <h3>By User: {item.buyer_id}</h3>
        <h4>
          ${item.amount?.toFixed(2)} <span>USD</span>
        </h4>
      </div>

      <div className="button accept" onClick={() => handleAcceptOffer(item.id)}>
        Accept Offer
      </div>
      <div
        className="button reject"
        onClick={() => handleRejectOffer(item.marketplace_offer_id)}
      >
        Reject Offer
      </div>

      <CardImgWrapper rarity={0}></CardImgWrapper>
    </MyOfferCardContainer>
  );
};

export default MyOfferCard;
