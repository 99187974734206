import api from '../config/api';
import { ICardPack } from '../models/card_pack';

export const getMyNftCardPack = async (filters?: any) => {
  try {
    // todo
    // let q_params = new URLSearchParams();
    // if (filters.rarities) {
    //   q_params.append('rarities', filters.rarities.join(','));
    // }

    // if (filters.status) {
    //   q_params.append('status', filters.status.join(','));
    // }

    // if (filters.card_collection_id) {
    //   q_params.append(
    //     'card_collection_id',
    //     filters.card_collection_id.join(',')
    //   );
    // }
    const res = await api.get<ICardPack[]>('/me/card_pack');
    return { success: true, data: res.data };
  } catch (error) {
    return { success: false, message: error };
  }
};
