import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  BuyPackCollectionWrapper,
  BuyPackSectionWrapper,
  BuyPackSlider,
  BuyPackTextWrapper,
  BuyPackWrapper
} from './styles';
import { Navigation } from 'swiper';
import { BuyCard } from '../../components';
import { BuyDetailsSection } from './BuyDetailsSection';
import { useNavigate } from 'react-router-dom';
import api from '../../config/api';
import { ICardSeries } from '../../models/card_series';
import { toast } from 'react-toastify';
import { ICardPack } from '../../models/card_pack';
import { ICardCollection } from '../../models/card_collection';
import { getMyInfo } from '../../actions';
import { useMyInfoContext } from '../../context';

import { loadMoonPay } from '@moonpay/moonpay-js';

export const BuyPackSection: React.FC = () => {
  const navigate = useNavigate();
  const [detailsView, setDetailsView] = useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [selectedCardSeries, setSelectedCardSeries] =
    useState<ICardSeries | null>(null);
  const [selectedRarity, setSelectedRarity] = useState<number>(0);
  // const [cardSeriesList, setCardSeriesList] = useState<ICardSeries[]>([]);
  const [cardCollectionList, setCardCollectionList] = useState<
    ICardCollection[]
  >([]);
  const { setMyInfoContext } = useMyInfoContext();

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
    console.log('USER SETUP --->', localStorage.getItem('auth'));
    // showMoonpay();
  }, []);
  // This is an example environment variable you would set in your .env.local file
  const MOONPAY_API_KEY = process.env.NEXT_PUBLIC_MOONPAY_API_KEY;
  const showMoonpay = async () => {
    const myinfo = await getMyInfo();

    const address = myinfo.data.wallet_address;
    // console.log('THIS IS THE USER', address);
    let moonPay = await loadMoonPay();
    if (moonPay) {
      const moonPaySdk = moonPay({
        flow: 'buy',
        environment: 'sandbox',
        variant: 'overlay',
        params: {
          apiKey: 'pk_test_PaUTi3HVAHvclaZTMJS0TNTfMIrpPj',
          baseCurrencyCode: 'usd',
          defaultCurrencyCode: 'usdc',
          signature: 'test',
          walletAddress: address
        },
        debug: true
      });

      if (moonPaySdk) {
        const urlForSignature = moonPaySdk.generateUrlForSigning();
        api
          .post('/webhook/moonpay/sign', { url: urlForSignature })
          .then((res) => {
            console.log(res);
            moonPaySdk.updateSignature(res.data.signature);
            moonPaySdk.show();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('error showing moonpay');
      }
    }
  };

  const handleBuyCardSeries = async (
    cardSeries: ICardSeries,
    quantity: number,
    payment_method_id: number
  ) => {
    switch (payment_method_id) {
      case 2:
        showMoonpay();
        break;
      case 1:
        try {
          let res = await api.post<ICardPack>(
            `/card_series/${cardSeries.id}/buy`,
            {
              quantity,
              payment_method_id
            }
          );
          if (res.data) {
            const myinfo = await getMyInfo();
            if (myinfo.data) setMyInfoContext(myinfo.data);
            toast.success(
              `You bought a ${
                cardSeries.card_collection
                  ? cardSeries.card_collection.name
                  : ''
              } ${cardSeries.name || 'new'} card pack!`
            );
            setDetailsView(false);
          }
        } catch (e: any) {
          toast.error(e?.response?.data?.message || "No more pack available");
        }
        break;
      default:
        break;
    }
  };

  const handleCardClick = (cardSeries: ICardSeries) => {
    setSelectedCardSeries(cardSeries);
    currentUser ? setDetailsView(true) : navigate('/signup');
  };

  const handleDetailsClose = () => {
    setDetailsView(false);
  };

  const listCardSeries = async () => {
    try {
      let res = await api.get<ICardSeries[]>('/card_series');
      if (res.data) {
        // setCardSeriesList(res.data);
        let map = new Map<number, ICardCollection>();
        let list: ICardCollection[] = [];
        res.data.forEach((v) => {
          let s = v;
          // s.card_collection = undefined;
          let o = map.get(v.card_collection_id);
          if (o && o.card_series) {
            o.card_series.push(s);
            map.set(v.card_collection_id, o);
          } else {
            if (v.card_collection) {
              let n = v.card_collection;
              n.card_series = [s];
              map.set(s.card_collection_id, n);
            }
          }
        });

        map.forEach((v) => {
          v?.card_series?.sort((a, b) => a.cost_usd - b.cost_usd);
          list.push(v);
        });

        setCardCollectionList(list);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    listCardSeries();
  }, []);

  console.log('selectedCardSeries', selectedCardSeries);
  return (
    <BuyPackSectionWrapper isview={detailsView ? 'true' : undefined}>
      <BuyPackWrapper>
        <h2>Buy Card Packs</h2>
        {cardCollectionList.map((item, key) => (
          <BuyPackCollectionWrapper key={key}>
            <BuyPackTextWrapper>
              <h3>{item.name}</h3>
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam lorem ipsum lia. aliqua dolor do amet sint.
                Velit officit.
              </p>
            </BuyPackTextWrapper>
            <BuyPackSlider>
              {/* <Swiper
                slidesPerView={1}
                spaceBetween={42}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 42,
                  },
                }}
                className="mySwiper"
              > */}
              {item.card_series &&
                item.card_series.map((item, key) => {
                  // render by lowest item.cost_usd

                  return (
                    <BuyCard
                      key={key}
                      cardSeries={item}
                      rarity={1}
                      cardType={item?.name}
                      price={50}
                      onCardClick={handleCardClick}
                    />
                  );
                })}
              {/* <SwiperSlide key={key}>
                    <BuyCard
                      cardSeries={item}
                      rarity={1}
                      cardType={item?.name}
                      price={50}
                      onCardClick={handleCardClick}
                    />
                  </SwiperSlide> */}
              {/* </Swiper> */}
            </BuyPackSlider>
          </BuyPackCollectionWrapper>
        ))}
        {selectedCardSeries && (
          <BuyDetailsSection
            id={selectedCardSeries?.id || 0}
            isView={detailsView && selectedCardSeries != null}
            onClose={handleDetailsClose}
            onBuyClick={handleBuyCardSeries}
            onMoonpayClick={showMoonpay}
            cardSeries={selectedCardSeries}
            rarity={selectedRarity}
          />
        )}
      </BuyPackWrapper>
    </BuyPackSectionWrapper>
  );
};
