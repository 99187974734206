import React, { ChangeEvent, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Entries,
  EntryAction,
  SaveButton,
} from "../styles";
import { PencilAlt, TrashIcon } from "../../../../../components/Icons";
import { ICardCollection } from '../../../../../models/collection';
interface Rarity {
  id: number;
  name: string;
}

const CardRarities = ({ collection }: { collection: ICardCollection }) => {
  const [editMode, setEditMode] = useState<{ [key: number]: boolean }>({});
  const [editedText, setEditedText] = useState<{ [key: number]: string }>({});
  const [rarities, setRarities] = useState<Rarity[]>([
    { id: 1, name: 'Rare' },
    { id: 2, name: 'Uncommon' },
    { id: 3, name: 'Core' }
  ]);

  const handleEditClick = (id: number) => {
    setEditMode({ ...editMode, [id]: true });
    setEditedText({
      ...editedText,
      [id]: rarities.find((r) => r.id === id)?.name || ''
    });
  };

  const handleSaveClick = (id: number) => {
    setEditMode({ ...editMode, [id]: false });
    setRarities(
      rarities.map((r) => (r.id === id ? { ...r, name: editedText[id] } : r))
    );
    setEditedText({ ...editedText, [id]: '' });
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setEditedText({ ...editedText, [id]: event.target.value });
  };

  const handleDeleteRarity = (id: number) => {
    setRarities(rarities.filter((r) => r.id !== id));
  };

  const handleAddRarity = () => {
    const newId =
      rarities.length > 0 ? rarities[rarities.length - 1].id + 1 : 1;
    setRarities([...rarities, { id: newId, name: '' }]);
    setEditMode({ ...editMode, [newId]: true });
    setEditedText({ ...editedText, [newId]: '' });
  };

  return (
    <Card>
      <CardHeader>Card Rarities</CardHeader>
      <CardBody>
        <Entries>
          {rarities.map((rarity) => (
            <li key={rarity.id}>
              {editMode[rarity.id] ? (
                <input
                  type="text"
                  className="input-text"
                  value={editedText[rarity.id]}
                  onChange={(e) => handleInputChange(e, rarity.id)}
                />
              ) : (
                <span>{rarity.name}</span>
              )}
              <EntryAction>
                {editMode[rarity.id] ? (
                  <SaveButton onClick={() => handleSaveClick(rarity.id)}>
                    Save
                  </SaveButton>
                ) : (
                  <button
                    className="svg-btn"
                    onClick={() => handleEditClick(rarity.id)}
                  >
                    <PencilAlt />
                  </button>
                )}
                <button
                  className="svg-btn"
                  onClick={() => handleDeleteRarity(rarity.id)}
                >
                  <TrashIcon />
                </button>
              </EntryAction>
            </li>
          ))}
        </Entries>
        <button onClick={handleAddRarity}>Add New Rarity</button>
      </CardBody>
    </Card>
  );
};

export default CardRarities;