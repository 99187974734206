import React, { useState, useEffect } from "react";
import { AdminLayout } from "../../../layout";
import {
  WithdrawManagementPageWrapper,
  CreateButton,
  PageActionWrapper,
} from "./styles";
import { AdminSearchInput } from "../../../components";
import { WithdrawManagementTable } from "../../../modules";
import { IWithdrawal } from "../../../models/claim";
import api from "../../../config/api";

export const WithdrawManagementPage: React.FC = () => {
  const [filterValue, setFilterValue] = useState("");

  const [withdrawals, setWithdrawals] = useState<IWithdrawal[]>([]);

  const filterData = (query: string) => {
    return withdrawals
        .map((item: IWithdrawal) => ({
          ...item,
          statusStr:
            item.status === 0
              ? "requested"
              : item.status === 1
              ? "approved"
              : "rejected",
        }))
        .filter(
          (f: any) =>
            f.date.toLowerCase().includes(filterValue.toLowerCase()) ||
            f.predictions.toLowerCase().includes(filterValue.toLowerCase()) ||
            f.user.toLowerCase().includes(filterValue.toLowerCase()) ||
            f.statusStr.toLowerCase().includes(filterValue.toLowerCase())
        )
  }

  const fetchData = async () => {
    try {
      let res = await api.get<any[]>('/withdrawal_transaction');
      if (res.status === 200) {
        setWithdrawals(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async (withdrawal: IWithdrawal) => {
    try {
      let res = await api.put(`/withdrawal_transaction/${withdrawal.id}`, {
        status: 1
      });
      if (res.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async (withdrawal: IWithdrawal) => {
    try {
      let res = await api.put(`/withdrawal_transaction/${withdrawal.id}`, {
        status: 2
      });
      if (res.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveSelected = async (withdrawals: IWithdrawal[]) => {
    try {
      let res = await api.put(`/withdrawal_transaction`, {
        status: 1,
        ids: withdrawals.filter((v) => v.status === 0).map((item) => item.id)
      });
      if (res.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectSelected = async (withdrawals: IWithdrawal[]) => {
    try {
      let ids = withdrawals.filter(v => v.status === 0).map((item) => item.id);
      let res = await api.put(`/withdrawal_transaction`, {
        status: 2,
        ids: ids
      });
      if (res.status === 200) {
        let n: IWithdrawal[] = []; 
        withdrawals.forEach((item) => {
          if (ids.includes(item.id)) item.status = 2
          n.push(item)
        })
        setWithdrawals(n);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <WithdrawManagementPageWrapper>
        <h1>Event Validation</h1>
        <PageActionWrapper>
          <AdminSearchInput
            onChange={(e) => setFilterValue(e.target.value)}
            value={filterValue}
            bg="white"
          />
          {/* <CreateButton>Create Post</CreateButton> */}
        </PageActionWrapper>
        <WithdrawManagementTable
          allData={withdrawals}
          onApprove={handleApprove}
          onApproveSelected={handleApproveSelected}
          onReject={handleReject}
          onRejectSelected={handleRejectSelected}
        />
      </WithdrawManagementPageWrapper>
    </AdminLayout>
  );
};
