import React, { useState } from "react";
import {
  TrashIcon,
  PencilAlt,
  IconArrowUp,
  IconArrowDown,
} from "../../../../../components";
import {
  Card,
  CardHeader,
  CardBody,
  CardTable,
  CardTableHead,
  CardTableBody,
  CardTableTr,
  CardTableTd,
  CardTableTh,
  SaveButton,
} from "../styles";
import {
  CardTypeValue,
  ICardSeries,
  ICardCollection,
  CardSeriesGuaranteed,
} from "../../../../../models/collection";
interface GuaranteedType {
  day_month: number;
  year: number;
  trigger:  number;
  category: number;
  crafting: number;
}

interface GuaranteedItem {
  core?: GuaranteedType;
  premium?: GuaranteedType;
  elite?: GuaranteedType;
}

type GuaranteedState = GuaranteedItem[];
type CollapsedRows = {
  [key: number]: boolean;
};

type RowItem = {
  rowId: number;
  packType: string;
  cardsPerPack: number;
  packsQty: number;
  price: number;
  guarRarity: string;
  guarQtyRarity: number;
  cardType: string[];
  guarQtyCardType: number[];
};
type CollapsibleRowProps = {
  rowData: ICardSeries;
  guaranteedState: GuaranteedState;
  handleGuaranteedChange: (
    index: number,
    key: keyof GuaranteedItem,
    field: keyof GuaranteedType,
    value: number
  ) => void;

  isExpanded: boolean;
  toggleCollapse: (rowId: number) => void;
  isEditing: boolean;
  startEditing: (rowId: number | null) => void;
  updateRowData: (rowId: number, updatedData: RowItem) => void;
};
const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  rowData,
  guaranteedState,
  handleGuaranteedChange,
  isExpanded,
  toggleCollapse,
  isEditing,
  startEditing,
  updateRowData
}) => {
  const {
    id,
    name, //tier
    cards_per_pack,
    card_pack_quantity,
    cost_usd
  } = rowData;

  const calculateTotalGuaranteed = (v: CardTypeValue) => {
    return v.category + v.day_month + v.year + v.trigger;
  };

  const [formData, setFormData] = useState({
    cardsPerPack: cards_per_pack,
    packsQty: card_pack_quantity,
    price: cost_usd
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const onGuaranteedFieldChange = (event:any, index:any, key:any, field:any) => {
    const value = parseInt(event.target.value, 10);
    handleGuaranteedChange(index, key, field, value);
  };
  const handleSave = () => {
    if (rowData.id !== undefined) {
      // Ensure rowData.id is not undefined
      const updatedRowData: RowItem = {
        rowId: rowData.id,
        packType: rowData.name, // Assuming 'name' field maps to 'packType'
        cardsPerPack: formData.cardsPerPack,
        packsQty: formData.packsQty,
        price: formData.price,
        // Add default values or retrieve from somewhere else
        guarRarity: '', // Default value or from another source
        guarQtyRarity: 0, // Default value or from another source
        cardType: [], // Default value or from another source
        guarQtyCardType: [] // Default value or from another source
      };
      updateRowData(rowData.id, updatedRowData);
      startEditing(null);
      toggleCollapse(rowData.id);
    }
  };

  return (
    <CardTableTr className={isExpanded ? 'rowCollapsed' : ''}>
      <CardTableTd>{name}</CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-number"
            type="number"
            value={formData?.cardsPerPack}
            onChange={handleSelectChange}
            name="cardsPerPack"
          />
        ) : (
          formData.cardsPerPack
        )}
      </CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-number"
            type="number"
            value={formData?.packsQty}
            onChange={handleSelectChange}
            name="packsQty"

            // onChange={(e) =>
            //   updateRowData(rowId, {
            //     ...rowData,
            //     packsQty: Number(e.target.value),
            //   })
            // }
          />
        ) : (
          formData.packsQty
        )}
      </CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-number"
            type="number"
            value={formData.price}
            onChange={handleSelectChange}
            name="price"
          />
        ) : (
          `$${formData.price}`
        )}
      </CardTableTd>

      <CardTableTd className="childTable">
        <CardTable>
          <thead className="subTableHead">
            <CardTableTr>
              <CardTableTh>guar. rarity</CardTableTh>
              <CardTableTh>guar. QTY/rarity</CardTableTh>
              <CardTableTh>card type</CardTableTh>
              <CardTableTh>guar. QTY/card type</CardTableTh>
            </CardTableTr>
          </thead>
          <CardTableBody className="subTableBody">
            {guaranteedState.map((item, index) => {
              const categoryKey = Object.keys(item)[0];
              const categoryData = (item as any)[categoryKey] as CardTypeValue;

              return (
                <CardTableTr key={index}>
                  <CardTableTd>{categoryKey}</CardTableTd>
                  <CardTableTd>
                    {calculateTotalGuaranteed(categoryData)}
                  </CardTableTd>

                  <CardTableTd className="col-btn">
                    {index === 0 && !isEditing && (
                      <button
                        className={isExpanded ? 'expanded' : 'collapsed'}
                        onClick={() => toggleCollapse(id ? id : 0)}
                      >
                        See Details
                      </button>
                    )}

                    <ul
                      className={`collapsible-row-content ${
                        isExpanded ? 'expanded' : 'collapsed'
                      }`}
                    >
                      <li key={0}>Day & Month</li>
                      <li key={1}>Year</li>
                      <li key={2}>Trigger</li>
                      <li key={3}>Category</li>
                      <li key={4}>Crafting</li>
                    </ul>
                  </CardTableTd>
                  <CardTableTd className="col-btn">
                    {index === 0 && !isEditing && (
                      <button
                        className={isExpanded ? 'expanded' : 'collapsed'}
                        onClick={() => toggleCollapse(id ? id : 0)}
                      >
                        {isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                      </button>
                    )}

                    <ul
                      className={`collapsible-row-content ${
                        isExpanded ? 'expanded' : 'collapsed'
                      }`}
                    >
                      {isEditing ? (
                        <>
                          <li key={0} className="edit-list">
                            <input
                              className="input-number"
                              type="number"
                              value={categoryData.day_month}
                              onChange={(e) =>
                                onGuaranteedFieldChange(
                                  e,
                                  index,
                                  categoryKey,
                                  'day_month'
                                )
                              }
                              name={'day_month'}
                            />
                          </li>
                          <li key={1} className="edit-list">
                            <input
                              className="input-number"
                              type="number"
                              value={categoryData.year}
                              onChange={(e) =>
                                onGuaranteedFieldChange(
                                  e,
                                  index,
                                  categoryKey,
                                  'year'
                                )
                              }
                              name={'year'}
                            />
                          </li>
                          <li key={2} className="edit-list">
                            <input
                              className="input-number"
                              type="number"
                              value={categoryData.trigger}
                              onChange={(e) =>
                                onGuaranteedFieldChange(
                                  e,
                                  index,
                                  categoryKey,
                                  'trigger'
                                )
                              }
                              name={'trigger'}
                            />
                          </li>
                          <li key={3} className="edit-list">
                            <input
                              className="input-number"
                              type="number"
                              value={categoryData.category}
                              onChange={(e) =>
                                onGuaranteedFieldChange(
                                  e,
                                  index,
                                  categoryKey,
                                  'category'
                                )
                              }
                              name={'category'}
                            />
                          </li>
                          <li key={4} className="edit-list">
                            <input
                              className="input-number"
                              type="number"
                              value={categoryData.crafting}
                              onChange={(e) =>
                                onGuaranteedFieldChange(
                                  e,
                                  index,
                                  categoryKey,
                                  'crafting'
                                )
                              }
                              name={'crafting'}
                            />
                          </li>
                        </>
                      ) : (
                        <li key={0}>{categoryData.day_month}</li>
                      )}
                    </ul>
                  </CardTableTd>
                </CardTableTr>
              );
            })}
            {/* <CardTableTr> */}
            {/* <CardTableTd>0</CardTableTd>
              <CardTableTd className="col-btn">
                {!isEditing && (
                  <button
                    className={isExpanded ? "expanded" : "collapsed"}
                    onClick={() => toggleCollapse(id ? id : 0)}
                  >
                    See Details
                  </button>
                )}

                <ul
                  className={`collapsible-row-content ${
                    isExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  <li key={0}>Day & Month</li>
                  <li key={1}>Year</li>
                  <li key={2}>Trigger</li>
                  <li key={3}>Category</li>
                  <li key={4}>Crafting</li>
                </ul>
              </CardTableTd>
              <CardTableTd className="col-btn">
                {!isEditing && (
                  <button
                    className={isExpanded ? "expanded" : "collapsed"}
                    onClick={() => toggleCollapse(id ? id : 0)}
                  >
                    {isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                  </button>
                )}

                <ul
                  className={`collapsible-row-content ${
                    isExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  {isEditing ? (
                    <li key={0} className="edit-list">
                      <input
                        className="input-number"
                        type="number"
                        value={2}
                        // value={guaranteed[0].core.day_month}
                        onChange={handleSelectChange}
                        name={"day_month"}
                      />
                    </li>
                  ) : (
                    <li key={0}>0</li>
                    // <li key={0}>{guaranteed[0].core.day_month}</li>
                  )}
                </ul>
              </CardTableTd>
            </CardTableTr>
            <CardTableTr> */}
            {/* <CardTableTd>Core</CardTableTd> */}

            {/* <CardTableTd>{calculateTotalGuaranteed(guaranteed[0].core)}</CardTableTd> */}
            {/* <CardTableTd>0</CardTableTd>
              <CardTableTd className="col-btn">
                <ul
                  className={`collapsible-row-content ${
                    isExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  <li key={0}>Day & Month</li>
                  <li key={1}>Year</li>
                  <li key={2}>Trigger</li>
                  <li key={3}>Category</li>
                  <li key={4}>Crafting</li>
                </ul>
              </CardTableTd>
              <CardTableTd className="col-btn">
                <ul
                  className={`collapsible-row-content ${
                    isExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  {isEditing ? (
                    <li key={0} className="edit-list">
                      <input
                        className="input-number"
                        type="number"
                        value={2}
                        // value={guaranteed[0].core.day_month}
                        onChange={handleSelectChange}
                        name={"day_month"}
                      />
                    </li>
                  ) : (
                    <li key={0}>0</li>
                    // <li key={0}>{guaranteed[0].core.day_month}</li>
                  )}
                </ul>
              </CardTableTd> */}
            {/* </CardTableTr> */}
          </CardTableBody>
        </CardTable>
      </CardTableTd>

      <CardTableTd className="col-action">
        {isEditing ? (
          <>
            <SaveButton onClick={handleSave}>Save</SaveButton>
            <button className="svg-btn">
              <TrashIcon />
            </button>
          </>
        ) : (
          <>
            <button
              className="svg-btn"
              onClick={() => {
                toggleCollapse(id ? id : 0);
                startEditing(id ? id : 0);
              }}
            >
              <PencilAlt />
            </button>
            <button className="svg-btn">
              <TrashIcon />
            </button>
          </>
        )}
      </CardTableTd>
    </CardTableTr>
  );
};

// Sample data for your rows


const CardPackValue: React.FC<{
  collection: ICardCollection;
  onChange: (data: ICardCollection) => void;
}> = ({ collection, onChange }) => {
  const [collapsedRows, setCollapsedRows] = useState<CollapsedRows>({});
  const [editingRow, setEditingRow] = useState<number | null>(null);

  const toggleCollapse = (rowId: number) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };
const [guaranteedState, setGuaranteedState] = React.useState<GuaranteedState>([
  {
    core: {
      day_month: 0,
      year: 0,
      trigger: 0,
      category: 0,
      crafting: 0
    }
  },
  {
    premium: {
      day_month: 0,
      year: 0,
      trigger: 0,
      category: 0,
      crafting: 0
    }
  },
  {
    elite: {
      day_month: 0,
      year: 0,
      trigger: 0,
      category: 0,
      crafting: 0
    }
  }
]);
const handleGuaranteedChange = (
  index: number,
  key: keyof GuaranteedItem,
  field: keyof GuaranteedType,
  value: number
) => {
  setGuaranteedState((prev) => {
    const newState = [...prev];
    newState[index][key] = {
      ...newState[index][key],
      [field]: value
    } as GuaranteedType;
    return newState;
  });
};
  const updateRowData = (rowId: number, updatedData: RowItem) => {
    const updatedSeries = collection.card_series?.map((series) =>
      series.id === rowId ? { ...series, ...updatedData } : series
    );
    onChange({ ...collection, card_series: updatedSeries ?? [] });
  };

  return (
    <Card>
      <CardHeader>
        Card Pack Values/Specification <IconArrowUp />{' '}
      </CardHeader>
      <CardBody>
        <CardTable>
          <CardTableHead>
            <CardTableTr>
              <CardTableTh>PACK TYPE</CardTableTh>
              <CardTableTh>CARDS PER PACK</CardTableTh>
              <CardTableTh>PACKS QTY</CardTableTh>
              <CardTableTh>price </CardTableTh>
              <CardTableTh>
                <CardTable>
                  <CardTableHead className="subTableHeadForPadding">
                    <CardTableTr>
                      <CardTableTh>guar. rarity</CardTableTh>
                      <CardTableTh>guar. QTY/rarity</CardTableTh>
                      <CardTableTh>card type</CardTableTh>
                      <CardTableTh>guar. QTY/card type</CardTableTh>
                    </CardTableTr>
                  </CardTableHead>
                </CardTable>
              </CardTableTh>
              {/* <CardTableTh>guar. QTY/rarity</CardTableTh>
              <CardTableTh>card type</CardTableTh>
              <CardTableTh>guar. QTY/card type</CardTableTh> */}
              <CardTableTh></CardTableTh>
            </CardTableTr>
          </CardTableHead>
          <CardTableBody>
            {collection.card_series &&
              collection.card_series.map((rowData) => (
                <CollapsibleRow
                  key={rowData.id}
                  rowData={rowData}
                  isExpanded={rowData.id ? collapsedRows[rowData.id] : false}
                  toggleCollapse={toggleCollapse}
                  isEditing={rowData.id === editingRow}
                  startEditing={setEditingRow}
                  updateRowData={updateRowData}
                  guaranteedState={guaranteedState}
                  handleGuaranteedChange={handleGuaranteedChange}
                />
              ))}
          </CardTableBody>
        </CardTable>
      </CardBody>
    </Card>
  );
};

export default CardPackValue;
