import React from 'react';
import {
  ItemAction,
  ItemInfo,
  MyProfileWrapper,
  ProfileItemWrapper,
  LogoutWrapper,
  RequiredText
} from './styles';
import { ProfileItemProps, ProfileProps } from '../../../typeroot/types';
import { TitleBG } from '../../../components';
import { Button } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, useMyInfoContext } from '../../../context';
import { IUser } from '../../../models/user';

export const Profile: React.FC<ProfileProps> = ({
  email,
  name,
  password,
  phone,
  username,
  onEdit
}) => {
  const navigate = useNavigate();
  const { setAuthContext } = useAuthContext();
  const { setMyInfoContext } = useMyInfoContext();

  //for logout
  const handleLogout = () => {
    localStorage.removeItem('auth');
    setAuthContext({
      isAuthenticated: false,
      user: undefined
    });
    setMyInfoContext({} as IUser);
    navigate('/dashboard/home');
  };

  return (
    <MyProfileWrapper>
      <h2>
        <TitleBG />
        <span>My Profile</span>
      </h2>
      <ProfileItem
        id="username"
        label="Username"
        value={username}
        isRequired
        onEdit={onEdit}
      />
      <ProfileItem
        id="name"
        label="Name"
        isRequired
        value={name}
        onEdit={onEdit}
      />
      <ProfileItem
        id="phone"
        label="Phone Number"
        value={phone}
        isRequired
        onEdit={onEdit}
      />
      <ProfileItem
        id="email"
        label="Email Address"
        onEdit={onEdit}
        value={email}
        isRequired
        isEditable
      />
      <ProfileItem
        id="password"
        onEdit={onEdit}
        label="Password"
        value={password}
        isRequired
        type="password"
      />

      {localStorage.getItem('auth') && (
        <LogoutWrapper>
          <Button onClick={handleLogout}>Logout</Button>
        </LogoutWrapper>
      )}
      <RequiredText>
        <p className="required">*</p> Required
      </RequiredText>
    </MyProfileWrapper>
  );
};

const ProfileItem: React.FC<ProfileItemProps> = ({
  id,
  label,
  type,
  value,
  isRequired,
  isEditable,
  onEdit
}) => {
  return (
    <ProfileItemWrapper>
      <ItemInfo>
        <p>
          {label}
          <span className="required">{isRequired ? ' * ' : ''}</span>
        </p>
        {type === 'password' ? (
          <input type="password" readOnly value={value} />
        ) : (
          <span>{value}</span>
        )}
      </ItemInfo>
      {!isEditable && (
        <ItemAction onClick={onEdit ? () => onEdit(id) : () => {}}>
          {value ? 'Edit' : 'Add'}
        </ItemAction>
      )}
    </ProfileItemWrapper>
  );
};
