import api from '../config/api';

export const getTransactions = async () => {
  try {
    const res = await api.get('/me/transactions');
    return { success: true, data: res.data };
  } catch (error) {
    return { success: false, message: error };
  }
};
