import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  DatePageContainer,
  DatePageTitleWrapper,
  DatesPageWrapper,
  EmptyCards
} from '../category/styles';
import { useMyOfferContext } from '../../../context';
import { Button, IconArrowDown, MarketCard } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { ViewOfferSection } from '../../../modules/app/dates/ViewOfferSection';
import {
  CardWrapper,
  OfferHistoryCard,
  OfferHistoryCardGrid,
  OfferStatus,
  ViewOfferInfoWrapper
} from './styles';
import {
  PropertiesContent,
  PropertiesHeader,
  PropertiesWrapper,
  PropertyItem
} from '../../../modules/app/dates/styles';
import { DashboardCardGrid } from '../dashboard/styles';
import MyOfferCard from '../../../components/MyOfferCard';
import { toast } from 'react-toastify';
import { getMarketplaceList } from '../../../actions';
import api from '../../../config/api';

export const MyOfferPage: React.FC = () => {
  const navigate = useNavigate();
  const [isView, setIsView] = useState(false);
  const { myOfferContext, setMyOfferContext } = useMyOfferContext();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [tab, setTab] = useState<'new' | 'history'>('new');
  const [collapse, setCollapse] = useState(-1);

  const loadOffers = async () => {
    let offers: any = [];

    try {
      const marketplaceOffer = await api.get('/me/marketplace_offer_by_owner');
      const marketplaceListing = await getMarketplaceList({
        card_collection_id: 1,
        limit: 100,
        offset: 0,
        nft_type_ids: [1, 2, 3, 4, 5, 6, 7],
        rarity: [0, 1, 2],
        status: [0, 1]
      });
      //  fetch user here

      if (marketplaceListing && marketplaceOffer) {
        for (const offer of marketplaceOffer.data) {
          for (const listing of marketplaceListing.data) {
            if (offer.marketplace_listing_id === listing.id) {
              offers.push({
                ...listing,
                buyer_id: offer.buyer_id,
                marketplace_offer_id: offer.id,
                amount: offer.amount,
                status: offer
              });
            }
          }
          // match user.id to buyer_id
        }
        setMyOfferContext(offers);
      }
    } catch (e: any) {
      toast.error(e);
    }
  };

  useEffect(() => {
    loadOffers();
  }, []);

  return (
    <AppLayout>
      {myOfferContext?.length > 0 ? (
        <DatesPageWrapper isview={isView ? 'true' : undefined}>
          <DatePageContainer>
            <DatePageTitleWrapper>
              <h3>My Offers</h3>
            </DatePageTitleWrapper>
            {/* <OfferTabWrapper>
              <span
                className={tab === 'new' ? 'active' : ''}
                onClick={() => setTab('new')}
              >
                New
              </span>
              <span
                className={tab === 'history' ? 'active' : ''}
                onClick={() => setTab('history')}
              >
                History
              </span>
            </OfferTabWrapper> */}
            {tab === 'new' ? (
              <DashboardCardGrid>
                {myOfferContext.map((item: any, key: number) => (
                  <MyOfferCard item={item} loadOffers={loadOffers} />
                ))}
              </DashboardCardGrid>
            ) : (
              <OfferHistoryCardGrid>
                {myOfferContext
                  .filter((f: any) => f.status !== 0)
                  .map((item: any, key: number) => (
                    <OfferHistoryCard key={key}>
                      <CardWrapper>
                        <MarketCard
                          // {...cardData[key]}
                          {...item}
                        />
                      </CardWrapper>
                      <ViewOfferInfoWrapper>
                        <div>
                          <b>By {item?.buyer || ''}</b>
                          <p>{new Date(item.date).toLocaleDateString()}</p>
                        </div>
                        <h1>$1,000 USD</h1>
                      </ViewOfferInfoWrapper>
                      <PropertiesWrapper>
                        <PropertiesHeader onClick={() => setCollapse(item.id)}>
                          <span>Card Properties</span>
                          <IconArrowDown />
                        </PropertiesHeader>
                        {collapse === item.id && (
                          <PropertiesContent>
                            <PropertyItem>
                              <p>Rarity</p>
                              <span>Rare</span>
                            </PropertyItem>
                            <PropertyItem>
                              <p>Type</p>
                              <span>Year</span>
                            </PropertyItem>
                            <PropertyItem>
                              <p>Year</p>
                              <span>2023</span>
                            </PropertyItem>
                            <PropertyItem>
                              <p>Collection</p>
                              <span>Sports Series</span>
                            </PropertyItem>
                          </PropertiesContent>
                        )}
                      </PropertiesWrapper>
                      <OfferStatus
                        className={item.status === 1 ? 'approved' : 'denied'}
                      >
                        {item.status === 1 && 'Approved'}
                        {item.status === 2 && 'Denied'}
                      </OfferStatus>
                    </OfferHistoryCard>
                  ))}
              </OfferHistoryCardGrid>
            )}
          </DatePageContainer>
        </DatesPageWrapper>
      ) : (
        <EmptyCards>
          <h3>No Cards</h3>
          <p>It looks like you don’t have any cards to offer.</p>
          <Button
            className="buy-button"
            onClick={() => navigate('/marketplace')}
          >
            Go to Marketplace
          </Button>
        </EmptyCards>
      )}
      <ViewOfferSection
        isView={isView}
        item={selectedItem}
        onClose={() => {
          setIsView(false);
          setSelectedItem(null);
        }}
      />
    </AppLayout>
  );
};
function useEFfect(arg0: () => void, arg1: never[]) {
  throw new Error('Function not implemented.');
}
