import React, { useState, useEffect } from 'react';
import { ICardPackCards } from '../../../models/card_pack';
import { IconArrowBack } from '../../../components';
import {
  OpenCardPackModalWrapper,
  OpenCard,
  OpenCardContent,
  FrontBackCard,
  BackCardPacks
} from './styles';

import { useMonthContext } from '../../../context';
import { checkRarity, formatCategory } from '../../../utils/helperFunctions';
import { Loader } from '../../../components';

type Props = {
  cardsToAnimation: ICardPackCards;
  onClose: () => void;
  flipActive: any;
  setFlipActive: (value: any) => void;
};

const FrontCardComponent: React.FC<{ rarity: number }> = ({ rarity }) => (
  <FrontBackCard className="front">
    {rarity >= 0 && rarity <= 2 && (
      <img
        src={`/assets/nfts/rarity/${
          rarity === 0 ? 'Core' : rarity === 2 ? 'Rare' : 'Uncommon'
        }-Card-Back.png`}
        alt="nft"
      />
    )}
  </FrontBackCard>
);

const OpenCardPack: React.FC<Props> = ({
  cardsToAnimation,
  onClose,
  flipActive,
  setFlipActive
}) => {
  const { monthContext } = useMonthContext();
  const [videoPlayed, setVideoPlayed] = useState(false);

  const handleVideoEnded = () => {
    setVideoPlayed(true);
  };

  useEffect(() => {
    if (!videoPlayed) {
      const timeout = setTimeout(() => {
        setVideoPlayed(true);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [videoPlayed]);

  return (
    <>
      {!videoPlayed ? (
        <video
          id="your-video-id"
          width="1000"
          autoPlay
          playsInline
          onEnded={handleVideoEnded}
        >
          <source src="/assets/videos/open-card-pack.mp4" type="video/mp4" />
          <source src="/assets/videos/open-card-pack.webm" type="video/webm" />
        </video>
      ) : (
        <>
          <OpenCardPackModalWrapper>
            {cardsToAnimation?.crafting &&
              cardsToAnimation?.crafting?.map((item: any, index) => (
                <OpenCard
                  key={index}
                  onClick={() => {
                    const newFlipActive = { ...flipActive };
                    newFlipActive.crafting[index] =
                      !newFlipActive.crafting[index];
                    setFlipActive(newFlipActive);
                  }}
                >
                  <OpenCardContent
                    className={flipActive.crafting[index] ? 'active' : ''}
                  >
                    {item ? (
                      <>
                        <FrontCardComponent rarity={item?.rarity} />
                        <FrontBackCard className="back">
                          <img
                            src={`/assets/nfts/rarity/Crafting-${checkRarity(
                              item?.rarity
                            )}-copy.png`}
                            alt="nft"
                          />
                        </FrontBackCard>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </OpenCardContent>
                </OpenCard>
              ))}

            {cardsToAnimation?.category &&
              cardsToAnimation?.category?.map((item: any, index) => (
                <OpenCard
                  key={index}
                  onClick={() => {
                    const newFlipActive = { ...flipActive };
                    newFlipActive.category[index] =
                      !newFlipActive.category[index];
                    setFlipActive(newFlipActive);
                  }}
                >
                  <OpenCardContent
                    className={flipActive.category[index] ? 'active' : ''}
                  >
                    {item && item?.category ? (
                      <>
                        <FrontCardComponent rarity={item?.rarity} />
                        <FrontBackCard className="back">
                          <img
                            src={`/assets/nfts/rarity/${formatCategory(
                              item?.category
                            )}-${checkRarity(item?.rarity)}.png`}
                            alt="nft"
                          />
                        </FrontBackCard>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </OpenCardContent>
                </OpenCard>
              ))}

            {cardsToAnimation?.day_month &&
              cardsToAnimation?.day_month?.map((item: any, index) => (
                <OpenCard
                  key={index}
                  onClick={() => {
                    const newFlipActive = { ...flipActive };
                    newFlipActive.day_month[index] = !newFlipActive.day_month[index];
                    setFlipActive(newFlipActive);
                  }}
                >
                  <OpenCardContent
                    className={flipActive.day_month[index] ? 'active' : ''}
                  >
                    {item && item?.day && item?.month ? (
                      <>
                        <FrontCardComponent rarity={item?.rarity} />
                        <FrontBackCard className="back">
                          <img
                            src={`/assets/nfts/rarity/Month-Day-${checkRarity(
                              item?.rarity
                            )}-copy.png`}
                            alt="nft"
                          />
                          <div className="gif">
                            {monthContext && (
                              <h3
                                className={checkRarity(item?.rarity)}
                                style={{ fontSize: '3rem', fontWeight: 600 }}
                              >
                                {String(item.month).padStart(2, '0')}.
                                {String(item.day).padStart(2, '0')}
                              </h3>
                            )}
                          </div>
                        </FrontBackCard>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </OpenCardContent>
                </OpenCard>
              ))}

            {cardsToAnimation?.year &&
              cardsToAnimation?.year?.map((item: any, index) => (
                <OpenCard
                  key={index}
                  onClick={() => {
                    const newFlipActive = { ...flipActive };
                    newFlipActive.year[index] =
                      !newFlipActive.year[index];
                    setFlipActive(newFlipActive);
                  }}
                >
                  <OpenCardContent
                    className={flipActive.year[index] ? 'active' : ''}
                  >
                    {item && item?.year ? (
                      <>
                        <FrontCardComponent rarity={item?.rarity} />
                        <FrontBackCard className="back">
                          <img
                            src={`/assets/nfts/rarity/Year-${checkRarity(
                              item?.rarity
                            )}-copy.png`}
                            alt="nft"
                          />
                          <div className="gif">
                            <h3
                              className={checkRarity(item?.rarity)}
                              style={{ fontSize: '3rem', fontWeight: 600 }}
                            >
                              {item?.year}
                            </h3>
                          </div>
                        </FrontBackCard>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </OpenCardContent>
                </OpenCard>
              ))}
            {cardsToAnimation?.trigger &&
              cardsToAnimation?.trigger?.map((item: any, index) => (
                <OpenCard
                  key={index}
                  onClick={() => {
                    const newFlipActive = { ...flipActive };
                    newFlipActive.trigger[index] =
                      !newFlipActive.trigger[index];
                    setFlipActive(newFlipActive);
                  }}
                >
                  <OpenCardContent
                    className={flipActive.trigger[index] ? 'active' : ''}
                  >
                    {item && item?.trigger ? (
                      <>
                        <FrontCardComponent rarity={item?.rarity} />
                        <FrontBackCard className="back">
                          <img
                            src={`/assets/nfts/rarity/Trigger-${checkRarity(
                              item?.rarity
                            )}-No-Text.png`}
                            alt="nft"
                          />
                          <div className="gif">
                            <h3
                              className={checkRarity(item?.rarity)}
                              style={{
                                fontSize: '0.9rem',
                                marginTop: '44px',
                                maxWidth: '80%'
                              }}
                            >
                              {item?.trigger}
                            </h3>
                          </div>
                        </FrontBackCard>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </OpenCardContent>
                </OpenCard>
              ))}
          </OpenCardPackModalWrapper>

          <BackCardPacks
            onClick={() => {
              onClose();
              setFlipActive({
                crafting: Array(cardsToAnimation.crafting?.length).fill(false),
                category: Array(cardsToAnimation.category?.length).fill(false),
                year: Array(cardsToAnimation.year?.length).fill(false),
                day_month: Array(cardsToAnimation.day_month?.length).fill(
                  false
                ),
                trigger: Array(cardsToAnimation.day_month?.length).fill(false)
              });
            }}
          >
            <IconArrowBack />
            Back to Card packs
          </BackCardPacks>
        </>
      )}
    </>
  );
};

export default OpenCardPack;
