import { styled } from 'styled-components';

//  background: ${({ bg }) =>
//     bg
//       ? `url(${bg}) no-repeat, #CDCDCD`
//       : "linear-gradient(0deg, #CDCDCD, #CDCDCD), url(.png), #FFFFFF"};

export const CardWrapper = styled.div`
  padding: 23px 25px;
  cursor: pointer;
  position: relative;
  width: 100%;
  /* background-blend-mode: luminosity, normal; */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  &:hover {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const CardImgWrapper = styled.div<{
  dashbordstyle?: boolean;
  rarity?: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
  .prediction-gif {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    max-width: 300px;
  }
  .prediction-text-block {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    h4 {
      font-size: 0.7rem;
    }
    &.Rare {
    }
    &.Core {
      bottom: 26%;
      h4 {
        font-size: 0.5rem;
      }
    }
    &.Uncommon {
    }
  }
  .info-nft {
    position: relative;
    text-align: center;
    position: absolute;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    h2,
    h3,
    h4 {
      text-shadow: 1px 1px 2px black;
      &.Rare {
        color: #2911a4;
      }
      &.Core {
        color: white;
      }
      &.Uncommon {
        color: #e6b964;
      }
    }
    &.info-nft-day-month {
      top: 50%;
      &.marketplace {
        font-size: 3rem;
        @media screen and (max-width: 1000px) {
          font-size: 1.4rem;
        }
      }
      &.crafting {
        h2 {
          font-size: clamp(1.3rem, 2vw, 1.6rem);
        }
        h3 {
          font-size: clamp(0.7em, 2vw, 0.8rem);
        }
      }
    }
    &.info-nft-prediction {
      top: 43%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      img {
        width: 4vw;
        margin: 0 auto;
      }
      h4 {
        font-size: ${({ dashbordstyle }) =>
          dashbordstyle
            ? 'clamp(0.6rem, 2vw, 0.8rem)'
            : 'clamp(0.8rem, 2vw, 1rem)'};
      }
      @media screen and (max-width: 1024px) {
        img {
          width: 15vw;
        }
      }
    }
    &.info-nft-identity {
      top: 50%;
      font-family: vipnagorgialla;

      img {
        width: 80%;
      }
      .nft-info-detail {
        position: absolute;
        bottom: 0;
        width: 100%;
        h2 {
          font-size: clamp(1.2rem, 1.5rem, 2.8rem);
          font-family: vipnagorgialla;
          @media screen and (max-width: 580px) {
            font-size: 1rem;
          }
          &.forCraft {
            font-size: 1rem;
            @media screen and (max-width: 480px) {
              font-size: 1rem;
            }
          }
          &.isMarketplace {
            @media screen and (max-width: 930px) {
              font-size: 1rem;
            }
          }
        }
        h3,
        h4 {
          font-size: clamp(0.8rem, 2vw, 1rem);
          font-family: vipnagorgialla;
          font-weight: 300;
          @media screen and (max-width: 480px) {
            font-size: 1rem;
          }
          &.forCraft {
            font-size: 0.8rem;
            @media screen and (max-width: 480px) {
              font-size: 1rem;
            }
          }
        }
        font-size: clamp(0.8rem, 2vw, 1rem);
        font-family: vipnagorgialla;
        @media screen and (max-width: 480px) {
          font-size: 1rem;
        }
        &.forCraft {
          font-size: clamp(0.6rem, 2vw, 1rem);
          @media screen and (max-width: 480px) {
            font-size: 1rem;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      img {
        width: 80%;
      }
    }
    &.info-nft-cardPack {
      top: 50%;
      img {
        position: relative;
        left: 16%;
      }
    }
  }

  .info-nft-trigger {

    height: 10px;
    top: 55%;
    width: 50%;
    justify-content: center;
    h4 {
      justify-content: center;
      font-weight: 600;
      font-size: clamp(0.6rem, 0.9rem, 1.2rem);
      &.rarity-0 {
        color: #fff;
      }
      &.rarity-1 {
        color: #e6b964;
      }
      &.rarity-2 {
        color: #2911a4;
      }
    }
  }
`;

export const CardTopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CardBottomSection = styled.div`
  height: 37px;
  width: 100%;
  border-radius: 4px;
  background: #000;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.2);
  margin-top: 12px;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.08) 18.23%,
    rgba(0, 0, 0, 0.35) 51.56%,
    rgba(0, 0, 0, 0.8) 100%
  ); */
  color: #fff;
  padding: 14px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 4px;
  }
  p {
    color: #fff;
    font-family: vipnagorgialla;
    font-size: 12px;
    font-weight: 100;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
  }
`;

export const CardOverlay = styled.div`
  opacity: 0;
  visibility: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(11, 11, 11, 0.6); */
  background: url('/assets/nfts/blur.png') no-repeat;
  background-size: 100% 100%;
  backdrop-filter: blur(2px);
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > :not(:first-child) {
    margin-top: 12px;
  }
`;

export const Rarity = styled.div`
  height: 32px;
  background: url('/assets/nfts/rarity.png') no-repeat;
  background-size: 100% 100%;
  min-width: 81px;
  display: flex;
  font-family: 'IBM Plex Mono', monospace;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 11px;
  right: 11px;
  color: #fff;
  padding: 0 13px;
  /* background: #615e5e; */

  @media (min-width: 425px) {
    max-width: 60px !important;
  }
`;

export const StatusWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 0px;
  & > * {
    margin-top: 8px;
    padding: 0 7px;
    height: 23px;
    border-radius: 0 4px 4px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    width: fit-content;
  }
  p {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
  }
  span {
    color: #000;
    border-radius: 0px 4px 4px 0px;
    background: rgba(255, 255, 255, 0.7);
  }
`;
