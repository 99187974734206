import React, { useState } from 'react';
import { TrashIcon, PencilAlt, IconArrowUp } from '../../../../../components';
import {
  Card,
  CardHeader,
  CardBody,
  CardTable,
  CardTableHead,
  CardTableBody,
  CardTableTr,
  CardTableTd,
  CardTableTh,
  SaveButton,
  Tabs
} from '../styles';
import { ICardCollection } from '../../../../../models/collection';

type RowItem = {
  rowId: number;
  cardType: string;
  rarity: string;
  probability: number;
  triggerType?: string;
  triggerName?: string;
  rewType?: string;
  rewPackRarity?: string;
  regDefinition?: string;
};

const CollapsibleRow = ({
  rowData,
  isEditing,
  startEditing,
  updateRowData
}: {
  rowData: RowItem;
  isEditing: boolean;
  startEditing: (rowId: number | null) => void;
  updateRowData: (rowId: number, updatedData: RowItem) => void;
}) => {
  const {
    rowId,
    cardType,
    rarity,
    probability,
    triggerType,
    triggerName,
    rewType,
    rewPackRarity,
    regDefinition
  } = rowData;

  const [formData, setFormData] = useState<RowItem>({ ...rowData });

const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => {
  const { name, value } = event.target;
  const updatedValue = name === 'probability' ? Number(value) : value;

  setFormData((prevData) => ({
    ...prevData,
    [name]: updatedValue
  }));
};
  // save handle
  const handleSave = () => {
    updateRowData(rowId, formData);
    startEditing(null);
  };

  return (
    <CardTableTr className="border-bottom">
      <CardTableTd>{cardType}</CardTableTd>
      <CardTableTd>{rarity}</CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-number"
            type="number"
            value={formData.probability}
            onChange={handleInputChange}
            name="probability"
          />
        ) : (
          `${probability}%`
        )}
      </CardTableTd>
      <CardTableTd>{triggerType}</CardTableTd>
      <CardTableTd>{triggerName}</CardTableTd>

      <CardTableTd>
        {isEditing ? (
          <input
            className="input-text"
            type="text"
            value={formData.rewType}
            onChange={handleInputChange}
            name="rewType"
          />
        ) : (
          rewType
        )}
      </CardTableTd>
      <CardTableTd>
        {isEditing ? (
          <input
            className="input-text"
            type="text"
            value={formData.rewPackRarity}
            onChange={handleInputChange}
            name="rewPackRarity"
          />
        ) : (
          rewPackRarity
        )}
      </CardTableTd>

      <CardTableTd>
        {isEditing ? (
          <input
            className="input-text"
            type="text"
            value={formData.regDefinition}
            onChange={handleInputChange}
            name="regDefinition"
          />
        ) : (
          regDefinition
        )}
      </CardTableTd>

      <CardTableTd className="col-action">
        {isEditing ? (
          <>
            <SaveButton className="ml-auto" onClick={handleSave}>
              Save
            </SaveButton>
            <button className="svg-btn">
              <TrashIcon />
            </button>
          </>
        ) : (
          <>
            <button
              className="svg-btn ml-auto"
              onClick={() => startEditing(rowId)}
            >
              <PencilAlt />
            </button>
            <button className="svg-btn">
              <TrashIcon />
            </button>
          </>
        )}
      </CardTableTd>
    </CardTableTr>
  );
};

const Standard = ({ collection }: { collection: ICardCollection }) => {
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState('Day & Month');

  // Define state variables for each tab's data
  const [dayAndMonthData, setDayAndMonthData] = useState<RowItem[]>([
    {
      rowId: 1,
      cardType: 'Day & Month',
      rarity: 'Core',
      probability: 10
    },
    {
      rowId: 2,
      cardType: 'Day & Month',
      rarity: 'Uncommon',
      probability: 10
    },
    {
      rowId: 3,
      cardType: 'Day & Month',
      rarity: 'Rare',
      probability: 10
    }
  ]);

  const [yearData, setYearData] = useState<RowItem[]>([
    {
      rowId: 1,
      cardType: 'Year',
      rarity: 'Core',
      probability: 10
    },
    {
      rowId: 2,
      cardType: 'Year',
      rarity: 'Uncommon',
      probability: 10
    },
    {
      rowId: 3,
      cardType: 'Year',
      rarity: 'Rare',
      probability: 10
    }
  ]);

  const [categoryData, setCategoryData] = useState<RowItem[]>([
    {
      rowId: 1,
      cardType: 'Category',
      rarity: 'Core',
      probability: 10
    },
    {
      rowId: 2,
      cardType: 'Category',
      rarity: 'Uncommon',
      probability: 10
    },
    {
      rowId: 3,
      cardType: 'Category',
      rarity: 'Rare',
      probability: 10
    }
  ]);

  const [triggerData, setTriggerData] = useState<RowItem[]>([
    {
      rowId: 1,
      cardType: 'Trigger',
      rarity: 'Core',
      probability: 3,
      triggerType: 'Major 1',
      triggerName: '--',
      rewType: 'Cash',
      rewPackRarity: '',
      regDefinition: '0.9%'
    },
    {
      rowId: 2,
      cardType: 'Trigger',
      rarity: 'Core',
      probability: 8,
      triggerType: 'Major 2',
      triggerName: '--',
      rewType: 'Card Pack',
      rewPackRarity: 'Standard',
      regDefinition: '0'
    }
  ]);

  const updateRowData = (rowId: number, updatedData: RowItem) => {
    // Determine which tab is active and update the corresponding state variable
    switch (activeTab) {
      case 'Day & Month':
        setDayAndMonthData((prevData) =>
          prevData.map((row) => (row.rowId === rowId ? updatedData : row))
        );
        break;
      case 'Year':
        setYearData((prevData) =>
          prevData.map((row) => (row.rowId === rowId ? updatedData : row))
        );
        break;
      case 'Category':
        setCategoryData((prevData) =>
          prevData.map((row) => (row.rowId === rowId ? updatedData : row))
        );
        break;
      case 'Trigger':
        setTriggerData((prevData) =>
          prevData.map((row) => (row.rowId === rowId ? updatedData : row))
        );
        break;
      default:
        break;
    }
  };

  const calculateTotalProbability = () => {
    return (
      calculateTabProbability(dayAndMonthData) +
      calculateTabProbability(yearData) +
      calculateTabProbability(categoryData) +
      calculateTabProbability(triggerData)
    );
  };

  // Function to calculate probability for a single tab
const calculateTabProbability = (data: RowItem[]) => {
  return data.reduce((total, current) => total + current.probability, 0);
};



  return (
    <Card>
      <CardHeader>
        Probabilities <IconArrowUp />
      </CardHeader>
      <CardBody>
        <Tabs>
          <div className="tabs-button">
            <button
              className={`tab ${activeTab === 'Day & Month' ? 'active' : ''}`}
              onClick={() => setActiveTab('Day & Month')}
            >
              Day & Month
              <span>{calculateTabProbability(dayAndMonthData)}%</span>
            </button>
            <button
              className={`tab ${activeTab === 'Year' ? 'active' : ''}`}
              onClick={() => setActiveTab('Year')}
            >
              Year
              <span>{calculateTabProbability(yearData)}%</span>
            </button>
            <button
              className={`tab ${activeTab === 'Category' ? 'active' : ''}`}
              onClick={() => setActiveTab('Category')}
            >
              Category
              <span>{calculateTabProbability(categoryData)}%</span>
            </button>
            <button
              className={`tab ${activeTab === 'Trigger' ? 'active' : ''}`}
              onClick={() => setActiveTab('Trigger')}
            >
              Trigger
              <span>{calculateTabProbability(triggerData)}%</span>
            </button>
          </div>
          <div className="total-summary">
            <p>
              TOTAL PERCENTAGE SUMMARY:{' '}
              <span>{calculateTotalProbability()}%</span>
            </p>
          </div>
        </Tabs>

        <CardTable>
          <CardTableHead>
            <CardTableTr>
              <CardTableTh className="TPPool-th">Card Type</CardTableTh>
              <CardTableTh className="TPPool-th">Rarity</CardTableTh>
              <CardTableTh className="TPPool-th">Probability</CardTableTh>
              {activeTab === 'Trigger' && (
                <>
                  <CardTableTh className="TPPool-th">TRIGGER TYPE</CardTableTh>
                  <CardTableTh className="TPPool-th">TRIGGER NAME</CardTableTh>
                  <CardTableTh className="TPPool-th">rew. Type</CardTableTh>
                  <CardTableTh className="TPPool-th">
                    rew. Pack rarity
                  </CardTableTh>
                  <CardTableTh className="TPPool-th">
                    reg. definition
                  </CardTableTh>
                </>
              )}
              <CardTableTh></CardTableTh>
            </CardTableTr>
          </CardTableHead>
          <CardTableBody>
            {activeTab === 'Day & Month' &&
              dayAndMonthData.map((rowData) => (
                <CollapsibleRow
                  key={rowData.rowId}
                  rowData={rowData}
                  isEditing={rowData.rowId === editingRow}
                  startEditing={setEditingRow}
                  updateRowData={updateRowData}
                />
              ))}
            {activeTab === 'Year' &&
              yearData.map((rowData) => (
                <CollapsibleRow
                  key={rowData.rowId}
                  rowData={rowData}
                  isEditing={rowData.rowId === editingRow}
                  startEditing={setEditingRow}
                  updateRowData={updateRowData}
                />
              ))}
            {activeTab === 'Category' &&
              categoryData.map((rowData) => (
                <CollapsibleRow
                  key={rowData.rowId}
                  rowData={rowData}
                  isEditing={rowData.rowId === editingRow}
                  startEditing={setEditingRow}
                  updateRowData={updateRowData}
                />
              ))}
            {activeTab === 'Trigger' &&
              triggerData.map((rowData) => (
                <CollapsibleRow
                  key={rowData.rowId}
                  rowData={rowData}
                  isEditing={rowData.rowId === editingRow}
                  startEditing={setEditingRow}
                  updateRowData={updateRowData}
                />
              ))}
          </CardTableBody>
        </CardTable>
      </CardBody>
    </Card>
  );
};

export default Standard;
