import React, { useEffect, useRef, useState } from 'react';
import {
  CheckboxWrapper,
  ClearAll,
  OptionGroup,
  OptionItem,
  SelectAction,
  SelectBoxContainer,
  SelectBoxTextWrapper,
  SelectBoxWrapper,
  SelectOptionsWrapper
} from './styles';
import { IconArrowDown } from '../Icons';
import { SelectBoxProps } from '../../typeroot/types';
import { Button } from '../Button';

export const SelectBox: React.FC<SelectBoxProps> = ({
  placeholder,
  label,
  value,
  options,

  border,
  isFilter,
  onClick,
  onChange,
  filters,
  type
}) => {
  const optionRef = useRef<any>(null);
  const [isOption, setIsOption] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    if (type && filters && filters[type]) {
      let asStrings = filters[type].map((v: any) => v.toString());
      setSelectedOptions(asStrings);
    }
  }, [type, filters, setSelectedOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionRef.current && !optionRef.current.contains(event.target)) {
        setIsOption(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = async (value: string) => {
    onClick && onClick('Collections', [value]);
    // onChange && onChange(value);
    setIsOption(false);
  };

  const handleFilterClick = async (filterType: string) => {
    if (!selectedOptions.length) {
      onClick && onClick(filterType, []);
      return;
    }
    onClick && onClick(filterType, selectedOptions);

    setIsOption(false);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const isChecked = e.target.checked;
    setSelectedOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, value];
      } else {
        return prevOptions.filter((option) => option !== value);
      }
    });
  };

  return (
    <SelectBoxWrapper ref={optionRef}>
      {label && <p>{label}</p>}
      <SelectBoxContainer
        border={border ? 'true' : undefined}
        onClick={() => setIsOption(!isOption)}
      >
        <SelectBoxTextWrapper>
          {value ? (
            <>
              {options && options.filter((f) => f.value === value)[0].image && (
                <img
                  src={options.filter((f) => f.value === value)[0].image}
                  alt=""
                />
              )}{' '}
              {options && options.filter((f) => f.value === value)[0].label}
            </>
          ) : (
            <span>{placeholder}</span>
          )}
        </SelectBoxTextWrapper>
        <IconArrowDown />
      </SelectBoxContainer>
      <SelectOptionsWrapper open={isOption}>
        {isFilter ? (
          <>
            <OptionGroup>
              {options &&
                options.map((v) => {
                  return (
                    <OptionItem key={`${v.value}-${v.label}`}>
                      <span>{v.label}</span>

                      <CheckboxWrapper>
                        <input
                          key={`checkbox-${v.value}-${v.label}`}
                          id={`checkbox-${v.value}-${v.label}`}
                          type="checkbox"
                          value={v.label as string}
                          checked={selectedOptions.includes(v.value)}
                          onChange={(e) =>
                            handleCheckboxChange(e, v.value as string)
                          }
                        />
                        <label
                          htmlFor={`checkbox-${v.value}-${v.label}`}
                        ></label>
                      </CheckboxWrapper>
                    </OptionItem>
                  );
                })}
            </OptionGroup>
            <SelectAction>
              <ClearAll
                onClick={() => {
                  setSelectedOptions([]);
                }}
              >
                Clear All
              </ClearAll>
              <Button
                className="filter-apply-button"
                onClick={() => {
                  handleFilterClick(placeholder as string);
                  setIsOption(false);
                }}
              >
                Apply Selection
              </Button>
            </SelectAction>
          </>
        ) : (
          <OptionGroup>
            {options &&
              options.map((item, key) => (
                <OptionItem
                  key={key}
                  onClick={() => handleOptionClick(item.value)}
                >
                  <div>
                    {item.image ? <img src={item.image} alt="" /> : ''}
                    {item.label}
                  </div>
                </OptionItem>
              ))}
          </OptionGroup>
        )}
      </SelectOptionsWrapper>
    </SelectBoxWrapper>
  );
};
