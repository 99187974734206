import React, { useEffect, useState } from 'react';
import { CardPreviewSectionWrapper, CraftCard } from './styles';
import {
  PropertiesContent,
  PropertiesHeader,
  PropertiesWrapper,
  PropertyItem
} from '../app/dates/styles';
import { INftCardCrafting } from '../../models/nft_card_crafting';
import { INftCardIdentity } from '../../models/nft_card_identity';
import { INftCardTrigger } from '../../models/nft_card_trigger';
import { useCardSeriesContext, useMonthContext } from '../../context';
import { CategoryCard } from '../../components/CategoryCard';
import { CraftingCard, DateCard, IdentityCard } from '../../components';
import { TriggerCardPreview } from '../../components/TriggerCardPreview';

export const CardPreviewSection: React.FC<{
  selectedCraft: string;
  page: 'identity' | 'prediction';
  clickedCard: string | number | null;
  clickedNft?: INftCardCrafting | INftCardIdentity | INftCardTrigger;
  myNfts?: any;
}> = ({ page, clickedCard, clickedNft, selectedCraft, myNfts }) => {
  type CardProps = {
    id: number;
    rarity: number;
    image: string;
    name: string | number;
  }[];

  // console.log('selectedCraft', selectedCraft);
  // console.log('clickedNft', clickedNft);
  // console.log('clickedCard', clickedCard);
  const { monthContext } = useMonthContext();
  const [nftData, setNftData] = useState<CardProps>([]);
  const [currentCard, setCurrentCard] = useState<any>(null);

  // useEffect(() => {
  //   let tempData: CardProps = [
  //     ...nft_card_crafting_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: 'Crafting'
  //         };
  //       }),
  //     ...nft_card_day_month_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: item.day + '/' + item.month
  //         };
  //       }),
  //     ...nft_card_year_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: item.year
  //         };
  //       }),
  //     ...nft_card_category_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: item.category
  //         };
  //       }),
  //     ...nft_card_identity_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: item.category
  //         };
  //       }),
  //     ...nft_card_trigger_data
  //       .filter((f) => !f.is_crafted)
  //       .map((item) => {
  //         return {
  //           id: item.id,
  //           rarity: item.rarity,
  //           image: item.image,
  //           name: item.trigger
  //         };
  //       })
  //   ];
  //   // }
  //   setNftData(tempData);
  // }, []);
  // useEffect(() => {
  //   if (myNfts.crafting && myNfts.category && myNfts.dayMonth && myNfts.year)
  //     setNftData([
  //       ...myNfts.crafting,
  //       ...myNfts.category,
  //       ...myNfts.dayMonth,
  //       ...myNfts.year
  //     ]);
  // }, [myNfts]);

  useEffect(() => {
    if (!myNfts || !selectedCraft) {
      return;
    }
    const current = myNfts[selectedCraft]?.find(
      (f: any) => f.id === clickedCard
    );
    setCurrentCard(current);
  }, [myNfts, selectedCraft, clickedCard]);

  return (
    <CardPreviewSectionWrapper>
      <h2>Preview</h2>
      {Number(clickedCard) > -1 ? (
        <>
          <CraftCard className="preview">
            {selectedCraft === 'category' && (
              <CategoryCard item={currentCard} {...currentCard} />
            )}
            {(selectedCraft === 'dayMonth' || selectedCraft === 'year') && (
              <DateCard
                position="right"
                item={currentCard}
                {...currentCard}
                textSize="1.5rem"
              />
            )}
            {selectedCraft === 'crafting' && (
              <CraftingCard
                position="right"
                item={currentCard}
                {...currentCard}
              />
            )}
            {selectedCraft === 'identity' && (
              <IdentityCard
                position="right"
                item={currentCard}
                {...currentCard}
                styles={{
                  fontSize: '1rem',
                  position: 'relative',
                  bottom: '40px'
                }}
              />
            )}
            {selectedCraft === 'trigger' && (
              <TriggerCardPreview
                position="right"
                item={currentCard}
                {...currentCard}
              />
            )}
          </CraftCard>
          <PropertiesWrapper>
            <PropertiesHeader noborder="true">
              <span>Properties</span>
            </PropertiesHeader>

            <PropertiesContent>
              {(currentCard?.day || currentCard?.month) && (
                <PropertyItem>
                  <p>Month/Day</p>
                  <span>
                    {monthContext.get(currentCard.month)} {currentCard.day}
                  </span>
                </PropertyItem>
              )}
              {currentCard?.celebrity_name && (
                <PropertyItem>
                  <p>Name</p>
                  <span>{currentCard.celebrity_name}</span>
                </PropertyItem>
              )}
              {currentCard?.year && (
                <PropertyItem>
                  <p>Year</p>
                  <span>{currentCard.year}</span>
                </PropertyItem>
              )}
              {currentCard?.category && (
                <PropertyItem>
                  <p>Category</p>
                  <span>{currentCard.category}</span>
                </PropertyItem>
              )}
              {currentCard?.trigger && (
                <PropertyItem>
                  <p>Trigger</p>
                  <span>{currentCard.trigger}</span>
                </PropertyItem>
              )}
              {currentCard?.tier && (
                <PropertyItem>
                  <p>Tier</p>
                  <span>{currentCard.tier}</span>
                </PropertyItem>
              )}
              {currentCard?.rarity >= 0 ? (
                <PropertyItem>
                  <p>Rarity</p>
                  {currentCard?.rarity === 0 && <span>Common</span>}
                  {currentCard?.rarity === 1 && <span>Uncommon</span>}
                  {currentCard?.rarity === 2 && <span>Rare</span>}
                </PropertyItem>
              ) : null}
            </PropertiesContent>
          </PropertiesWrapper>
        </>
      ) : (
        <p>Click on a card from your inventory to see its properties.</p>
      )}
    </CardPreviewSectionWrapper>
  );
};
