import React, { useEffect, useState, useMemo } from 'react';
import {
  CardGridWrapper,
  FilterWrapper,
  SelectBoxWrapper,
  SelectCardSectionContainer,
  SelectCardSectionWrapper
} from './styles';
import {
  Button,
  IconSort,
  SelectBox,
  Loader,
  getImagePath,
  SortIcon
} from '../../components';
import { SortButton } from '../app/dates/styles';
import { useStatusContext, useAllRaritiesContext } from '../../context';
import { EmptyCards } from '../../pages/app/category/styles';
import { useNavigate } from 'react-router-dom';

import { getMyNftCardCrafting } from '../../actions/nft_card_crafting';
import { getMyNftCardDayMonth } from '../../actions/nft_card_day_month';
import { getMyNftCardCategory } from '../../actions/nft_card_category';
import { getMyNftCardYear } from '../../actions/nft_card_year';
import { INftCardCrafting } from '../../models/nft_card_crafting';
import { INftCardDayMonth } from '../../models/nft_card_day_month';
import { INftCardYear } from '../../models/nft_card_year';
import { INftCardCategory } from '../../models/nft_card_category';
import { useMonthContext, useCardCollectionContext } from '../../context';
import { SelectOptionProps } from '../../typeroot/types';
import {
  NftCardCategoryFilters,
  NftCardCraftingFilters,
  NftCardDayMonthFilters,
  NftCardYearFilters
} from '../../models/filters';
import { DateCardWrapper } from '../../components/DateCard/styles';
import { CardImgWrapper } from '../../components/MarketCard/styles';
import { CardBottomWrapper } from '../../components/PredictionCard/styles';
import {
  CardButton,
  CardButtonGroup,
  CardOverlayWrapper
} from '../../components/CraftingCard/styles';
import { checkRarity } from '../../utils/helperFunctions';
import { CardTooltip, TooltipContent } from '../marketplace/styles';

function formatCategory(category: string) {
  const words = category.split(' ');

  const formattedCategory = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');

  return formattedCategory;
}

export const IdentitySelectCardSection: React.FC<{
  selectedCraft: string;
  clickedCard: number | string | null;
  selectedCard: number | string | null;
  onCardClicked: (
    key: number | string,
    item: INftCardCrafting | INftCardCategory | INftCardDayMonth | INftCardYear
  ) => void;
  onSelectCardCrafting: (card: INftCardCrafting) => void;
  onSelectCardCategory: (card: INftCardCategory) => void;
  onSelectCardDayMonth: (card: INftCardDayMonth) => void;
  onSelectCardYear: (card: INftCardYear) => void;
  myNfts: {
    dayMonth: INftCardDayMonth[] | null;
    category: INftCardCategory[] | null;
    year: INftCardYear[] | null;
    crafting: INftCardCrafting[] | null;
  };
  setMyNfts: React.Dispatch<
    React.SetStateAction<{
      crafting: INftCardCrafting[] | null;
      category: INftCardCategory[] | null;
      dayMonth: INftCardDayMonth[] | null;
      year: INftCardYear[] | null;
    }>
  >;
}> = ({
  selectedCraft,
  clickedCard,
  selectedCard,
  onCardClicked,
  myNfts,
  setMyNfts,
  onSelectCardCategory,
  onSelectCardCrafting,
  onSelectCardDayMonth,
  onSelectCardYear
}) => {
  const { statusContext } = useStatusContext();
  const { allRaritiesContext } = useAllRaritiesContext();
  const { cardCollectionContext } = useCardCollectionContext();
  const { monthContext } = useMonthContext();
  const [isLoadingCrating, setIsLoadingCrating] = useState(true);
  const [isLoadingDayMonth, setIsLoadingDayMonth] = useState(true);
  const [isLoadingYear, setIsLoadingYear] = useState(true);
  const [isLoadingCategory, setIsLoadingCategory] = useState(true);
  const [rarities, setRarity] = useState<number[]>([]);
  const [status, setStatus] = useState<number[]>([]);
  const [collection, setCollection] = useState<number[]>([1]);
  const [filteredListings, setFilteredListings] = useState<any>([]);
  const [sortToolTip, setSortToolTip] = useState(false);

  const navigate = useNavigate();

  const setNftCardCraftingData = (data: INftCardCrafting[]) => {
    setMyNfts({
      dayMonth: myNfts.dayMonth,
      year: myNfts.year,
      category: myNfts.category,
      crafting: data
    });
  };

  const setNftCardCategoryData = (data: INftCardCategory[]) => {
    setMyNfts({
      dayMonth: myNfts.dayMonth,
      year: myNfts.year,
      category: data,
      crafting: myNfts.crafting
    });
  };

  const setNftCardDayMonthData = (data: INftCardDayMonth[]) => {
    setMyNfts({
      dayMonth: data,
      year: myNfts.year,
      category: myNfts.category,
      crafting: myNfts.crafting
    });
  };

  const setNftCardYearData = (data: INftCardYear[]) => {
    setMyNfts({
      dayMonth: myNfts.dayMonth,
      category: myNfts.category,
      year: data,
      crafting: myNfts.crafting
    });
  };

  const filter = useMemo(() => {
    return {
      rarities,
      card_collection_id: collection
    };
  }, [rarities, collection]);

  const getNFTCrafting = async (filter: any) => {
    setIsLoadingCrating(true);
    setIsLoadingDayMonth(true);
    setIsLoadingYear(true);
    setIsLoadingCategory(true);

    if (selectedCraft === 'crafting') {
      const response = await getMyNftCardCrafting(
        filter as NftCardCraftingFilters
      );
      if (response.data) {
        setNftCardCraftingData(response.data);
      }
      setIsLoadingCrating(false);
    } else if (selectedCraft === 'dayMonth') {
      const response = await getMyNftCardDayMonth(
        filter as NftCardDayMonthFilters
      );
      if (response.data) {
        setNftCardDayMonthData(response.data);
      }
      setIsLoadingDayMonth(false);
    } else if (selectedCraft === 'year') {
      const response = await getMyNftCardYear(filter as NftCardYearFilters);
      if (response.data) {
        setNftCardYearData(response.data);
      }
      setIsLoadingYear(false);
    } else if (selectedCraft === 'category') {
      const response = await getMyNftCardCategory(
        filter as NftCardCategoryFilters
      );
      if (response.data) {
        setNftCardCategoryData(response.data);
      }
      setIsLoadingCategory(false);
    }
  };

  useEffect(() => {
    setRarity([]);
    setCollection([1]);
  }, [selectedCraft]);

  useEffect(() => {
    getNFTCrafting(filter);
  }, [filter]);

  const [optionsStatus, setOptionsStatus] = useState<SelectOptionProps[]>([]);
  const [optionsRarities, setOptionsRarities] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsCollection, setOptionsCollection] = useState<
    SelectOptionProps[]
  >([]);
  const [optionsTriggers, setOptionsTriggers] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsTiers, setOptionsTiers] = useState<SelectOptionProps[]>([]);
  const [optionsCategories, setOptionsCategories] = useState<
    SelectOptionProps[]
  >([]);

  useEffect(() => {
    if (statusContext && cardCollectionContext) {
      // setOptionsStatus(Array.from((statusContext as Map<number, { id: number, name: string }>).values()).map(v => {
      //   return { checked: false, value: v.id.toString(), label: v.name }
      // }))
      const collection: any = [];
      if (cardCollectionContext) {
        for (const item of cardCollectionContext) {
          collection.push({
            checked: false,
            value: item?.id?.toString(),
            label: item.name
          });
        }
      }
      setOptionsCollection(collection);

      setOptionsRarities(
        Array.from(
          (
            allRaritiesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );
    }
  }, [cardCollectionContext, allRaritiesContext]);

  const handleClick = (filterType: string, selectedOptions: string[]) => {
    if (filterType === 'All Rarities')
      setRarity(selectedOptions.map((v) => Number(v)));
    if (filterType === 'Status')
      setStatus(selectedOptions.map((v) => Number(v)));
    if (filterType === 'Collections')
      setCollection(selectedOptions.map((v) => Number(v)));
  };

  const sortListingsByRarity = (listings: any, type: string, order: string) => {
    const sortedListings = [...listings].sort((a, b) => {
      if (order === 'asc') {
        return b.rarity - a.rarity;
      } else {
        return a.rarity - b.rarity;
      }
    });

    let update = { ...filteredListings };
    update[type as any] = sortedListings;

    return setFilteredListings(update);
  };

  const sortListingsByDate = (listings: any, type: string, order: string) => {
    console.log('listings', listings);
    let sortedListings = [...listings];
    if (order === 'desc') {
      if (type === 'year') {
        sortedListings = [...listings].sort((a, b) => b.year - a.year);
      }
      if (type === 'dayMonth') {
        sortedListings = [...listings].sort((a, b) => {
          if (a.month === b.month) return b.day - a.day;
          return b.month - a.month;
        });
      }
    } else {
      if (type === 'year') {
        sortedListings = [...listings].sort((a, b) => a.year - b.year);
      }
      if (type === 'dayMonth') {
        sortedListings = [...listings].sort((a, b) => {
          if (a.month === b.month) return a.day - b.day;
          return a.month - b.month;
        });
      }
    }

    let update = { ...filteredListings };
    update[type as any] = sortedListings;

    return setFilteredListings(update);
  };

  return (
    <SelectCardSectionWrapper>
      {selectedCraft === 'crafting' && (
        <SelectCardSectionContainer>
          {myNfts.crafting != null ? (
            <>
              <h2>Select a Crafting card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.crafting,
                              'crafting',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.crafting,
                              'crafting',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {(filteredListings.crafting
                  ? filteredListings.crafting
                  : myNfts.crafting
                )
                  .filter((v: any) => !v.is_crafted)
                  .map((item: any) => (
                    <DateCardWrapper key={`crafting-${item.id}`}>
                      <CardImgWrapper>
                        {item.rarity || item.rarity === 0 ? (
                          <img
                            src={`/assets/nfts/rarity/Crafting-${checkRarity(
                              item?.rarity
                            )}-copy.png`}
                            alt="nft"
                          />
                        ) : (
                          <Loader />
                        )}
                      </CardImgWrapper>
                      <CardBottomWrapper>Crafting</CardBottomWrapper>

                      <CardOverlayWrapper
                        className="overlay"
                        onClick={() => onCardClicked(Number(item.id), item)}
                      >
                        <CardButtonGroup>
                          <CardButton
                            onClick={() => onSelectCardCrafting(item)}
                          >
                            Select
                          </CardButton>
                        </CardButtonGroup>
                      </CardOverlayWrapper>

                      <CardOverlayWrapper />
                    </DateCardWrapper>
                  ))}
              </CardGridWrapper>
            </>
          ) : !isLoadingCrating ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>
              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}

      {selectedCraft === 'dayMonth' && (
        <SelectCardSectionContainer>
          {myNfts.dayMonth != null ? (
            <>
              <h2>Select a Day-Month card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.dayMonth,
                              'dayMonth',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.dayMonth,
                              'dayMonth',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(
                              myNfts.dayMonth,
                              'dayMonth',
                              'desc'
                            )
                          }
                        >
                          <span>Date High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(
                              myNfts.dayMonth,
                              'dayMonth',
                              'asc'
                            )
                          }
                        >
                          <span>Date Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {(filteredListings.dayMonth
                  ? filteredListings.dayMonth
                  : myNfts.dayMonth
                )
                  .filter((v: any) => !v.is_crafted)
                  .map((item: any) => {
                    const imagePath = getImagePath(
                      false,
                      item?.rarity ?? 0,
                      'Month-Day',
                      false
                    );

                    return (
                      <DateCardWrapper key={`dayMonth-${item.id}`}>
                        <CardImgWrapper>
                          <img src={imagePath} alt="nft" />
                          <div className="info-nft info-nft-day-month crafting">
                            {(item?.rarity || item?.rarity === 0) &&
                            item.day &&
                            monthContext ? (
                              <h3
                                className={checkRarity(item?.rarity)}
                                style={{
                                  fontSize: '1.5rem'
                                }}
                              >
                                {String(item.month).padStart(2, '0')}.
                                {String(item.day).padStart(2, '0')}
                              </h3>
                            ) : null}
                          </div>
                        </CardImgWrapper>

                        {item.day && monthContext && (
                          <CardBottomWrapper>
                            {String(item.month).padStart(2, '0')}.
                            {String(item.day).padStart(2, '0')}
                          </CardBottomWrapper>
                        )}

                        <CardOverlayWrapper
                          className="overlay"
                          onClick={() => onCardClicked(Number(item.id), item)}
                        >
                          <CardButtonGroup>
                            <CardButton
                              onClick={() => onSelectCardDayMonth(item)}
                            >
                              Select
                            </CardButton>
                          </CardButtonGroup>
                        </CardOverlayWrapper>
                      </DateCardWrapper>
                    );
                  })}
              </CardGridWrapper>
            </>
          ) : !isLoadingDayMonth ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>
              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
      {selectedCraft === 'year' && (
        <SelectCardSectionContainer>
          {myNfts.year != null ? (
            <>
              <h2>Select a Year card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(myNfts.year, 'year', 'asc')
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(myNfts.year, 'year', 'desc')
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(myNfts.year, 'year', 'desc')
                          }
                        >
                          <span>Date High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(myNfts.year, 'year', 'asc')
                          }
                        >
                          <span>Date Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {(filteredListings.year ? filteredListings.year : myNfts.year)
                  .filter((v: any) => !v.is_crafted)
                  .map((item: any) => {
                    const imagePath = getImagePath(
                      item.year,
                      item?.rarity ?? 0,
                      'Month-Day',
                      true
                    );

                    return (
                      <DateCardWrapper key={`year-${item.id}`}>
                        <CardImgWrapper>
                          <img src={imagePath} alt="nft" />
                          <div className="info-nft info-nft-day-month crafting">
                            {(item?.rarity || item?.rarity === 0) &&
                            item?.year ? (
                              <h3
                                className={checkRarity(item?.rarity)}
                                style={{ fontSize: '1.5rem' }}
                              >
                                {item?.year}
                              </h3>
                            ) : null}
                          </div>
                        </CardImgWrapper>

                        {item?.year && (
                          <CardBottomWrapper>{item?.year}</CardBottomWrapper>
                        )}
                        <CardOverlayWrapper
                          className="overlay"
                          onClick={() => onCardClicked(Number(item.id), item)}
                        >
                          <CardButtonGroup>
                            <CardButton onClick={() => onSelectCardYear(item)}>
                              Select
                            </CardButton>
                          </CardButtonGroup>
                        </CardOverlayWrapper>
                      </DateCardWrapper>
                    );
                  })}
              </CardGridWrapper>
            </>
          ) : !isLoadingYear ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>
              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
      {selectedCraft === 'category' && (
        <SelectCardSectionContainer>
          {myNfts.category != null ? (
            <>
              <h2>Select a Category card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.category,
                              'category',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              myNfts.category,
                              'category',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {(filteredListings.category
                  ? filteredListings.category
                  : myNfts.category
                )
                  .filter((v: any) => !v.is_crafted)
                  .map((item: any) => {
                    return (
                      <DateCardWrapper key={`category-${item.id}`}>
                        <CardImgWrapper>
                          {item.rarity || item.rarity === 0 ? (
                            <img
                              src={`/assets/nfts/rarity/${formatCategory(
                                item?.category ?? ''
                              )}-${checkRarity(item?.rarity)}.png`}
                              alt="nft"
                            />
                          ) : (
                            <Loader />
                          )}
                        </CardImgWrapper>
                        <CardBottomWrapper>{item.category}</CardBottomWrapper>
                        <CardOverlayWrapper
                          className="overlay"
                          onClick={() => onCardClicked(Number(item.id), item)}
                        >
                          <CardButtonGroup>
                            <CardButton
                              onClick={() => onSelectCardCategory(item)}
                            >
                              Select
                            </CardButton>
                          </CardButtonGroup>
                        </CardOverlayWrapper>
                      </DateCardWrapper>
                    );
                  })}
              </CardGridWrapper>
            </>
          ) : !isLoadingCategory ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>
              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
    </SelectCardSectionWrapper>
  );
};
