import api from '../config/api';
import { MarketplaceListObjectParams } from '../typeroot/types/actions';

export interface RequestSearchMarketplaceListingParams {
  rarity?: number[];
  status?: number[];
  nft_type_ids: number[];
  card_collection_id: number;
  limit?: number;
  offset?: number;
}

//create offer
export const createMarketplaceOffer = async (params: any) => {
  // what are the params?
  try {
    const res = await api.post(`/marketplace_offer`);
    console.log('res', res);
  } catch (err) {
    console.log('err', err);
  }
};
export const acceptMarketplaceOffer = async (id: any) => {
  console.log('id', id);

  try {
    const res = await api.post(`/marketplace_offer/${id}/approve`);
    console.log('res', res);
  } catch (err) {
    console.log('err', err);
  }
};
export const rejectMarketplaceOffer = async (id: any) => {
  try {
    const res = await api.delete(`/marketplace_offer/${id}`);
    console.log('res', res);
  } catch (err) {
    console.log('err', err);
  }
};

// get offers
