import React, { useState, useEffect } from 'react';
import {
  CardTooltip,
  FilterSectionGrid,
  FilterSectionWrapper,
  SortButton,
  TooltipContent
} from './styles';
import { SelectBox, SortIcon } from '../../components';

import {
  useMarketCardTypesContext,
  useCardTypesContext,
  useAllRaritiesContext,
  useStatusContext,
  useCategoriesContext,
  useTriggersContext,
  useCardCollectionContext
} from '../../context';
import { SelectOptionProps } from '../../typeroot/types';

const packTypes = [
  { checked: false, value: '0', label: 'Standard' },
  { checked: false, value: '1', label: 'Premium' },
  { checked: false, value: '2', label: 'Elite' }
];

export const MFilterSection: React.FC<{
  page?: string;
  onSelectTrigger?: (selected: number[]) => void;
  onSelectCategory?: (selected: number[]) => void;
  onSelectCardTypes?: (selected: number[]) => void;
  onSelectNftCollection?: (selected: number) => void;
  onSelectStatus?: (selected: number[]) => void;
  onSelectRarity?: (selected: number[]) => void;
  sortListings?: any;
  data?: any;
}> = ({
  page,
  onSelectCardTypes,
  onSelectCategory,
  onSelectNftCollection,
  onSelectRarity,
  onSelectStatus,
  onSelectTrigger,
  sortListings,
  data
}) => {
  const { marketCardTypesContext } = useMarketCardTypesContext();
  const { cardTypesContext } = useCardTypesContext();
  const { allRaritiesContext } = useAllRaritiesContext();
  const { statusContext } = useStatusContext();
  const { categoriesContext } = useCategoriesContext();
  const { triggersContext } = useTriggersContext();
  const { cardCollectionContext } = useCardCollectionContext();

  const [sortToolTip, setSortToolTip] = useState(false);
  const [optionsRarity, setOptionsRarity] = useState<SelectOptionProps[]>([]);
  const [optionsCollection, setOptionsCollection] = useState<
    SelectOptionProps[]
  >([]);
  const [optionsStatus, setOptionsStatus] = useState<SelectOptionProps[]>([]);
  const [optionsCardTypes, setOptionsCardTypes] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsCategories, setOptionsCategories] = useState<
    SelectOptionProps[]
  >([]);
  const [optionsTriggers, setOptionsTriggers] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsPackTypes, setOptionsPackTypes] =
    useState<SelectOptionProps[]>(packTypes);

  useEffect(() => {
    if (
      statusContext &&
      categoriesContext &&
      allRaritiesContext &&
      cardTypesContext &&
      marketCardTypesContext &&
      triggersContext
    ) {
      setOptionsStatus(
        Array.from(
          (statusContext as Map<number, { id: number; name: string }>).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );

      const collection: any = [];
      if (cardCollectionContext) {
        for (const item of cardCollectionContext) {
          collection.push({
            checked: false,
            value: item?.id?.toString(),
            label: item.name
          });
        }
      }
      setOptionsCollection(collection);

      setOptionsRarity(
        Array.from(
          (
            allRaritiesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );

      setOptionsCategories(
        Array.from(
          (
            categoriesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );

      setOptionsCardTypes(
        Array.from(
          (
            marketCardTypesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );

      setOptionsTriggers(
        Array.from(
          (
            triggersContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );
    }
  }, [
    statusContext,
    categoriesContext,
    allRaritiesContext,
    cardTypesContext,
    marketCardTypesContext,
    triggersContext,
    cardCollectionContext
  ]);

  const handleOptionSelect = (filterType: string, selected: string[]) => {
    switch (filterType) {
      case 'Collections':
        onSelectNftCollection && onSelectNftCollection(Number(selected[0]));
        break;
      case 'Status':
        onSelectStatus &&
          onSelectStatus(
            selected.map((v) => {
              return Number(v);
            })
          );
        break;
      case 'Trigger Type':
        onSelectTrigger &&
          onSelectTrigger(
            selected.map((v) => {
              return Number(v);
            })
          );
        break;
      case 'Categories':
        onSelectCategory &&
          onSelectCategory(
            selected.map((v) => {
              return Number(v);
            })
          );
        break;
      case 'All Rarities':
        onSelectRarity &&
          onSelectRarity(
            selected.map((v) => {
              return Number(v);
            })
          );
        break;
      case 'Card Types':
        onSelectCardTypes &&
          onSelectCardTypes(
            selected.map((v) => {
              return Number(v);
            })
          );
        break;
    }
  };

  const getTimeStemp = (date: string) => {
    const now = new Date(date);
    const timestamp = now.getTime();
    return timestamp;
  };

  return (
    <FilterSectionWrapper>
      <h3>Filter traits</h3>
      <FilterSectionGrid>
        {!page && (
          <>
            <SelectBox
              isFilter
              options={optionsCardTypes}
              placeholder="Card Types"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsCollection}
              placeholder="Collections"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsRarity}
              placeholder="All Rarities"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsStatus}
              placeholder="Status"
              onClick={handleOptionSelect}
            />
          </>
        )}
        {page === 'identities' && (
          <>
            <SelectBox
              isFilter
              options={optionsCategories}
              placeholder="Categories"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsCollection}
              placeholder="Collections"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsRarity}
              placeholder="All Rarities"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsStatus}
              placeholder="Status"
              onClick={handleOptionSelect}
            />
          </>
        )}
        {page === 'predictions' && (
          <>
            <SelectBox
              isFilter
              options={optionsTriggers}
              placeholder="Trigger Type"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsCategories}
              placeholder="Categories"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsCollection}
              placeholder="Collections"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsRarity}
              placeholder="All Rarities"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsStatus}
              placeholder="Status"
              onClick={handleOptionSelect}
            />
          </>
        )}

        {page === 'packs' && (
          <>
            <SelectBox
              isFilter
              options={optionsRarity}
              placeholder="All Rarities"
              onClick={handleOptionSelect}
            />
            <SelectBox
              isFilter
              options={optionsCollection}
              placeholder="Collections"
              onClick={handleOptionSelect}
            />

            <SelectBox
              isFilter
              options={optionsStatus}
              placeholder="Status"
              onClick={handleOptionSelect}
            />
          </>
        )}
        {data && (
          <CardTooltip className="sortTooltip">
            <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
              <SortIcon />
            </SortButton>
            {sortToolTip && (
              <TooltipContent onClick={() => setSortToolTip(!sortToolTip)}>
                <div>
                  <div className="OptionHeading">
                    <span>Sort By</span>
                  </div>
                  <div
                    className="sOption"
                    onClick={() => sortListings(data, 'asc')}
                  >
                    <span>Rarity High-Low</span>
                  </div>
                  <div
                    className="sOption"
                    onClick={() => sortListings(data, 'desc')}
                  >
                    <span>Rarity Low-High</span>
                  </div>
                </div>
              </TooltipContent>
            )}
          </CardTooltip>
        )}
      </FilterSectionGrid>
    </FilterSectionWrapper>
  );
};
