import React, { useEffect, useRef } from 'react';
import { CloseButton, NotificationWrapper, NotificationsGroup } from './styles';
import { notifications } from './data';
import { NotificationItem } from '../../components/NotificationItem';
import { NotificationProps } from '../../typeroot/types';
import moment from 'moment';
import api from '../../config/api';
import { toCapitalCase } from '../../utils/helperFunctions';

export const Notification: React.FC<NotificationProps> = ({
  onClose,
  open,
  data,
  onClear
}) => {
  const wrapperRef = useRef<any>(null);
  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       onClose();
  //     }
  //   };
  //   // Bind the event listener
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getType = (type: number) => {
    switch (type) {
      case 0:
        return 'Card Pack';
      case 1:
        return 'Crafting Card';
      case 2:
        return 'Category Card';
      case 3:
        return 'Month/Day Card';
      case 4:
        return 'Year Card';
      case 5:
        return 'Trigger Card';
      case 6:
        return 'Identity Card';
      case 7:
        return 'Prediction Card';
      default:
        return '';
    }
  };

  const handleClearNotification = async (id: number) => {
    await api.put(`/me/notification/${id}`, { is_read: true });
    onClear();
  };

  return (
    <NotificationWrapper ref={wrapperRef} open={open}>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <h3>Notifications</h3>
      <NotificationsGroup>
        {data.length > 0 ? (
          data.map((item, key) => {
            if (!item.is_read) {
              if (item?.price) {
                return (
                  <NotificationItem
                    date={moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                    desc={`Your sold a ${getType(item.nft_type_id)} for ${(
                      item.price / 100
                    ).toFixed(2)}`}
                    title={'Item sold!'}
                    isNew={!item.is_read}
                    key={key}
                    onClick={() =>
                      handleClearNotification(item.notification_id)
                    }
                  />
                );
              } else if (!item.to_account){
                  <NotificationItem
                    date={moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                    desc={
`Your withdrawal to ${item?.to_account} for $${item?.amount} was denied`
                    }
                    title={
'Your withdrawal was denied'
                    }
                    isNew={!item.is_read}
                    key={key}
                    onClick={() =>
                      handleClearNotification(item.notification_id)
                    }
                  />;
              }else {
                return (
                  <NotificationItem
                    date={moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                    desc={
                      item?.status === 1
                        ? `The trigger ${item?.claim_trigger?.trigger} has come true for ${item?.nft_prediction?.celebrity_name}`
                        : `Your claim ${item?.claim_trigger?.trigger} for ${item?.nft_prediction?.celebrity_name} was denied because we determined the event did not happen`
                    }
                    title={
                      item?.status === 1
                        ? 'Your Prediction Came True'
                        : 'Your claim was denied'
                    }
                    isNew={!item.is_read}
                    key={key}
                    onClick={() =>
                      handleClearNotification(item.notification_id)
                    }
                  />
                );
              }
            }
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            You have no new notifications
          </div>
        )}
      </NotificationsGroup>

      {/* <p>
        <div onClick={handleClearAll}>Clear All</div>
      </p> */}
    </NotificationWrapper>
  );
};
