import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AuthFormGroup,
    AuthFormTitle,
    Button,
    Input,
    SocialButtonsGroup,
} from '../../components';
import {
    AuthFormWrapper,
    FormActionText,
    FormActionWrapper,
    SignUpCheckbox,
} from './styles';

import { ToastContainer, toast } from 'react-toastify';
import { register } from '../../actions';
import { signupFormValidation } from '../../utils';

export const SignUpForm: React.FC = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({ username: '', email: '', password: '' });
    const [error, setError] = useState({ username: '', email: '', password: '' });
    const [isAgree, setIsAgree] = useState(true);

    const handleSignup = async () => {
        const { isValid, errors } = signupFormValidation(form);
        setError(errors);
        if (isValid) {
            try {
                const res = await register({ ...form });
                if (res.success) {
                    navigate('/check-email?type=signup');
                } else {
                    toast.error(res.message);
                }
            } catch (error) {
                console.error('Error signing up:', error);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <AuthFormWrapper>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <AuthFormTitle>Sign Up</AuthFormTitle>
            <SocialButtonsGroup authType="Signup" />
            <AuthFormGroup>
                <Input
                    label="Username"
                    value={form.username}
                    onChange={handleChange}
                    error={error.username}
                    name="username"
                    placeholder="e.g moulee"
                />
                <Input
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    error={error.email}
                    value={form.email}
                    placeholder="e.g moulee@example.com"
                />
                <Input
                    label="Password"
                    type="password"
                    onChange={handleChange}
                    error={error.password}
                    value={form.password}
                    name="password"
                    placeholder="Enter password"
                />
            </AuthFormGroup>
            <SignUpCheckbox htmlFor="signup-checkbox">
                <input
                    type="checkbox"
                    id="signup-checkbox"
                    onClick={(e) => setIsAgree(!e.currentTarget.checked)}
                />
                <span>
                    {'I am at least 21 years of age and agree to the '}
                    <Link to="/term-conditions">Terms & Conditions</Link>
                    {' & '}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    {
                        ' of TwoTwenkyK and agree to  opt-in to receive TwoTwenkyK’s Newsletter and to receive email, alerts, news and other offers from TwoTwenkyK and/or its affiliated entities.'
                    }
                </span>
            </SignUpCheckbox>
            <FormActionWrapper>
                <Button onClick={handleSignup} disabled={isAgree}>
                    Sign Up
                </Button>
                <FormActionText>
                    {'Already have an account? '} <Link to={'/signin'}>Login now</Link>
                </FormActionText>
            </FormActionWrapper>
        </AuthFormWrapper>
    );
};
