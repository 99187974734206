import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AuthFormDesc,
  AuthFormWrapper,
  FormActionText,
  FormActionWrapper
} from './styles';
import { AuthFormTitle, Button, Input } from '../../components';
import { forgotPasswordFormValidation } from '../../utils';
import api from '../../config/api';

export const ForgetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSendEmail = async () => {
    const { isValid, error } = forgotPasswordFormValidation(email);
    setError(error);

    if (isValid) {
      await api.post('/auth/reset_password', { email });
      navigate('/check-email?type=forgot');
      return;
    }
  };

  return (
    <AuthFormWrapper>
      <AuthFormTitle>Forgot Password</AuthFormTitle>
      <AuthFormDesc>
        <span>Enter the email associated with your account</span>
        <p>{'We’ll send an email with instructions to reset your password'}</p>
      </AuthFormDesc>
      <Input
        label="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="e.g moulee@example.com"
        error={error}
      />
      <FormActionWrapper>
        <Button onClick={handleSendEmail}>Send Email</Button>
        <FormActionText>
          {'Don’t have an account? '} <Link to={'/signup'}>Sign Up now</Link>
        </FormActionText>
      </FormActionWrapper>
    </AuthFormWrapper>
  );
};
