import React from 'react';
import { AuthLayout } from '../../layout';
import {
  PrivayPolicyWrapper,
  PolicyPageTitle,
  PolicyPageContainer,
  PolicyPageContent,
  PolicyPageLastRevised,
  PolicyParagraph,
  PolicyStrong,
  PolicyPageSubTitle,
  PolicypageSubTitle2
} from './styles';

export const PrivacyPolicy: React.FC = () => {
  return (
    <AuthLayout>
      <PrivayPolicyWrapper>
        <PolicyPageContainer>
          <PolicyPageTitle>
            <h3>Privacy Policy</h3>
          </PolicyPageTitle>
          <PolicyPageLastRevised>
            <h4>Last Revised on May 15, 2023</h4>
          </PolicyPageLastRevised>
          <PolicyPageContent>
            <PolicyParagraph>
              Welcome to the Privacy Policy (this “
              <PolicyStrong>Privacy Policy</PolicyStrong>”) for the website{' '}
              <a
                href="https://www.twotwentyk.io"
                target="_blank"
                rel="noreferrer"
              >
                www.twotwentyk.io
              </a>{' '}
              (the “<PolicyStrong>Website</PolicyStrong>”), the derivative
              gaming platform (the “<PolicyStrong>Platform</PolicyStrong>”), and
              all other applications, content, smart contracts, tools, features,
              and functionality offered on or through the Website and the
              Platform (collectively with the Website and the Platform, the “
              <PolicyStrong>Services</PolicyStrong>”), in each case operated by
              or on behalf of 10XChi, Inc. (“
              <PolicyStrong>10XChi,</PolicyStrong>” “
              <PolicyStrong>we</PolicyStrong>,” “<PolicyStrong>us</PolicyStrong>
              ,” or “<PolicyStrong>our</PolicyStrong>”).
            </PolicyParagraph>
            <PolicyParagraph>
              We are committed to respecting and protecting your privacy and
              complying with any applicable law and regulation regarding your
              personal information. This Privacy Policy describes how we
              collect, use, disclose, and protect your personal information,
              including across the Website and Platform and in connection with
              the Services.
            </PolicyParagraph>
            <PolicyParagraph>
              Where appropriate or legally required, we will describe how
              personal information about you that we collect through our
              Services will be used and will provide choices about whether or
              not to allow us to engage in that use. To exercise your choice,
              you can contact us to correct, update, amend or request deletion
              of your personal information at{' '}
              <a href="mailto:admin@220k.io">admin@220k.io</a>, or at the
              following address: 10XChi, Inc., 3200 N Federal Hwy, Suite 118,
              Boca Raton, FL. 33431.
            </PolicyParagraph>
            <PolicyParagraph>
              Please read this Privacy Policy carefully. If you do not agree
              with this Privacy Policy in general or any part of it, you should
              not access the website, use our Services, or otherwise provide
              your information to us.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h4>Information We Collect</h4>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              Information we collect falls into one of two categories:
              “automatically collected” information and “voluntarily provided”
              information.
              <br /> <br />
              “Automatically collected” information refers to any information
              automatically sent by your devices in the course of accessing our
              Services.
              <br /> <br />
              “Voluntarily provided” information refers to any information you
              knowingly and actively provide us when using or participating in
              any of our Services.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h4>Automatically Collected Information</h4>
            </PolicyPageSubTitle>
            <PolicypageSubTitle2>
              <h5>Log Data</h5>
            </PolicypageSubTitle2>
            <PolicyParagraph>
              When you visit our Services, our servers may automatically log the
              standard data provided by your web browser. It may include your
              device’s Internet Protocol (IP) address, geolocation data, your
              browser type and version, the pages you visit, the time and date
              of your visit, the time spent on each page, the referring site,
              website elements clicked, and other details about your visit.
            </PolicyParagraph>
            <PolicyParagraph>
              Additionally, if you encounter certain errors while using the
              site, we may automatically collect data about the error and the
              circumstances surrounding its occurrence. This data may include
              technical details about your device, what you were trying to do
              when the error happened, and other technical information relating
              to the problem. You may or may not receive notice of such errors,
              even in the moment they occur, that they have occurred, or what
              the nature of the error is.
            </PolicyParagraph>
            <PolicyParagraph>
              Please be aware that while this information may not be personally
              identifying by itself, it may be possible to combine it with other
              data to personally identify individual persons.
            </PolicyParagraph>
            <PolicypageSubTitle2>
              <h5>Device Data</h5>
            </PolicypageSubTitle2>
            <PolicyParagraph>
              When you visit the Platform or interact with our Services, we may
              automatically collect data about your device, such as:
              <ul>
                <li>
                  Technical information about your device, such as device type,
                  browser type, IP address, operating system, and device
                  identifier. We collect this information automatically from
                  your device and web browser through cookies and similar
                  technologies.
                </li>
                <li>
                  • Information about how you interact with us. We may collect
                  technical data about your usage of the Platforms and Apps and
                  how you interact with our digital advertisements and
                  promotions, such as content viewed or downloaded, features
                  used, links clicked, our promotional emails opened, and dates
                  and times of interactions. We collect this information using
                  cookies and similar technologies.
                </li>
                <li>
                  We may collect location information, including precise
                  real-time location information from your device and imprecise
                  location information derived from, for example, your IP
                  address or postal code.
                </li>
              </ul>
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h4>Voluntarily Provided Information</h4>
            </PolicyPageSubTitle>
            <PolicypageSubTitle2>
              <h5>Personal Information</h5>
            </PolicypageSubTitle2>
            <PolicyParagraph>
              We may ask for personal information — for example, when you
              register an account, make a purchase, request support for our
              Services, or when you otherwise contact us, including on Discord —
              which may include one or more of the following:
            </PolicyParagraph>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Discord username, if you interact with us there</li>
              <li>Digital Asset Wallet Address</li>
              <li>
                Other information necessary for Anti-Money Laundering (“
                <PolicyStrong>AML</PolicyStrong>”), Counter Financing of
                Terrorism (“<PolicyStrong>CFT</PolicyStrong>”), and Know Your
                Customer (“<PolicyStrong>KYC</PolicyStrong>”) compliance
                purposes
              </li>
              <li>
                Any information provided by you when you register for an Account
                to use the Platform
              </li>
              <li>
                Transaction data, including comments, questions, requests and
                orders you may make, as well as purchase order history and
                information
              </li>
              <li>
                Financial information needed to process payments if you make
                purchases, such as credit card or other financial account
                information; and
              </li>
              <li>
                Any other information you choose to provide, such as when you
                fill in a form, file a support ticket, provide screenshots,
                conduct a search, update or add information to your account,
                respond to surveys, participate in promotions, correspond with
                us, or use other features of our Services.
              </li>
            </ul>
            <PolicyParagraph>
              You have choices about the personal information you provide us.
              You may choose not to provide information that we request, but if
              you do so, we may not be able to provide you a relevant service or
              a particular feature for our Services.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Third-Party Information</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We may collect personal information through our business partners.
              This information may include contact information, such as name,
              e-mail address, and phone number.
            </PolicyParagraph>
            <PolicyParagraph>
              We may also obtain personal information from other third-party
              sources, including publicly and commercially available sources. We
              may combine the information we receive from our business partners
              and other third-party sources with information that we collect
              from you or your device, as described above.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Collection and Use of Information</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We may collect personal information from you when you do any of
              the following on our Services:
            </PolicyParagraph>
            <ul>
              <li>Register for an Account</li>
              <li>
                Use a mobile device, personal computer, or web browser to access
                our Services
              </li>
              <li>
                Contact us via email, social media, chat programs, or on any
                similar technologies
              </li>
              <li>When you mention us on social media</li>
            </ul>
            <PolicyParagraph>
              We may use information for the following purposes, and personal
              information will not be further processed in a manner that is
              incompatible with these purposes
            </PolicyParagraph>
            <ul>
              <li>
                to provide you with our Services or carrying out transactions
                that you or our business partners have requested
              </li>
              <li>
                to enable you to customize or personalize your experience of our
                Services
              </li>
              <li>to operate, improve, and develop our Services</li>
              <li>to contact and communicate with you</li>
              <li>
                to enable you to access and use our Services, associated
                interactive features, associated applications, and associated
                social media or chat platforms
              </li>
              <li>
                to provide you with information about our products, services,
                and transactions, and advertisements, that may be of interest to
                you
              </li>
              <li>
                to provide you and our business partners a more consistent
                experience in interacting with us, including by learning more
                about you and how you use and interact with our Services; and{' '}
              </li>
              <li>
                planning, managing, and performing under our contractual
                relationships with our business partners
              </li>
              <li>
                to comply with our legal obligations and resolve any disputes
                that we may have
              </li>
              <li>
                for security and fraud prevention, and to ensure that our sites
                and apps are safe, secure, and used in line with our Terms of
                Use
              </li>
            </ul>
            <PolicyParagraph>
              We may anonymize or aggregate any of the information we collect
              and use it for any purpose, including for research and
              product-development purposes. Such information will not identify
              you individually.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Security of Your Personal Information</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              When we collect and process personal information, and while we
              retain this information, we will protect it within commercially
              acceptable means to prevent loss and theft, as well as
              unauthorized access, disclosure, copying, use, or modification.
            </PolicyParagraph>
            <PolicyParagraph>
              Your personal information will generally be stored in our
              databases or databases maintained by our service providers. Many
              of these databases are stored on servers located in the United
              States. We take appropriate measures, by contract or otherwise, to
              provide adequate protection for personal information that is
              disclosed to a third party or transferred to another country.
            </PolicyParagraph>
            <PolicyParagraph>
              Although we will implement and maintain practices to protect the
              personal information you provide to us, we advise that no method
              of electronic transmission or storage is 100% secure, and no one
              can guarantee absolute data security. We recommend that you do not
              use unsecure channels to communicate sensitive or confidential
              information to us. Any information you transmit to us is at your
              own risk.
            </PolicyParagraph>
            <PolicyParagraph>
              You are responsible for selecting any password, to the extent one
              is required, and its overall security strength, ensuring the
              security of your own information within the bounds of our
              services. For example, you are responsible for ensuring any
              passwords associated with accessing your personal information and
              accounts are secure and confidential.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>How Long We Keep Your Personal Information.</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              How long we keep your personal information will vary and will
              depend on the purpose and use of information collected. There are
              legal requirements that we keep some types of data for specific
              periods. Otherwise, we will retain it for no longer than is
              necessary for the purposes for which the data was collected.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Children’s Privacy.</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We do not aim any of our Services directly at children under the
              age of 18, and we do not knowingly collect personal information
              about children under 18. If we become aware that we have
              unknowingly collected information about a child under 18 years of
              age, we will make commercially reasonable efforts to delete such
              information from our database.
            </PolicyParagraph>
            <PolicyParagraph>
              If you are the parent or guardian of a child under 18 years of age
              who has provided us with their personal information, you may
              contact us using the below information to request that it be
              deleted.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Disclosure of Personal Information to Third Parties</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We may disclose personal information to third parties in the
              following circumstances:
            </PolicyParagraph>
            <ul>
              <li>
                to our parent, subsidiary, and/or affiliated entities, and their
                respective employees, contractors, and/or related entities, as
                required to provide the Services
              </li>
              <li>
                with service providers for the purpose of enabling them to
                provide our Services, including IT service providers, data
                storage, hosting and server providers, analytics, error loggers,
                debt collectors, maintenance or problem-solving providers,
                professional advisors, and payment systems operators
              </li>
              <li>advertising partners</li>
              <li>
                credit reporting agencies, courts, tribunals, and regulatory
                authorities, in the event you fail to pay for goods or services
                we have provided to you
              </li>
              <li>
                courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, or any obligations arising
                thereunder, or in connection with any actual or prospective
                legal proceedings, or in order to establish, exercise, or defend
                our legal rights or those of a third party
              </li>
              <li>
                to prevent physical harm, financial loss, or fraud on our
                Services
              </li>
              <li>
                an entity that buys, or to which we transfer, all or
                substantially all of our assets and business
              </li>
              <li>
                with third parties in connection with our sale, purchase,
                merger, reorganization, liquidation or dissolution or under
                similar circumstances. If such an event occurs, we will take
                appropriate steps to protect your personal informatio
              </li>
              <li>with your permission or at your request</li>
            </ul>
            <PolicyParagraph>
              We may share anonymized or aggregated information internally and
              with third parties for any purpose, including, but not limited to,
              selling such information to third parties. Such information will
              not identify you individually.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Your Rights and Controlling Your Personal Information</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              <i>Correcting or Updating Your Personal Information</i>: You may
              review, correct and update personal information you provide to us
              by contacting us through email or by writing to us at the address
              set forth below.
            </PolicyParagraph>
            <PolicyParagraph>
              <i>Opting Out</i>: If you have previously agreed to us using your
              personal information for direct marketing purposes, you may change
              your mind at any time by contacting us through email or by writing
              to us at the address set forth below.
            </PolicyParagraph>
            <PolicyParagraph>
              <i>Complaints</i>: If you believe that we have breached a relevant
              data protection law and wish to make a complaint, please contact
              us using the details below and provide us with full details of the
              alleged breach. We will promptly investigate your complaint and
              respond to you, in writing, setting out the outcome of our
              investigation and the steps we will take to deal with your
              complaint. You may also have the right to contact a regulatory
              body or data protection authority in relation to your complaint.
              Please see the EU/UK Addendum for further detail.
            </PolicyParagraph>
            <i>Contact Information:</i>
            <br /> <br />
            10XChi, Inc., <br></br>
            3200 N Federal Hwy, Suite 118 <br></br>
            Boca Raton, FL. 33431 <br></br>
            <a href="mailto:admin@220k.io">admin@220k.io</a> <br></br>
            <br></br>
            <PolicyPageSubTitle>
              <h5>Cookies</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              The Website, like most websites, uses cookies, pixels, and other
              tracking technologies (“<b>Tracking Technologies</b>”) to collect
              information about you and your activity across our Platform. A
              cookie, for example, is a small piece of data related to your
              interactions with the Platform, that our Platform stores on your
              computer or , and accesses each time you visit, so we can
              understand how you use our site. A pixel is a small portion of
              code that we use as part of our Services. We use pixels to learn
              whether you have clicked on certain content. This helps us measure
              and improve our services and personalize your experience. Tracking
              Technologies help us to improve our Services including by
              conducting statistical analyses, estimating audience size and
              usage patterns, and identifying a user’s digital asset wallet
              address and wallet provider.
            </PolicyParagraph>
            <PolicyParagraph>
              Certain countries require your consent to the use of Tracking
              Technologies. If you access the Platform for one of these
              countries, you may be presented with information regarding your
              options, including the option to accept or reject certain
              categories of Tracking Technologies. You will be able to change
              these settings on subsequent visits. Please note that you will not
              be able to opt out of receiving certain cookies that are strictly
              necessary for the delivery of a service requested by you.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Analytics and Advertising.</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We use third-party analytics services to better understand how
              users engage with the Services. The information generated by
              certain of our Tracking Technologies about your use of the
              Services (including your IP address) will be transmitted to and
              stored by our web analytics providers on their servers. The web
              analytics providers will use this information for the purpose of
              evaluating your use of the Services, compiling reports on Services
              activity and providing other services related to the Website and
              Platform, activity and internet usage. The web analytics providers
              may also transfer this information to third parties where required
              to do so by law, or where such third parties process the
              information on their behalf.
            </PolicyParagraph>
            <PolicyParagraph>
              We use different analytic tools which serve the purpose of
              measuring, analyzing and optimizing our marketing measures and
              provide you with customized advertisements that could be of
              particular interest to you.
            </PolicyParagraph>
            <PolicyParagraph>
              Our Platform and Website, may allow selected third parties,
              including these analytics and advertising services, to place
              cookies on our Platform and Website. These third parties may
              collect information about your online activities over time and
              across third-party websites.
            </PolicyParagraph>
            <PolicyParagraph>
              These third parties may be members of industry self-regulatory
              groups such as the Network Advertising Initiative (“<b>NAI</b>”)
              and Digital Advertising Alliance (“<b>DAA</b>”). The websites for
              these groups provide more information on interest-based online
              advertising and how to opt out of receiving interest-based online
              ads from participating companies.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Do Not Track Signals</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              Some browsers may transmit "Do Not Track" ("DNT") signals to our
              Platform and Website. Because there is not common agreement about
              how to interpret DNT signals, we currently do not take action in
              response to them. To learn more about “Do Not Track” signals, you
              can visit{' '}
              <a
                href="https://www.allaboutdnt.com/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.allaboutdnt.com/
              </a>
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Business Transfers</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              If we or our assets are acquired, or in the unlikely event that we
              go out of business or enter bankruptcy or an insolvency
              proceeding, we would include data, including your personal
              information, among the assets transferred to any parties who
              acquire us. You acknowledge that such transfers may occur, and
              that any parties who acquire us may, to the extent permitted by
              applicable law, continue to use your personal information
              according to this Privacy Policy, which they will be required to
              assume as it is the basis for any ownership or use rights we have
              over such information.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Third Party Websites and Links</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              Our Services may link to or interact with third party sites or
              services, such as your digital wallet provider, or chat platforms,
              such as Discord, that are not operated by us. Our inclusion of
              such links or use of such services or platforms does not, by
              itself, imply any endorsement of the content on such platforms or
              of their owners or operators except as disclosed on our Services.
              After following a link to any third-party content, you should read
              their posted privacy policy information about how they collect and
              use personal information. Any information submitted by you
              directly to these third parties is subject to that third party’s
              privacy policy. Please be aware that we have no control over the
              content and policies of those sites and cannot accept
              responsibility or liability for their respective privacy
              practices. This Privacy Policy does not apply to any of your
              activities after you leave our Services.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>User-Generated Content</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              We do not control who will have access to the information that you
              choose to make available to others and cannot ensure that parties
              who have access to such information will respect your privacy or
              keep it secure. We are not responsible for the privacy or security
              of any information that you make publicly available on the
              features permitting creation of User-Generated Content (“UGC”) or
              what others do with information you share with them on the
              Platforms. We are not responsible for the accuracy, use, or misuse
              of any UGC that you disclose or receive from third parties through
              social media or discussions on our Services.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>California Privacy Rights Addendum</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              If you are a verified California resident, you may have, pursuant
              to the California Consumer Protection Privacy Act (the “CCPA”),
              the following rights with respect to your personal information,
              which apply differently in different circumstances: right to
              access, right to information, and the right to deletion. We do not
              and will not sell personal information, however, may sell
              aggregated anonymous user data. Most of these rights are not
              absolute. Below we describe these rights in more detail and
              provide information on how you can exercise them. If you are a
              verified California consumer and make a request to exercise your
              rights, we will respond within 45 days, but we have the right to
              extend this period by 45 additional days where necessary. If we
              extend the response period, we will let you know within 45 days
              from your request. You can exercise your rights by contacting us
              at:
            </PolicyParagraph>
            10XChi, Inc., <br></br>
            3200 N Federal Hwy, Suite 118 <br></br>
            Boca Raton, FL. 33431 <br></br>
            <a href="mailto:admin@220k.io">admin@220k.io</a> <br></br>
            <br></br>
            <i>Right to Access.</i>
            <br></br>
            <br></br>
            <PolicyParagraph>
              You have a right to ask us to provide the categories and specific
              pieces of personal information that we retain about you through
              your account with us or, at your option, by mail or in a readily
              usable electronic format. We are obligated to respond to no more
              than two Right-to-Access requests in a 12-month period. The Right
              to Access does not apply to information collected by us for a
              single transaction.
            </PolicyParagraph>
            <i>Right to Information.</i>
            <br></br>
            <PolicyParagraph>
              You have the right to ask us to disclose to you:
            </PolicyParagraph>
            (i) the categories of personal information that we have collected
            about you in the 12 months preceding the request and the specific
            pieces of that personal information, <br></br>
            <br></br>
            (ii) the categories of sources from which the personal information
            was collected<br></br>
            <br></br>
            (iii) the business purpose or commercial purpose for collecting or
            selling the personal information,<br></br>
            <br></br>
            (iv) the categories of third parties with whom or which the personal
            information is shared,<br></br>
            <br></br>
            (v) the categories of personal information that we have sold about
            you and the categories of parties to whom or which we sold each
            category of personal information, and<br></br>
            <br></br>
            (vi) the categories of personal information that we have disclosed
            about you for a business purpose.<br></br>
            <br></br>
            <i>Right to Deletion.</i>
            <br></br>
            <PolicyParagraph>
              You have the right to ask us to delete personal information we
              have collected about you. The Right to Deletion does not apply
              when we need the personal information:
            </PolicyParagraph>
            (i) to complete a transaction with you, provide a good or service
            requested by you, or otherwise perform a contract with you;<br></br>
            <br></br>
            (ii) to detect or prevent security incidents or illegal activity;
            <br></br>
            <br></br>
            (iii) to identify and correct errors that impair existing
            functionality;<br></br>
            <br></br>
            (iv) for the exercise of a legal right or to ensure another person
            can exercise his or her legal right;<br></br>
            <br></br>
            (v) to comply with the California Electronic Communications Privacy
            Act;<br></br>
            <br></br>
            (vi) to engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest if deletion of the
            personal information is likely to make the research impossible or
            seriously impair it;<br></br>
            <br></br>
            (vii) solely for internal uses that are reasonably aligned with
            expectations based on your relationship with us<br></br>
            <br></br>
            (viii) to comply with a legal obligation; or<br></br>
            <br></br>
            (ix) for lawful internal uses that are compatible with the context
            in which we obtained the information.<br></br>
            <br></br>
            <PolicyPageSubTitle>
              <h5>EU/UK Addendum</h5>
            </PolicyPageSubTitle>
            <br></br>
            <i>Our Legal Basis</i>
            <br></br>
            <PolicyParagraph>
              We process your personal information to perform our contract with
              you, such as:
            </PolicyParagraph>
            <ul>
              <li>register and maintain your account with us</li>
              <li>
                provide you with our Services, other content, and Platform
                functionality
              </li>
              <li>
                to enable you to access and use our Services, associated
                applications, and associated social media platforms
              </li>
            </ul>
            <PolicyParagraph>
              We obtain your consent to process your personal information for
              the following reasons:
            </PolicyParagraph>
            <ul>
              <li>sign you up for marketing communications</li>
              <li>personalize our Services for you</li>
              <li>
                if you opted into marketing, to communicate with you about
                products, services, marketing, promotions, events, and other
                news and information we think will be of interest to you
              </li>
            </ul>
            <PolicyParagraph>
              In order to be responsive to you, to provide effective services to
              you, and to maintain our business relationship, as a matter of our
              legitimate interests, we will use your personal information to
              contact and communicate with you.
            </PolicyParagraph>
            <PolicyParagraph>
              We may also use your personal information to comply with our legal
              obligations (such as compliance obligations) and resolve any
              disputes that we may have. We may also process your personal
              information for security and fraud prevention, and to ensure that
              our sites and apps are safe, secure, and used in line with our
              Terms of Use.
            </PolicyParagraph>
            <PolicyParagraph>
              <i>Where We Store Your Data</i>
            </PolicyParagraph>
            <PolicyParagraph>
              The personal information that we collect from you will be
              transferred to and stored at/processed in countries outside the
              European Economic Area (“<b>EEA</b>”) and the United Kingdom (“
              <b>UK</b>”). Your personal information is also processed by staff
              operating outside the EEA and the UK who work for us or one of our
              third party service providers or partners. We will take all steps
              reasonably necessary to ensure that your personal information is
              treated securely and in accordance with this Privacy Policy.
            </PolicyParagraph>
            <PolicyParagraph>
              For any transfers of data outside the EEA or the UK, the data
              transfer will be under the European Commission’s model contracts
              for the transfer of personal information to third countries (i.e.,
              the standard contractual clauses) (the “<b>Model Clauses</b>”), or
              any equivalent contracts issued by the relevant competent
              authority of the UK, as relevant, unless the data transfer is to a
              country that has been determined by the European Commission or the
              relevant UK authorities, as applicable, to provide an adequate
              level of protection for individuals’ rights and freedoms for their
              personal information. Please contact us at{' '}
              <a href="mailto:admin@220k.io">admin@220k.io</a> should you wish
              to examine a copy of the Model Clauses.
            </PolicyParagraph>
            <PolicyParagraph>
              <i>Rights</i>
            </PolicyParagraph>
            <PolicyParagraph>
              If you are located in the EEA or the UK, you have certain rights
              in relation to your personal information, including, but not
              limited to:
            </PolicyParagraph>
            <ul>
              <li>
                <b>Access</b>: You have the right to access personal information
                we hold about you, how we use it, and who we share it with.
              </li>
              <br></br>
              <li>
                <b>Portability</b>: You have the right to receive a copy of the
                personal information we hold about you and to request that we
                transfer it to a third party, in certain circumstances and with
                certain exceptions.
              </li>
              <br></br>
              <li>
                <b>Correction</b>: You have the right to correct any of your
                personal information we hold that is inaccurate.
              </li>
              <br></br>
              <li>
                <b>Erasure</b>: In certain circumstances, you have the right to
                delete the personal information we hold about you.
              </li>
              <br></br>
              <li>
                <b>Restriction of processing to storage only</b>: You have the
                right to require us to stop processing the personal information
                we hold about you, other than for storage purposes, in certain
                circumstances.
              </li>
              <br></br>
              <li>
                <b>Objection</b>: You have the right to object to our processing
                of your personal information
              </li>
              <br></br>
              <li>
                <b>Objection to marketing</b>: You can object to marketing at
                any time by opting out using the unsubscribe/ opt-out function
                displayed in our communications to you.
              </li>
              <br></br>
              <li>
                <b>Withdrawal of consent</b>: Where we rely on consent to
                process your personal information, you have the right to
                withdraw this consent at any time by emailing us at{' '}
                <a href="mailto:admin@220k.io">admin@220k.io</a>.
              </li>
            </ul>
            <PolicyParagraph>
              Please note that a number of these rights only apply in certain
              circumstances, and all of these rights may be limited by law. For
              example, where fulfilling your request would adversely affect
              other individuals or our trade secrets or intellectual property,
              where there are overriding public interests, or where we are
              required by law to retain your personal information.
            </PolicyParagraph>
            <PolicyParagraph>
              To exercise any of these rights, you can email us at
              admin@220k.io. We will respond to requests to exercise these
              rights without undue delay and at least within one month (though
              this may be extended by a further two months in certain
              circumstances). We will use our best efforts to address and settle
              any requests or complaints brought to our attention. In addition,
              you have the right to approach the competent data protection
              authority with requests or complaints. This can be the supervisory
              authority in the country or federal state where you live. The
              overall competent supervisory authority for us in Europe is:
              Commission for the Protection of Privacy, Rue de la Presse 35,
              1000 Brussels; Telephone: +32 (0)2 274 4800; E-mail:{' '}
              <a href="mailto:admin@220k.io">commission@privacycommission.be</a>
              ; Website:{' '}
              <a
                href="http://www.privacycommission.be"
                target="_blank"
                rel="noreferrer"
              >
                www.privacycommission.be.
              </a>
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Complaints & Questions</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              If you have complaints or questions about how we process your
              personal information, please contact us at{' '}
              <a href="mailto:admin@220k.io">admin@220k.io</a> and we will
              respond to your request as soon as possible.
            </PolicyParagraph>
            <PolicyParagraph>
              If you think we have infringed data protection laws, you can file
              a claim with the data protection supervisory authority in the EEA
              country in which you live or work or where you think we have
              infringed data protection laws, or with the UK Information
              Commissioner’s Office, as applicable to you.
            </PolicyParagraph>
            <PolicyPageSubTitle>
              <h5>Changes to This Policy</h5>
            </PolicyPageSubTitle>
            <PolicyParagraph>
              At our discretion, we may change our Privacy Policy to reflect
              updates to our business processes, current acceptable practices,
              or legislative or regulatory changes. If we decide to change this
              Privacy Policy, we will post the changes here at the same link by
              which you are accessing this Privacy Policy with the last revised
              date. Updates to our Privacy Policy will apply only to information
              collected after the date of the change.
            </PolicyParagraph>
            <PolicyParagraph>
              Please check the website regularly for notices of changes to our
              Privacy Policy. If the changes are significant, or if required by
              applicable law, we will contact you (based on your selected
              preferences for communications from us) and all our registered
              users with the new details and links to the updated or changed
              policy.
            </PolicyParagraph>
            <PolicyParagraph>
              If required by law, we will get your permission or give you the
              opportunity to opt in to or opt out of, as applicable, any new
              uses of your personal information. Contact Us
            </PolicyParagraph>
            <PolicyParagraph>
              Should you have any questions about our privacy practices or this
              Privacy Policy, please email us at{' '}
              <a href="mailto:admin@220k.io">admin@220k.io.</a>
            </PolicyParagraph>
          </PolicyPageContent>
        </PolicyPageContainer>
      </PrivayPolicyWrapper>
    </AuthLayout>
  );
};
