import { styled } from 'styled-components';

export const MyOfferCardContainer = styled.div`
  background: white;
  padding: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .offer-text-block {
    padding: 12px;
    padding-top: 24px;
    color: #0e4fbc;
    h3 {
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 4px;
    }
    h4 {
      span {
        font-weight: 500;
      }
    }
  }
  .button {
    padding: 12px;
    margin: 8px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    &.accept {
      background: #16a34a;
    }
    &.reject {
      background: #dc2626;
    }
  }
`;
