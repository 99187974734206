const token = localStorage.getItem('auth');

export let socket = new WebSocket(
  `${process.env.REACT_APP_WEBSOCKET_URL}ws?token=${token}`
);

export function initializeWebSocket() {
  if (token) {
    socket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}ws?token=${token}`
    );

    socket.onopen = () => {
      console.log('websocket connected');
    };

    socket.onerror = (error: any) => {
      console.error('WebSocket error: ', error);
    };

    socket.onclose = (event) => {
      console.log('WebSocket disconnected: ', event);
    };
  }
}
