import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  ButtonGroup,
  DatePageContainer,
  DatePageTitleWrapper,
  DatesPageWrapper,
  EmptyCards,
  HideOnMobile
} from '../dates/styles';
import { Button, SellConfirmModal, Loader } from '../../../components';
import {
  CardGridSection,
  TriggerFilterSection,
  SellDateCardSection,
  ViewDateCardSection
} from '../../../modules';
import { useNavigate } from 'react-router-dom';
import { getMyNftCardTrigger } from '../../../actions/nft_card_trigger';
import {
  getMarketplaceList,
  newMarketplaceList
} from '../../../actions/marketplace_listing';
import { INftCardTrigger } from '../../../models/nft_card_trigger';
import { NftCardTriggerFilters } from '../../../models/filters';
import { DatePageContent } from '../category/styles';
import {
  useAuthContext,
  useTriggersByNameContext,
  useTriggersContext
} from '../../../context';
import { Modal } from '../../../components/Modals/Modal';
export const TriggersPage: React.FC = () => {
  const navigate = useNavigate();
  const { authContext } = useAuthContext();
  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [isView, setIsView] = useState<'view' | 'sell' | ''>('');
  const [filterModal, setFilterModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const { triggersContext } = useTriggersContext();
  const [nftCardTriggerData, setNftCardTriggerData] = useState<
    INftCardTrigger[] | null
  >(null);

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
  }, []);

  const getPageData = async () => {
    setIsLoading(true);
    const response = await getMyNftCardTrigger(null);

    if (response?.data) {
      setNftCardTriggerData(response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  const [modal, setModal] = useState(false);

  const handleSellConfirm = async (
    id: number,
    collection_id: number,
    price: number
  ) => {
    const newMarketplace = {
      nft_type_id: collection_id,
      nft_card_trigger_id: id,
      price: Math.round(price * 100)
    };
    const response = await newMarketplaceList(newMarketplace);
    if (response.success) {
      setModal(true);
      setIsView('');
    }
  };

  const handleView = (item: any) => {
    setSelectedItem(item);
    setIsView('view');
  };

  const handleCraft = (item: any) => {
    if (item?.id) navigate(`/crafting/predictions?id=${item.id}&type=trigger`);
  };

  const handleSell = (item: any) => {
    setSelectedItem(item);
    setIsView('sell');
  };

  const [filters, setFilters] = useState<NftCardTriggerFilters>({
    tiers: null,
    rarities: null,
    status: null,
    card_collection_id: [1],
    triggers: null,
    card_series_id: null
  });

  // filter option click
  const handleOptionClick = async (
    filterType: string,
    selectedOptions: string[]
  ) => {
    setIsLoadingFilter(true);

    let newFilters: NftCardTriggerFilters = {
      status: filters.status,
      triggers: filters.triggers,
      rarities: filters.rarities,
      tiers: filters.tiers,
      card_collection_id: filters.card_collection_id,
      card_series_id: null
    };

    switch (filterType) {
      case 'Trigger Tier':
        newFilters.tiers = selectedOptions.map((v) => {
          return v;
        });
        break;
      case 'Triggers':
        newFilters.triggers = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'All Rarities':
        newFilters.rarities = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'Status':
        newFilters.status = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'Collections':
        newFilters.card_collection_id = selectedOptions.map((v) => {
          return Number(v);
        });
    }

    setFilters(newFilters);

    const marketplaceListings = await getMarketplaceList({
      card_collection_id: 1,
      nft_type_ids: [3, 4],
      status: [0, 1]
    });

    let filteredListings = [];

    if (newFilters?.status?.length === 1) {
      if (newFilters.status[0] === 0) {
        filteredListings = marketplaceListings.data.filter((f: any) => {
          if (!f.is_listed) {
            return f;
          }
        });
      } else {
        filteredListings = marketplaceListings.data.filter((f: any) => {
          if (f.is_listed) {
            return f;
          }
        });
      }
    }
    console.log('filteredListings', filteredListings);

    let res = await getMyNftCardTrigger(newFilters);

    if (res?.data) {
      if (newFilters.triggers && newFilters.triggers.length > 0) {
        if (triggersContext) {
          const selectedTriggers = [];
          const matches = [];

          for (const triggerId of selectedOptions) {
            selectedTriggers.push(triggersContext.get(Number(triggerId)));
          }

          for (const selectedTrigger of selectedTriggers) {
            for (const triggerNft of res.data) {
              if (
                triggerNft.trigger?.toLowerCase() ===
                selectedTrigger.name.toLowerCase()
              ) {
                matches.push(triggerNft);
              }
            }
          }
          setNftCardTriggerData(matches as INftCardTrigger[]);
          setIsLoadingFilter(false);
          return;
        }
      }
      // if (newFilters.status?.length === 1) {
      //   const matches = [];
      //   for (const f of filteredListings) {
      //     for (const d of res.data) {
      //       if (f.nft_card_day_month_id === d.id && d.owner_id === f.owner_id) {
      //         matches.push(d);
      //       }
      //     }
      //   }
      //   setNftCardTriggerData(matches as INftCardTrigger[]);
      //   return;
      // }
      setNftCardTriggerData(res?.data as INftCardTrigger[]);
      setIsLoadingFilter(false);
    }
    return;
  };

  const getTimeStemp = (date: string) => {
    const now = new Date(date);
    const timestamp = now.getTime();
    return timestamp;
  };

  const clickSelect = async (sortSelectOption: string) => {
    setIsLoading(true);
    if (sortSelectOption == 'Date-High-Low') {
      setIsLoading(true);
      const response = await getMyNftCardTrigger(null);
      if (response?.data) {
        response?.data.sort(
          (a: any, b: any) =>
            getTimeStemp(a.created_at) - getTimeStemp(b.created_at)
        );
        setNftCardTriggerData(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Date-Low-High') {
      setIsLoading(true);
      const response = await getMyNftCardTrigger(null);
      if (response?.data) {
        response?.data.sort(
          (a: any, b: any) =>
            getTimeStemp(b.created_at) - getTimeStemp(a.created_at)
        );
        setNftCardTriggerData(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Rarity High-Low') {
      setIsLoading(true);
      const response = await getMyNftCardTrigger(null);
      if (response?.data) {
        response?.data.sort((a: any, b: any) => b.rarity - a.rarity);
        setNftCardTriggerData(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Rarity Low-High') {
      setIsLoading(true);
      const response = await getMyNftCardTrigger(null);
      if (response?.data) {
        response?.data.sort((a: any, b: any) => a.rarity - b.rarity);
        setNftCardTriggerData(response?.data);
        setIsLoading(false);
      }
    }
  };

  return (
    <AppLayout>
      <SellConfirmModal open={modal} onClose={() => setModal(false)} />
      <Modal open={filterModal} onClose={() => setFilterModal(false)}>
        <TriggerFilterSection
          filters={filters}
          onClick={handleOptionClick}
          clickSelect={clickSelect}
        />
      </Modal>
      {authContext?.isAuthenticated ? (
        <DatesPageWrapper isview={isView ? 'true' : undefined}>
          <DatePageContainer>
            <DatePageTitleWrapper>
              <h3>Triggers</h3>
            </DatePageTitleWrapper>
            <DatePageContent>
              {!isLoadingFilter &&
              nftCardTriggerData &&
              nftCardTriggerData?.length > 0 ? (
                <>
                  <ButtonGroup>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/marketplace')}
                    >
                      Buy Cards
                    </Button>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/buy')}
                    >
                      Buy Packs
                    </Button>
                    <Button
                      className="filter-button"
                      onClick={() => setFilterModal(true)}
                    >
                      Filters
                    </Button>
                  </ButtonGroup>
                  <HideOnMobile>
                    <TriggerFilterSection
                      filters={filters}
                      onClick={handleOptionClick}
                      clickSelect={clickSelect}
                    />
                  </HideOnMobile>
                  <CardGridSection
                    data={nftCardTriggerData}
                    // data={[]}
                    cardType={'trigger'}
                    onCraft={handleCraft}
                    onSell={handleSell}
                    onView={handleView}
                  />
                </>
              ) : !isLoading &&
                !isLoadingFilter &&
                nftCardTriggerData?.length == 0 ? (
                <>
                  <ButtonGroup>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/marketplace')}
                    >
                      Buy Cards
                    </Button>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/buy')}
                    >
                      Buy Packs
                    </Button>
                    <Button
                      className="filter-button"
                      onClick={() => setFilterModal(true)}
                    >
                      Filters
                    </Button>
                  </ButtonGroup>
                  <HideOnMobile>
                    <TriggerFilterSection
                      filters={filters}
                      onClick={handleOptionClick}
                      clickSelect={clickSelect}
                    />
                  </HideOnMobile>
                  <CardGridSection
                    data={nftCardTriggerData}
                    // data={[]}
                    cardType={'trigger'}
                    onCraft={handleCraft}
                    onSell={handleSell}
                    onView={handleView}
                  />
                </>
              ) : !isLoading && nftCardTriggerData == null ? (
                <EmptyCards>
                  <div className="trigeres">
                    <h3>No Triggers</h3>
                    <p>It looks like you don’t have any triggers yet.   </p>
                    <Button className="buy-button">Buy Cards</Button>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/buy')}
                    >
                      Buy Packs
                    </Button>
                  </div>
                </EmptyCards>
              ) : isLoading ? (
                <Loader />
              ) : null}
            </DatePageContent>
            {nftCardTriggerData && nftCardTriggerData?.length > 0 ? (
              <>
                <ViewDateCardSection
                  isView={isView === 'view'}
                  cardType="trigger"
                  item={selectedItem}
                  onClose={() => setIsView('')}
                />
                <SellDateCardSection
                  onSellConfirm={handleSellConfirm}
                  isView={isView === 'sell'}
                  cardType="trigger"
                  item={selectedItem}
                  onClose={() => setIsView('')}
                />
              </>
            ) : isLoadingFilter ? (
              <h1 className="setText" hidden>
                No Records Found
              </h1>
            ) : null}
          </DatePageContainer>
        </DatesPageWrapper>
      ) : (
        <EmptyCards className="login">
          <p className="login">Log in to start playing</p>
          <Button className="buy-button" onClick={() => navigate('/signin')}>
            Login Now
          </Button>
        </EmptyCards>
      )}
    </AppLayout>
  );
};
