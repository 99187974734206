// check rarity
export function checkRarity(rarity: number) {
  const rarities = ['Core', 'Uncommon', 'Rare'];
  return rarities[rarity] || 'Unknown';
}

// check tier
export function checkTier(rarity: number) {
  const rarities = ['Standard', 'Premium', 'Elite'];
  return rarities[rarity] || 'Unknown';
}

// set format category
export function formatCategory(category: string) {
  if (!category) {
    return;
  }

  const words = category.split(' ');

  const formattedCategory = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');

  return formattedCategory;
}

export function toCapitalCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
