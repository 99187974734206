import React, { useState, useEffect } from 'react';
import {
  FilterGroupWrapper,
  FilterSectionWrapper,
  SortButton,
  CardTooltip,
  TooltipContent
} from './styles';
import { SelectBox, SortIcon } from '../../../components';
import {
  useAllRaritiesContext,
  useStatusContext,
  useTriggersContext,
  useTiersContext,
  useCardCollectionContext
} from '../../../context';
import { SelectOptionProps } from '../../../typeroot/types';

export const TriggerFilterSection: React.FC<{
  onClick: (filterType: string, selectedOptions: string[]) => void;
  clickSelect: (sortSelectOption: string) => void;
  filters: any;
}> = ({ onClick, clickSelect, filters }) => {
  const { allRaritiesContext } = useAllRaritiesContext();
  const { statusContext } = useStatusContext();
  const { triggersContext } = useTriggersContext();
  const { tiersContext } = useTiersContext();
  const { cardCollectionContext } = useCardCollectionContext();
  const [optionsCollection, setOptionsCollection] = useState<
    SelectOptionProps[]
  >([]);
  const [optionsStatus, setOptionsStatus] = useState<SelectOptionProps[]>([]);
  const [optionsRarities, setOptionsRarities] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsTiers, setOptionsTiers] = useState<SelectOptionProps[]>([]);
  const [optionsTriggers, setOptionsTriggers] = useState<SelectOptionProps[]>(
    []
  );
  // console.log('optionsTriggers', optionsTriggers);
  const [sortToolTip, setOpenSortToolTip] = useState(false);
  const openSortToolTip = () => {
    setOpenSortToolTip(!sortToolTip);
  };

  useEffect(() => {
    if (
      statusContext &&
      allRaritiesContext &&
      cardCollectionContext &&
      triggersContext &&
      tiersContext
    ) {
      setOptionsStatus(
        Array.from(
          (statusContext as Map<number, { id: number; name: string }>).values()
        ).map((v) => {
          return { checked: false, value: v?.id?.toString(), label: v.name };
        })
      );

      setOptionsRarities(
        Array.from(
          (
            allRaritiesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v?.id?.toString(), label: v.name };
        })
      );

      const collection: any = [];
      if (cardCollectionContext) {
        for (const item of cardCollectionContext) {
          collection.push({
            checked: false,
            value: item?.id?.toString(),
            label: item.name
          });
        }
      }
      setOptionsCollection(collection);

      setOptionsTriggers(
        Array.from(
          (
            triggersContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v?.id?.toString(), label: v.name };
        })
      );

      setOptionsTiers(
        Array.from(
          (tiersContext as Map<number, { id: number; name: string }>).values()
        ).map((v) => {
          return { checked: false, value: v?.name, label: v?.name };
        })
      );
    }
  }, [
    statusContext,
    allRaritiesContext,
    triggersContext,
    tiersContext,
    cardCollectionContext
  ]);

  return (
    <FilterSectionWrapper>
      <p>Filter traits</p>
      <FilterGroupWrapper>
        <SelectBox
          isFilter
          filters={filters}
          type="tiers"
          placeholder="Trigger Tier"
          options={optionsTiers}
          onClick={onClick}
        />
        <SelectBox
          isFilter
          filters={filters}
          type="triggers"
          options={optionsTriggers}
          placeholder="Triggers"
          onClick={onClick}
        />
        <SelectBox
          isFilter
          filters={filters}
          options={optionsCollection}
          placeholder="Collections"
          type="card_collection_id"
          onClick={onClick}
        />
        <SelectBox
          isFilter
          filters={filters}
          type="rarities"
          options={optionsRarities}
          placeholder="All Rarities"
          onClick={onClick}
        />

        {/* <SelectBox
          isFilter
          filters={filters}
          type="status"
          options={optionsStatus}
          placeholder="Status"
          onClick={onClick}
        /> */}

        <CardTooltip className="sortTooltip">
          <SortButton onClick={openSortToolTip}>
            <SortIcon />
          </SortButton>
          {sortToolTip && (
            <TooltipContent onClick={openSortToolTip}>
              <div>
                <div className="OptionHeading">
                  <span>Sort By</span>
                </div>
                <div
                  className="sOption"
                  onClick={() => clickSelect('Rarity High-Low')}
                >
                  <span>Rarity High-Low</span>
                </div>
                <div
                  className="sOption"
                  onClick={() => clickSelect('Rarity Low-High')}
                >
                  <span>Rarity Low-High</span>
                </div>
              </div>
            </TooltipContent>
          )}
        </CardTooltip>
      </FilterGroupWrapper>
    </FilterSectionWrapper>
  );
};
