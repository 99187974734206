import React from 'react';
import {
  ClearButton,
  NotificationItemWrapper,
  NotificationTitleWrapper
} from './styles';
import { NotificationItemProps } from '../../typeroot/types';

export const NotificationItem: React.FC<NotificationItemProps> = ({
  date,
  desc,
  isNew,
  title,
  onClick
}) => {
  return (
    <NotificationItemWrapper>
      {/* {isNew && <span>New</span>} */}
      <div className="notify-img">
        <img src="/assets/notification.png" alt="" />
      </div>
      <div>
        <NotificationTitleWrapper>
          <h5>
            <b>{title}</b> <p>{date}</p>
          </h5>
        </NotificationTitleWrapper>
        <p>{desc}</p>
        <ClearButton onClick={onClick}>Mark as read</ClearButton>
      </div>
    </NotificationItemWrapper>
  );
};
