import React, { useEffect, useRef, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  CraftLeftWrapper,
  CraftRightWrapper,
  CraftingWrapper,
  SuccessfulCraftView
} from '../identities/styles';
import { PredictionMatchListSection } from '../../../modules/crafting/PredictionMatchListSection';
import { CardPreviewSection } from '../../../modules';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  CraftPredictionModal,
  PredictionCard
} from '../../../components';
import { ToastContainer, toast } from 'react-toastify';
import { craftingPrediction } from '../../../actions';
import { INftCardIdentity } from '../../../models/nft_card_identity';
import { INftCardTrigger } from '../../../models/nft_card_trigger';
import { INftCardCrafting } from '../../../models/nft_card_crafting';
import { PredictionCraftSection } from '../../../modules/crafting/PredictionCraftSection';
import { getMyNftCardCrafting } from '../../../actions/nft_card_crafting';
import { getMyNftCardTrigger } from '../../../actions/nft_card_trigger';
import { getMyNftCardIdentity } from '../../../actions/nft_card_identity';
import { useAuthContext } from '../../../context';

interface ISelectedCards {
  crafting: INftCardCrafting | null;
  identity: INftCardIdentity | null;
  triggers: Array<INftCardTrigger> | null;
}

export const CraftingPredictionsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authContext } = useAuthContext();
  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [selectedCraft, setSelectedCraft] = useState('identity');
  const [clickedCard, setClickedCard] = useState<number | string | null>(-1);
  const [selectedCard, setSelectedCard] = useState<number | string | null>(-1);
  const [selectedCards, setSelectedCards] = useState<ISelectedCards>({
    crafting: null,
    identity: null,
    triggers: null
  });
  const [clickedNft, setClickedNft] = useState<
    INftCardCrafting | INftCardIdentity | INftCardTrigger
  >();
  const [clickedCraft, setClickedCraft] = useState('identity');
  const inCrafting = useRef<boolean>(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [craftedCard, setCraftedCard] = useState<any>(null);
  const [myNfts, setMyNfts] = useState<any>();

  const [rarity, setRarity] = useState(0);
  console.log('rarity', rarity);

  useEffect(() => {
    const { identity, crafting, triggers } = selectedCards;
    if (
      typeof identity?.rarity === 'number' &&
      typeof crafting?.rarity === 'number' &&
      triggers
    ) {
      let triggerRarities = 0;

      for (const t of triggers) {
        triggerRarities += t.rarity!;
      }
      console.log('triggers', triggers);
      console.log('triggerRarities', triggerRarities);
      console.log('crafting?.rarity', crafting?.rarity);
      console.log('identity?.rarity', identity?.rarity);

      const temp = Math.floor(
        (triggerRarities + crafting?.rarity + identity?.rarity) /
          (2 + triggers.length)
      );
      setRarity(temp);
    }
  }, [selectedCards]);

  const handleVideoEnded = () => {
    setPlayVideo(false);
  };

  const getNFTCrafting = async () => {
    const craftingRes = await getMyNftCardCrafting(null);

    const triggerRes = await getMyNftCardTrigger(null);

    const identityRes = await getMyNftCardIdentity(null);

    if (craftingRes.data && triggerRes.data && identityRes.data) {
      setMyNfts({
        crafting: craftingRes.data,
        trigger: triggerRes.data,
        identity: identityRes.data
      });
    }
  };

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
  }, []);

  useEffect(() => {
    getNFTCrafting();
  }, []);

  useEffect(() => {
    if (!myNfts) return;
    const params = new URLSearchParams(location.search);

    if (
      params.get('id') &&
      params.get('type') &&
      myNfts.crafting &&
      myNfts.trigger &&
      myNfts.identity
    ) {
      const allNfts: any[] = [
        ...myNfts.crafting,
        ...myNfts.trigger,
        ...myNfts.identity
      ];
      const id = params.get('id');
      const type = params.get('type');

      for (const nft of allNfts) {
        if (Number(id) === nft.id) {
          if (type === 'crafting') {
            setSelectedCards((prev) => ({ ...prev, crafting: nft }));
            setSelectedCard(nft.id);
            return;
          } else if (type === 'trigger') {
            setSelectedCards((prev) => ({ ...prev, triggers: [nft] }));
            setSelectedCard(nft.id);
            return;
          } else if (type === 'identity') {
            setSelectedCards((prev) => ({ ...prev, identity: nft }));
            setSelectedCard(nft.id);
            return;
          } else return;
        }
      }
    }
  }, [location.search, myNfts]);

  const handleCardClick = (
    key: string | number | null,
    item: INftCardCrafting | INftCardIdentity | INftCardTrigger
  ) => {
    if (key === clickedCard) {
      setClickedCard(-1);
      setClickedNft(undefined);
    } else {
      setClickedCard(key);
      setClickedNft(item);
      setClickedCraft(selectedCraft);
    }
  };

  const handleCardSelected = (id: string | number | null, craft: string) => {
    // if (craft === "trigger" && selectedCards.trigger != null) {
    //   setSelectedCards((prev) => ({ ...prev, trigger: [...prev.trigger, id] }));
    // } else {
    //   setSelectedCards((prev) => ({ ...prev, [craft]: id }));
    // }
    // setSelectedCard(id);
  };

  const handleSelectCardCrafting = (card: INftCardCrafting) => {
    setSelectedCards((prev) => ({
      triggers: prev.triggers,
      crafting: card,
      identity: prev.identity
    }));
    if (card.id) setSelectedCard(card.id);
  };

  const handleSelectCardIdentity = (card: INftCardIdentity) => {
    setSelectedCards((prev) => ({
      triggers: prev.triggers,
      crafting: prev.crafting,
      identity: card
    }));
    if (card.id) setSelectedCard(card.id);
  };

  const handleSelectCardTrigger = (card: INftCardTrigger) => {
    let tr = selectedCards.triggers;
    if (
      tr &&
      tr.length > 0 &&
      tr.findIndex((value) => value.id === card.id) !== -1
    )
      return;
    if (tr) {
      tr.push(card);
    } else {
      tr = [card];
    }

    setSelectedCards((prev) => ({
      triggers: tr,
      crafting: prev.crafting,
      identity: prev.identity
    }));
    if (card.id) setSelectedCard(card.id);
  };

  const handleCraft = () => {
    setCraftPopup(true);
    // craftPrediction();
  };

  const craftPrediction = async () => {
    if (inCrafting.current) {
      return;
    }
    if (selectedCards.identity === null) {
      toast.error('Select an Identity card');
      return;
    }

    if (selectedCards.triggers === null || selectedCards.triggers.length < 1) {
      toast.error('Select atleast one Trigger card');
      return;
    }

    if (selectedCards.crafting === null) {
      toast.error('Select a Crafting card');
      return;
    }

    let trigger_ids: number[] = [];

    selectedCards.triggers.forEach((v) => {
      if (v.id) {
        trigger_ids.push(v.id);
      }
    });

    const newCraft = {
      nft_card_trigger_ids: trigger_ids,
      nft_card_identity_id: Number(selectedCards.identity.id),
      nft_card_crafting_id: Number(selectedCards.crafting?.id)
    };
    inCrafting.current = true;
    const res = await craftingPrediction(newCraft);
    if (res.success) {
      toast.success('Crafted Successfully.');
      closePopup();

      setCraftedCard(res.data);
      setRarity(res.data?.rarity || 0);
      setPlayVideo(true);
      return;
    } else {
      toast.error(res.message);
    }
    inCrafting.current = false;
  };

  const [craftPopup, setCraftPopup] = useState<boolean>(false);

  const closePopup = () => {
    setCraftPopup(false);
  };

  return (
    <AppLayout noFooter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <CraftPredictionModal
        open={craftPopup}
        onClose={closePopup}
        onBurn={craftPrediction}
        selectedCards={selectedCards}
        rarity={rarity}
      />
      {playVideo ? (
        <video
          width="100%"
          height="100%"
          autoPlay
          playsInline
          onEnded={handleVideoEnded}
        >
          {rarity === 0 && (
            <>
              <source
                src={'/assets/videos/prediction-core.webm'}
                type="video/webm"
              />
            </>
          )}
          {rarity === 1 && (
            <>
              <source
                src={'/assets/videos/prediction-uncommon.webm'}
                type="video/webm"
              />
            </>
          )}
          {rarity === 2 && (
            <>
              <source
                src={'/assets/videos/prediction-rare.webm'}
                type="video/webm"
              />
            </>
          )}
        </video>
      ) : (
        <>
          {craftedCard ? (
            <SuccessfulCraftView>
              <h2>Craft Successful</h2>
              <div className="card-container">
                <PredictionCard
                  day={craftedCard?.nft_identity?.day}
                  month={craftedCard?.nft_identity?.month}
                  category={craftedCard?.nft_identity?.category}
                  rarity={craftedCard?.rarity}
                  year={craftedCard?.nft_identity?.year}
                  icon={craftedCard?.icon}
                  iconText={craftedCard?.iconText}
                  celebrity_name={craftedCard?.nft_identity?.celebrity_name}
                  image={craftedCard?.image}
                  item={craftedCard}
                  cardType="prediction"
                />
              </div>
              <div className="button-wrapper" style={{ paddingTop: '12px' }}>
                <Button
                  className="button"
                  onClick={() => navigate('/dashboard/predictions')}
                >
                  See Inventory
                </Button>
                <Button
                  className="button"
                  onClick={() => {
                    setCraftedCard(null);
                    navigate(0);
                  }}
                >
                  Craft Again
                </Button>
              </div>
            </SuccessfulCraftView>
          ) : (
            <CraftingWrapper>
              {authContext?.isAuthenticated ? (
                <>
                  <CraftLeftWrapper>
                    <PredictionCraftSection
                      onCraft={handleCraft}
                      onCraftChanged={setSelectedCraft}
                      selectedCards={selectedCards}
                      selectedCraft={selectedCraft}
                      selectedCard={selectedCard}
                      clickedCard={clickedCard}
                      onCardClicked={handleCardClick}
                      onSelectCardCrafting={handleSelectCardCrafting}
                      onSelectCardIdentity={handleSelectCardIdentity}
                      onSelectCardTrigger={handleSelectCardTrigger}
                    />
                    {/* <PredictionSelectCardSection
                selectedCard={selectedCard}
                clickedCard={clickedCard}
                selectedCraft={selectedCraft}
                onCardClicked={handleCardClick}
                onSelectCardCrafting={handleSelectCardCrafting}
                onSelectCardIdentity={handleSelectCardIdentity}
                onSelectCardTrigger={handleSelectCardTrigger}
              /> */}
                  </CraftLeftWrapper>
                  <CraftRightWrapper>
                    <PredictionMatchListSection selectedCards={selectedCards} />
                    <CardPreviewSection
                      page="prediction"
                      clickedCard={clickedCard}
                      selectedCraft={clickedCraft}
                      clickedNft={clickedNft}
                      myNfts={myNfts}
                    />
                  </CraftRightWrapper>
                </>
              ) : (
                <>
                  <div className="unAuth-display">
                    <p>
                      Identities are cards crafted by combining an Identity with
                      at least one trigger.
                    </p>
                    <h4>Log in to start playing.</h4>
                    <Button
                      className="login-button"
                      onClick={() => navigate('/signin')}
                    >
                      Login Now
                    </Button>
                  </div>
                </>
              )}
            </CraftingWrapper>
          )}
        </>
      )}
    </AppLayout>
  );
};
