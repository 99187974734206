import React from 'react';
import { BuyCardWrapper, CardAction } from './styles';
import { Button } from '../Button';
import { BuyPackProps } from '../../typeroot/types';
import { CardImgWrapper, Rarity } from '../MarketCard/styles';
import { Loader } from '../Loader';
import { toCapitalCase } from '../../utils/helperFunctions';

export const BuyCard: React.FC<BuyPackProps> = ({
  cardType,
  preview,
  price,
  onCardClick,
  cardSeries,
  rarity
}) => {
  return (
    <BuyCardWrapper onClick={() => onCardClick(cardSeries)}>
      <CardImgWrapper>
        {cardType ? (
          <>
            <img
              src={`/assets/nfts/rarity/${toCapitalCase(
                cardType
              )}-Pack-Background.png`}
              alt="Card Pack"
            />
            <div className="info-nft info-nft-cardPack">
              <img
                src={`/assets/nfts/rarity/${toCapitalCase(
                  cardType
                )}-Egg-Animation-No-Bkgnd-Small.gif`}
                alt="gif"
              />
            </div>
            <Rarity>{cardType}</Rarity>
          </>
        ) : (
          <Loader />
        )}
      </CardImgWrapper>
      {!preview && (
        <CardAction>
          <div>
            <p>Price</p>
            <h3>${(cardSeries.cost_usd / 100).toFixed(2)} USD</h3>
          </div>
          <Button
            className="buy-button"
            onClick={() => onCardClick(cardSeries)}
          >
            Buy Now
          </Button>
        </CardAction>
      )}
    </BuyCardWrapper>
  );
};
