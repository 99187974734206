import React, { useState, useEffect } from 'react';
import {
  FilterGroupWrapper,
  FilterSectionWrapper,
  SortButton,
  CardTooltip,
  TooltipContent,
  TooltipItem
} from './styles';
import {
  IconSortOption,
  IconSort,
  SelectBox,
  SortIcon
} from '../../../components';

import {
  useAllRaritiesContext,
  useCardCollectionContext,
  usePackTypesContext,
  useStatusContext
} from '../../../context';
import { SelectOptionProps, collectionOption } from '../../../typeroot/types';

export const CardPackFilterSection: React.FC<{
  onClick: (filterType: string, selectedOptions: string[]) => void;
  clickSelect: (sortSelectOption: string) => void;
  filters?: any;
}> = ({ onClick, clickSelect, filters }) => {
  const { allRaritiesContext } = useAllRaritiesContext();
  const { statusContext } = useStatusContext();
  const { packTypesContext } = usePackTypesContext();
  const { cardCollectionContext } = useCardCollectionContext();
  const [optionsStatus, setOptionsStatus] = useState<SelectOptionProps[]>([]);
  const [optionsPackTypes, setOptionsPackTypes] = useState<SelectOptionProps[]>(
    []
    );
  const [optionsRarities, setOptionsRarities] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsCollection, setOptionsCollection] = useState<
    SelectOptionProps[]
  >([]);

  const [sortToolTip, setOpenSortToolTip] = useState(false);
  const openSortToolTip = () => {
    setOpenSortToolTip(!sortToolTip);
  };

  useEffect(() => {
    if (statusContext && allRaritiesContext && collectionOption) {
      setOptionsStatus(
        Array.from(
          (statusContext as Map<number, { id: number; name: string }>).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );
      setOptionsPackTypes(
        Array.from(
          (packTypesContext as Map<number, { id: number; name: string }>).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );

      setOptionsRarities(
        Array.from(
          (
            allRaritiesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );
      const collection: any = [];
      if (cardCollectionContext) {
        for (const item of cardCollectionContext) {
          collection.push({
            checked: false,
            value: item?.id?.toString(),
            label: item.name
          });
        }
      }
      setOptionsCollection(collection);
    }
  }, [statusContext, allRaritiesContext, collectionOption]);

  return (
    <FilterSectionWrapper>
      <p>Filter traits</p>
      <FilterGroupWrapper>
        <SelectBox
          isFilter
          options={optionsPackTypes}
          placeholder="Pack Types"
          onClick={onClick}
          filters={filters}
        />

        <SelectBox
          isFilter
          options={optionsCollection}
          placeholder="Collections"
          type="card_collection_id"
          onClick={onClick}
          filters={filters}
        />

        {/* <SelectBox
          isFilter
          options={optionsStatus}
          placeholder="Status"
          onClick={onClick}
          filters={filters}
        /> */}
        <CardTooltip className="sortTooltip">
          <SortButton onClick={openSortToolTip}>
            <SortIcon />
          </SortButton>
          {sortToolTip && (
            <TooltipContent onClick={openSortToolTip}>
              <div>
                <div className="OptionHeading">
                  <span>Sort By</span>
                </div>
                <div
                  className="sOption"
                  onClick={() => clickSelect('Rarity High-Low')}
                >
                  <span>Rarity High-Low</span>
                </div>
                <div
                  className="sOption"
                  onClick={() => clickSelect('Rarity Low-High')}
                >
                  <span>Rarity Low-High</span>
                </div>
              </div>
            </TooltipContent>
          )}
        </CardTooltip>
      </FilterGroupWrapper>
    </FilterSectionWrapper>
  );
};
