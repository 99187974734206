import React from 'react';
import { IconArrowUp } from '../../../../components';
import CardTypes from './CollectionParameters/CardTypes';
import CardRarities from './CollectionParameters/CardRarities';
import CardPackValue from './CollectionParameters/CardPackValue';
import TriggerPrizePool from './CollectionParameters/TriggerPrizePool';
import Standard from './CardRarityProbability/Standard';
import Premium from './CardRarityProbability/Premium';
import Elite from './CardRarityProbability/Elite';

import {
  CDContainerWrapper,
  CDHead,
  CDCollectionParameterWrapper,
  FooterButtons
} from './styles';
import { ICardCollection } from '../../../../models/collection';
import api from '../../../../config/api';

const CollectionDraftSections: React.FC<{
  collection: ICardCollection;
  onChange: (data: ICardCollection) => void;
}> = ({ onChange, collection }) => {
  console.log('COLLECTION--->', collection);

  const submit = async () => {
    try {
      // Construct the correct URL with the collection ID
      const url = `/card_collection/${collection.id}/mint_collection`;

      // Make the API call
      const response = await api.post(url);

      // Handle the response (you can modify this based on your needs)
      console.log('Response:', response.data);

      // Optionally, call onChange or perform other state updates
      onChange(response.data);

      // Notify the user of success
      alert('Collection minted successfully!');
    } catch (error) {
      // Handle any errors
      console.error('Error minting collection:', error);
      alert('Failed to mint collection. Please try again.');
    }
  };

  return (
    <CDContainerWrapper>
      <CDCollectionParameterWrapper>
        <CDHead>
          <h3>Collection Parameters</h3>
          <IconArrowUp />
        </CDHead>
        <div className="cards">
          <CardTypes collection={collection} />
          <CardRarities collection={collection} />
        </div>
        <CardPackValue collection={collection} onChange={onChange} />
        <TriggerPrizePool collection={collection} />

        <CDHead>
          <h3>Card rarity probability per pack </h3>
          <IconArrowUp />
        </CDHead>
        <Standard collection={collection} />
        {/* <Premium collection={collection} />
        <Elite collection={collection} /> */}
      </CDCollectionParameterWrapper>
      <FooterButtons>
        <button
          className="btn-submit"
          onClick={() => {
            submit();
          }}
        >
          Submit
        </button>
        <button className="btn-save" onClick={() => {}}>
          Save as Draft
        </button>
        <button className="btn-discard" onClick={() => {
          api.post(`/card_collection/${collection.id}/delete`)
        }}>
          Discard
        </button>
      </FooterButtons>
    </CDContainerWrapper>
  );
};

export default CollectionDraftSections;
