import React, { useEffect, useState, useMemo } from 'react';
import {
  CardGridWrapper,
  FilterWrapper,
  SelectBoxWrapper,
  SelectCardSectionContainer,
  SelectCardSectionWrapper
} from './styles';
import {
  Button,
  IconSort,
  SelectBox,
  Loader,
  IdentityCard,
  SortIcon
} from '../../components';
import { SortButton } from '../app/dates/styles';
import {
  useStatusContext,
  useAllRaritiesContext,
  useCelebritiesContext,
  useCardCollectionContext
} from '../../context';
import { EmptyCards } from '../../pages/app/category/styles';
import { useNavigate } from 'react-router-dom';

import { getMyNftCardCrafting } from '../../actions/nft_card_crafting';
import { getMyNftCardIdentity } from '../../actions/nft_card_identity';
import { getMyNftCardTrigger } from '../../actions/nft_card_trigger';
import { INftCardIdentity } from '../../models/nft_card_identity';
import { INftCardTrigger } from '../../models/nft_card_trigger';
import { INftCardCrafting } from '../../models/nft_card_crafting';
import { SelectOptionProps } from '../../typeroot/types';
import { updateMyNftCardIdentity } from '../../actions/nft_card_identity';
import { ICelebrity } from '../../models/celebrity';
import {
  NftCardCraftingFilters,
  NftCardIdentityFilters,
  NftCardTriggerFilters
} from '../../models/filters';
import { DateCardWrapper } from '../../components/DateCard/styles';
import { CardImgWrapper } from '../../components/MarketCard/styles';
import { CardBottomWrapper } from '../../components/IdentityCard/styles';
import {
  CardButton,
  CardButtonGroup,
  CardOverlayWrapper
} from '../../components/CraftingCard/styles';
import { checkRarity } from '../../utils/helperFunctions';
import { CardTooltip, TooltipContent } from '../marketplace/styles';

export const PredictionSelectCardSection: React.FC<{
  selectedCraft: string;
  clickedCard: number | string | null;
  selectedCard: number | string | null;
  onCardClicked: (
    key: number | string,
    item: INftCardCrafting | INftCardIdentity | INftCardTrigger
  ) => void;
  onSelectCardCrafting: (card: INftCardCrafting) => void;
  onSelectCardTrigger: (card: INftCardTrigger) => void;
  onSelectCardIdentity: (card: INftCardIdentity) => void;
}> = ({
  selectedCraft,
  clickedCard,
  selectedCard,
  onCardClicked,
  onSelectCardCrafting,
  onSelectCardIdentity,
  onSelectCardTrigger
}) => {
  const navigate = useNavigate();
  const { statusContext } = useStatusContext();
  const { allRaritiesContext } = useAllRaritiesContext();
  const { celebritiesContext } = useCelebritiesContext();
  const { cardCollectionContext } = useCardCollectionContext();
  const [isLoadingCrafting, setIsLoadingCrafting] = useState(true);
  const [isLoadingIdentity, setIsLoadingIdentity] = useState(true);
  const [isLoadingTrigger, setIsLoadingTrigger] = useState(true);
  const [sortToolTip, setSortToolTip] = useState(false);

  const [nftCardCraftingData, setNftCardCraftingData] = useState<
    INftCardCrafting[] | null
  >(null);
  const [nftCardIdentityData, setNftCardIdentityData] = useState<
    INftCardIdentity[] | null
  >(null);
  const [nftCardTriggerData, setNftCardTriggerData] = useState<
    INftCardTrigger[] | null
  >(null);
  const [rarities, setRarity] = useState<number[]>([]);
  const [status, setStatus] = useState<number[]>([]);
  const [collection, setCollection] = useState<number[]>([1]);

  const filter = useMemo(() => {
    return {
      rarities,
      card_collection_id: collection
    };
  }, [rarities, collection]);

  const getNFTCrafting = async () => {
    setIsLoadingCrafting(true);
    setIsLoadingIdentity(true);
    setIsLoadingTrigger(true);

    if (selectedCraft === 'crafting') {
      const response = await getMyNftCardCrafting(
        filter as NftCardCraftingFilters
      );
      if (response.data) {
        setNftCardCraftingData(response.data);
      }
      setIsLoadingCrafting(false);
    } else if (selectedCraft === 'trigger') {
      const response = await getMyNftCardTrigger(
        filter as NftCardTriggerFilters
      );
      if (response.data) {
        setNftCardTriggerData(response.data);
      }
      setIsLoadingIdentity(false);
    } else if (selectedCraft === 'identity') {
      const response = await getMyNftCardIdentity(
        filter as NftCardIdentityFilters
      );
      if (response.data) {
        setNftCardIdentityData(response.data);
      }
      setIsLoadingTrigger(false);
    }
  };

  useEffect(() => {
    setRarity([]);
    setCollection([1]);
  }, [selectedCraft]);

  useEffect(() => {
    getNFTCrafting();
  }, [filter]);

  const chooseCelebrity = async (v: SelectOptionProps) => {
    let c = (celebritiesContext as Map<number, ICelebrity>).get(
      Number(v.value)
    );

    if (c && selectedCard) {
      let res = await updateMyNftCardIdentity(Number(selectedCard), c?.id);
      if (res?.data && Array.isArray(res.data)) {
        console.log(res?.data);
      }
    }
  };

  const [optionsStatus, setOptionsStatus] = useState<SelectOptionProps[]>([]);
  const [optionsRarities, setOptionsRarities] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsCollection, setOptionsCollection] = useState<
    SelectOptionProps[]
  >([]);
  const [optionsTriggers, setOptionsTriggers] = useState<SelectOptionProps[]>(
    []
  );
  const [optionsTiers, setOptionsTiers] = useState<SelectOptionProps[]>([]);
  const [optionsCategories, setOptionsCategories] = useState<
    SelectOptionProps[]
  >([]);

  useEffect(() => {
    if (statusContext && cardCollectionContext) {
      const collection: any = [];
      if (cardCollectionContext) {
        for (const item of cardCollectionContext) {
          collection.push({
            checked: false,
            value: item?.id?.toString(),
            label: item.name
          });
        }
      }
      setOptionsCollection(collection);

      setOptionsRarities(
        Array.from(
          (
            allRaritiesContext as Map<number, { id: number; name: string }>
          ).values()
        ).map((v) => {
          return { checked: false, value: v.id.toString(), label: v.name };
        })
      );
    }
  }, [cardCollectionContext, allRaritiesContext]);

  const handleClick = (filterType: string, selectedOptions: string[]) => {
    if (filterType === 'All Rarities')
      setRarity(selectedOptions.map((v) => Number(v)));
    if (filterType === 'Status')
      setStatus(selectedOptions.map((v) => Number(v)));
    if (filterType === 'Collections')
      setCollection(selectedOptions.map((v) => Number(v)));
  };

  const sortListingsByRarity = (
    updateFn: any,
    listings: any,
    type: string,
    order: string
  ) => {
    const sortedListings = [...listings].sort((a, b) => {
      if (order === 'asc') {
        return b.rarity - a.rarity;
      } else {
        return a.rarity - b.rarity;
      }
    });

    return updateFn(sortedListings);
  };

  const sortListingsByDate = (
    updateFn: any,
    listings: any,
    type: string,
    order: string
  ) => {
    let sortedListings = [...listings];
    if (order === 'desc') {
      sortedListings = [...listings].sort((a, b) => {
        if (a.month === b.month) return a.day - b.day;
        if (a.year === b.year) return a.month - b.month;
        return a.year - b.year;
      });
    } else {
      sortedListings = [...listings].sort((a, b) => {
        if (a.month === b.month) return b.day - a.day;
        if (a.year === b.year) return b.month - a.month;
        return b.year - a.year;
      });
    }

    return updateFn(sortedListings);
  };

  return (
    <SelectCardSectionWrapper>
      {selectedCraft === 'crafting' && (
        <SelectCardSectionContainer>
          {nftCardCraftingData != null ? (
            <>
              <h2>Select a Crafting card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardCraftingData,
                              nftCardCraftingData,
                              'crafting',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardCraftingData,
                              nftCardCraftingData,
                              'crafting',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {nftCardCraftingData.map((item) => (
                  <DateCardWrapper key={`craft-${item.id}`}>
                    <CardImgWrapper>
                      {item.rarity || item.rarity === 0 ? (
                        <img
                          src={`/assets/nfts/rarity/Crafting-${checkRarity(
                            item?.rarity
                          )}-copy.png`}
                          alt="nft"
                        />
                      ) : (
                        <Loader />
                      )}
                    </CardImgWrapper>
                    <CardBottomWrapper>Crafting</CardBottomWrapper>

                    <CardOverlayWrapper
                      className="overlay"
                      onClick={() => onCardClicked(Number(item.id), item)}
                    >
                      <CardButtonGroup>
                        <CardButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            onSelectCardCrafting(item);
                            onCardClicked &&
                              item &&
                              onCardClicked(Number(item.id), item);
                          }}
                        >
                          Select
                        </CardButton>
                      </CardButtonGroup>
                    </CardOverlayWrapper>

                    <CardOverlayWrapper />
                  </DateCardWrapper>
                ))}
              </CardGridWrapper>
            </>
          ) : !isLoadingCrafting ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>
              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
      {selectedCraft === 'identity' && (
        <SelectCardSectionContainer>
          {nftCardIdentityData != null ? (
            <>
              <h2>Select an Identity card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardIdentityData,
                              nftCardIdentityData,
                              'crafting',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardIdentityData,
                              nftCardIdentityData,
                              'identity',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(
                              setNftCardIdentityData,
                              nftCardIdentityData,
                              'identity',
                              'asc'
                            )
                          }
                        >
                          <span>Date High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByDate(
                              setNftCardIdentityData,
                              nftCardIdentityData,
                              'identity',
                              'desc'
                            )
                          }
                        >
                          <span>Date Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {nftCardIdentityData.map((item) => (
                  <IdentityCard
                    cardType="identity"
                    height={293}
                    isNotHover={true}
                    key={`identity-${item.id}`}
                    item={item}
                    {...item}
                    forCraft={true}
                    onCardClicked={onCardClicked}
                    onSelectCardIdentity={onSelectCardIdentity}
                  />
                ))}
              </CardGridWrapper>
            </>
          ) : !isLoadingIdentity ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>

              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
      {selectedCraft === 'trigger' && (
        <SelectCardSectionContainer>
          {nftCardTriggerData != null ? (
            <>
              <h2>Select a Trigger card</h2>
              <FilterWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    options={optionsRarities}
                    placeholder="All Rarities"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <SelectBoxWrapper>
                  <SelectBox
                    isFilter
                    type="card_collection_id"
                    options={optionsCollection}
                    placeholder="Collections"
                    onClick={handleClick}
                  />
                </SelectBoxWrapper>
                <CardTooltip className="sortTooltip">
                  <SortButton onClick={() => setSortToolTip(!sortToolTip)}>
                    <SortIcon />
                  </SortButton>
                  {sortToolTip && (
                    <TooltipContent
                      onClick={() => setSortToolTip(!sortToolTip)}
                    >
                      <div>
                        <div className="OptionHeading">
                          <span>Sort By</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardTriggerData,
                              nftCardTriggerData,
                              'trigger',
                              'asc'
                            )
                          }
                        >
                          <span>Rarity High-Low</span>
                        </div>
                        <div
                          className="sOption"
                          onClick={() =>
                            sortListingsByRarity(
                              setNftCardTriggerData,
                              nftCardTriggerData,
                              'trigger',
                              'desc'
                            )
                          }
                        >
                          <span>Rarity Low-High</span>
                        </div>
                      </div>
                    </TooltipContent>
                  )}
                </CardTooltip>
              </FilterWrapper>
              <CardGridWrapper>
                {nftCardTriggerData.map((item, key) => (
                  <DateCardWrapper key={`trigger-${item.id}`}>
                    <CardImgWrapper>
                      {item.rarity || item.rarity === 0 ? (
                        <img
                          src={`/assets/nfts/rarity/Trigger-${checkRarity(
                            item?.rarity
                          )}-No-Text.png`}
                          alt="nft"
                        />
                      ) : (
                        <Loader />
                      )}
                      <div className="info-nft info-nft-trigger">
                        <h4
                          style={{ height: '12px', width: '110%', marginRight: "10px" }}
                          className={`rarity-${item.rarity}`}
                        >
                          {item.trigger}
                        </h4>
                      </div>
                    </CardImgWrapper>
                    <CardBottomWrapper>{item.tier}</CardBottomWrapper>
                    <CardOverlayWrapper
                      className="overlay"
                      onClick={() => onCardClicked(Number(item.id), item)}
                    >
                      <CardButtonGroup>
                        <CardButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            onSelectCardTrigger(item);
                            onCardClicked &&
                              item &&
                              onCardClicked(Number(item.id), item);
                          }}
                        >
                          Select
                        </CardButton>
                      </CardButtonGroup>
                    </CardOverlayWrapper>
                  </DateCardWrapper>
                ))}
              </CardGridWrapper>
            </>
          ) : !isLoadingTrigger ? (
            <EmptyCards>
              <h3>No {selectedCraft} Cards</h3>

              <p style={{ maxWidth: '243px' }}>
                It looks like you don’t have any{' '}
                <span className="capitalize">{selectedCraft}</span> cards yet.
              </p>
              <Button className="buy-button" onClick={() => navigate('/buy')}>
                Buy Packs
              </Button>
              <Button
                className="buy-button"
                onClick={() => navigate('/marketplace')}
              >
                Go to Marketplace
              </Button>
            </EmptyCards>
          ) : (
            <Loader />
          )}
        </SelectCardSectionContainer>
      )}
    </SelectCardSectionWrapper>
  );
};
