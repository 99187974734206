import React, { useEffect, useState } from 'react';
import { DateCardProps } from '../../typeroot/types';
import {
  CardButton,
  CardButtonGroup,
  CardOverlayWrapper,
  CardTooltip,
  DateCardWrapper,
  TooltipContent,
  TooltipItem
} from './styles';
import { CardBottomWrapper } from '../PredictionCard/styles';
import { IconUser2 } from '../Icons';
import {
  useMonthContext,
  useMyInfoContext,
  useCelebritiesContext
} from '../../context';
import { CardImgWrapper } from '../MarketCard/styles';
import { checkRarity } from '../../utils/helperFunctions';
import { ICelebrity } from '../../models/celebrity';

export const getImagePath = (
  itemYear: any,
  rarity: number,
  category: string,
  isYear: boolean
) => {
  const raritySuffix =
    rarity === 0 ? 'Core' : rarity === 1 ? 'Uncommon' : 'Rare';
  const dateType = isYear ? 'Year' : 'Month-Day';
  return `/assets/nfts/rarity/${
    itemYear ? dateType : category
  }-${raritySuffix}-copy.png`;
};

export const DateCard: React.FC<DateCardProps> = ({
  item,
  day,
  month,
  position = '',
  is_crafted,
  owner_id,
  rarity,
  isNotHover,
  onCraft,
  onSell,
  onView,
  buttonText,
  textSize
}) => {
  const { monthContext } = useMonthContext();
  const { myInfoContext } = useMyInfoContext();
  const isYear = Boolean(item?.year);
  const imagePath = getImagePath(item?.year, rarity, 'Month-Day', isYear);
  const { celebritiesContext } = useCelebritiesContext();

  const [identityMatches, setIdentityMatches] = useState<ICelebrity[]>([]);

  useEffect(() => {
    if (celebritiesContext) {
      if (day && month) {
        setIdentityMatches(
          Array.from(
            (celebritiesContext as Map<number, ICelebrity>).values()
          ).filter(
            (v: ICelebrity) => v.birth_day === day && v.birth_month === month
          )
        );
      }
      if (isYear) {
        setIdentityMatches(
          Array.from(
            (celebritiesContext as Map<number, ICelebrity>).values()
          ).filter((v: ICelebrity) => v.birth_year === item?.year)
        );
      }
    }
  }, [celebritiesContext, day, month, isYear, item?.year]);

  return (
    item?.id && (
      <DateCardWrapper
        key={item.id}
        isnothover={isNotHover ? 'true' : undefined}
      >
        <CardImgWrapper>
          <img src={imagePath} alt="nft" />
          <div
            className="info-nft info-nft-day-month"
            style={{
              fontSize: textSize || '3rem'
            }}
          >
            {day && monthContext && (
              <h3 className={checkRarity(rarity)}>
                {String(month).padStart(2, '0')}.{String(day).padStart(2, '0')}
              </h3>
            )}
            {item?.year && (
              <h3 className={checkRarity(rarity)}>{item?.year}</h3>
            )}
          </div>
        </CardImgWrapper>

        {buttonText && buttonText !== undefined && buttonText !== '' ? (
          <CardBottomWrapper>{buttonText}</CardBottomWrapper>
        ) : (
          day &&
          monthContext && (
            <CardBottomWrapper>
              {(monthContext as Map<number, string>).get(month)} {day}
            </CardBottomWrapper>
          )
        )}

        {item?.year && <CardBottomWrapper>{item?.year}</CardBottomWrapper>}
        <CardOverlayWrapper className="overlay">
          <CardButtonGroup>
            {buttonText !== 'Crafting' && (
              <CardTooltip>
                <div className="bg-black">
                  <IconUser2 />
                </div>
                <TooltipContent position={position} className="tooltip-content">
                  <div>
                    <h3>Identity Matches</h3>
                    {identityMatches &&
                      identityMatches.map((v) => (
                        <TooltipItem key={v.name}>{v.name}</TooltipItem>
                      ))}
                  </div>
                </TooltipContent>
              </CardTooltip>
            )}
            {onView && (
              <CardButton onClick={() => onView(item)}>View</CardButton>
            )}
            {onCraft && (
              <CardButton onClick={() => onCraft(item)}>
                Craft Identity
              </CardButton>
            )}
            {item?.owner_id === myInfoContext?.id && onSell && (
              <CardButton onClick={() => onSell(item)}>Sell</CardButton>
            )}
          </CardButtonGroup>
        </CardOverlayWrapper>
      </DateCardWrapper>
    )
  );
};
