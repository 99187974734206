import React, { useEffect, useState } from 'react';
import { CraftPredictionModalProps } from '../../typeroot/types';
import { Modal as ModalWrapper } from './Modal';
import {
  ButtonGroup,
  Checkbox,
  CheckboxWrapper,
  CraftPredictionModalWrapper,
  PredictionSelectedCardWrapper,
  RarityTextBox,
  SelectedCardWrapper,
  SelectedCraftCard,
  SelectedCraftCardWrapper
} from './styles';
import { Button } from '../Button';
import { useMonthContext } from '../../context';
import {
  formatCategory,
  checkRarity,
  toCapitalCase
} from '../../utils/helperFunctions';

const Card = ({ cardType, selectedCard, heading }: any) => {
  const { monthContext } = useMonthContext();
  return (
    <SelectedCraftCardWrapper key={cardType}>
      <h6>{heading}</h6>

      <SelectedCraftCard className="crafting-card">
        {cardType === 'identity' ? (
          <img
            src={`/assets/nfts/rarity/Identity-Card-Blank-${checkRarity(
              selectedCard?.rarity
            )}.png`}
            alt="nft"
          />
        ) : cardType === 'trigger' ? (
          <>
            <img
              src={`/assets/nfts/rarity/Trigger-${checkRarity(
                selectedCard?.rarity
              )}-No-Text.png`}
              alt="nft"
            />
            <p
              className="trigger-description"
              style={{
                bottom: '40px',
                background: 'none',
                fontSize: '10px',
                fontWeight: 600,
                padding: '0 10px'
              }}
            >
              {selectedCard?.trigger}
            </p>
          </>
        ) : (
          <img
            src={`/assets/nfts/rarity/${toCapitalCase(cardType)}-${checkRarity(
              selectedCard?.rarity
            )}-copy.png`}
            alt="nft"
          />
        )}
        <div className="info-nft info-nft-identity" style={{ height: '56%' }}>
          {cardType === 'identity' && (
            <div className="nft-info-detail">
              <img
                className="identity-torso"
                src={`/assets/nfts/rarity/${checkRarity(
                  selectedCard?.rarity
                )}-Torso.gif`}
                alt="gif"
                style={{ maxWidth: '120px' }}
              />
              <h4
                className={`identity-info date ${checkRarity(
                  selectedCard?.rarity
                )}`}
                style={{ fontSize: '14px' }}
              >
                {String(selectedCard?.month).padStart(2, '0')}.
                {String(selectedCard?.day).padStart(2, '0')}.
                {selectedCard?.year}
              </h4>
              <h4
                className={`identity-info category ${checkRarity(
                  selectedCard?.rarity
                )}`}
                style={{ fontSize: '12px' }}
              >
                {selectedCard?.category}
              </h4>
            </div>
          )}
        </div>
      </SelectedCraftCard>
    </SelectedCraftCardWrapper>
  );
};

export const CraftPredictionModal: React.FC<CraftPredictionModalProps> = ({
  open,
  onClose,
  onBurn,
  selectedCards,
  rarity
}) => {
  useEffect(() => {
    setChecked(false);
  }, [open]);

  const [checked, setChecked] = useState(false);
  return (
    <ModalWrapper open={open} onClose={onClose} width={750}>
      <CraftPredictionModalWrapper>
        <h3>
          Your Identity and Trigger cards will be burned to craft this
          Prediction
        </h3>
        <>
          {selectedCards.identity !== null &&
            selectedCards.triggers !== null &&
            selectedCards.crafting !== null && (
              <PredictionSelectedCardWrapper>
                <Card
                  cardType="identity"
                  heading="Identity"
                  selectedCard={selectedCards.identity}
                />
                <Card
                  cardType="crafting"
                  heading="Crafting"
                  selectedCard={selectedCards.crafting}
                />
                {selectedCards.triggers?.map((t: any) => {
                  return (
                    <Card
                      key={t}
                      cardType="trigger"
                      heading="Trigger"
                      selectedCard={t}
                    />
                  );
                })}
              </PredictionSelectedCardWrapper>
            )}
        </>
        {
          <RarityTextBox>
            Prediction Rarity will be:{' '}
            {rarity === 0 && <span className="core">Core</span>}
            {rarity === 1 && <span className="uncommon">Uncommon</span>}
            {rarity === 2 && <span className="rare">Rare</span>}
          </RarityTextBox>
        }
        <ButtonGroup>
          <CheckboxWrapper>
            <Checkbox>
              <input
                id={'crafting'}
                type="checkbox"
                value={'crafting'}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label htmlFor={'crafting'}></label>
            </Checkbox>
            <span>
              Creating this Prediction will burn all selected cards. This process
              is irreversible.
            </span>
          </CheckboxWrapper>

          <Button disabled={!checked} onClick={onBurn}>
            Burn
          </Button>
          <Button variant={'outlined'}>Back</Button>
        </ButtonGroup>
      </CraftPredictionModalWrapper>
    </ModalWrapper>
  );
};
