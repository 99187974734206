import React, { useEffect, useState, useMemo } from 'react';
import {
  Badge,
  HeaderButton,
  HeaderButtonGroup,
  HeaderMenuWrapper,
  HeaderNavItem,
  HeaderWrapper,
  MainHeaderContainer,
  MainHeaderWrapper,
  MobileMenuButton,
  NotificationButtonWrapper,
  ProfileDropdown,
  SubHeaderContainer,
  SubHeaderWrapper,
  SubMenuItem
} from './styles';
import {
  BalanceForWithdrawModal,
  HeaderLogo,
  IconAlarm,
  IconCoins,
  IconLogout,
  IconMenu,
  IconProfile,
  WithdrawConfirmModal,
  WithdrawModal
} from '../../components';
import { headerData } from './data';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppHeaderMenuItemProps } from '../../typeroot/types';
import { useAuthContext, useMyInfoContext } from '../../context';
import { MobileMenu } from './MobileMenu';

import { socket } from '../../socket/socket';
import { INotification } from '../../models/claim';
import { IUser } from '../../models/user';
import { Notification } from './Notification';
import api from '../../config/api';
import { getMyNftCardTrigger } from '../../actions/nft_card_trigger';

export const Header: React.FC = () => {
  const { myInfoContext, setMyInfoContext } = useMyInfoContext();
  const { setAuthContext } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [notification, setNotification] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState<'success' | 'failed'>(
    'failed'
  );
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState<AppHeaderMenuItemProps>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    setCurrentPath(
      headerData.filter(
        (f) => f.to === '/' + location.pathname.split('/')[1]
      )[0]
    );
  }, [location]);

  const handleWithdraw = () => {
    setBalanceModal(false);
    setWithdrawModal(true);
    setConfirmModal(true);
  };

  const handleWithdrawClick = () => {
    setBalanceModal(true);
  };

  const handleConfirmWithdraw = async (status?: boolean) => {
    setWithdrawStatus(status ? 'success' : 'failed');
    setWithdrawModal(false);
    setConfirmModal(true);
  };

  const getData = async () => {
    const response = await api.get('/me/notification');
    const triggers = await getMyNftCardTrigger(null);

    let data = [];
    for (const item of response.data) {
      if (item.is_read) continue;
      if (item.payload.Payload.nft_trigger_id) {
        const trigger = triggers?.data?.find(
          (f) => f.id === item.payload.Payload.nft_trigger_id
        );
        data.push({
          ...item.payload.Payload,
          notification_id: item.id,
          is_read: item.is_read,
          claim_trigger: trigger
        });
      } else {
        data.push({
          ...item.payload.Payload,
          notification_id: item.id,
          is_read: item.is_read
        });
      }
    }
    setNotifications(data);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!socket) return;
    const handler = async (event: MessageEvent) => {
      const parsed = JSON.parse(event.data);
      console.log('parsed', parsed);

      // set in context notifications.
      setNotifications((prev) => [
        ...prev,
        {
          ...parsed.payload.data,
          notification_id: parsed.payload.notification_id
        }
      ]);
    };

    socket.addEventListener('message', handler);

    return () => {
      socket.removeEventListener('message', handler);
    };
  }, []);

  const newNotification = useMemo(() => {
    return notifications.reduce(
      (count, notification) => (!notification.is_read ? count + 1 : count),
      0
    );
  }, [notifications]);

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    setAuthContext({
      isAuthenticated: false,
      user: undefined
    });
    setMyInfoContext({} as IUser);
    navigate('/dashboard/home');
  };
  return (
    <>
      <MobileMenu onClose={() => setIsOpen(false)} open={isOpen} />
      <HeaderWrapper>
        <MainHeaderWrapper>
          <MainHeaderContainer>
            <HeaderLogo />
            <HeaderMenuWrapper>
              {headerData
                .filter((f) => f.label !== '')
                .map((item, key) => (
                  <HeaderNavItem
                    key={key}
                    to={item.to}
                    active={currentPath?.to === item.to ? 'true' : undefined}
                  >
                    {item.label}
                  </HeaderNavItem>
                ))}
            </HeaderMenuWrapper>
            {localStorage.getItem('auth') ? (
              <HeaderButtonGroup>
                <HeaderButton width={124} onClick={handleWithdrawClick}>
                  <IconCoins />
                  <span>
                    $
                    {myInfoContext && myInfoContext?.balance
                      ? (myInfoContext?.balance / 100).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      : '0.00'}
                  </span>
                </HeaderButton>
                <HeaderButton
                  width={124}
                  className={currentPath?.to === '/profile' ? 'active' : ''}
                  onClick={handleDropdown}
                >
                  <IconProfile />
                  <span>{myInfoContext?.username}</span>
                  {showDropdown && currentPath?.to !== '/profile' && (
                    <ProfileDropdown>
                      <div onClick={() => navigate('/profile')}>Profile</div>
                      <div onClick={handleLogout}>Logout</div>
                    </ProfileDropdown>
                  )}
                </HeaderButton>
                <NotificationButtonWrapper>
                  <HeaderButton onClick={() => setNotification(!notification)}>
                    <IconAlarm />
                    {newNotification > 0 && <Badge>{newNotification}</Badge>}
                  </HeaderButton>
                  <Notification
                    open={notification}
                    data={notifications}
                    onClose={() => setNotification(false)}
                    onClear={() => getData()}
                  />
                </NotificationButtonWrapper>
              </HeaderButtonGroup>
            ) : (
              <HeaderButtonGroup>
                <HeaderButton
                  className="login-button"
                  onClick={() => navigate('/signin')}
                >
                  <IconLogout />
                  <span>Log In</span>
                </HeaderButton>
              </HeaderButtonGroup>
            )}
            <MobileMenuButton onClick={() => setIsOpen(true)}>
              <HeaderButton>
                <IconMenu />
              </HeaderButton>
            </MobileMenuButton>
          </MainHeaderContainer>
        </MainHeaderWrapper>
        {currentPath?.children && (
          <SubHeaderWrapper>
            <SubHeaderContainer id="submenu">
              {currentPath?.children.map((item, key) => (
                <SubMenuItem
                  key={key}
                  to={currentPath.to + '/' + item.to}
                  active={
                    location.pathname.split('/')[2] === item.to
                      ? 'true'
                      : undefined
                  }
                >
                  {item.label}
                </SubMenuItem>
              ))}
            </SubHeaderContainer>
          </SubHeaderWrapper>
        )}
      </HeaderWrapper>
      <BalanceForWithdrawModal
        onClose={() => setBalanceModal(false)}
        open={balanceModal}
        onWithdraw={handleWithdraw}
      />
      <WithdrawModal
        onClose={() => setWithdrawModal(false)}
        open={withdrawModal}
        onWithdraw={handleConfirmWithdraw}
      />
      <WithdrawConfirmModal
        status={withdrawStatus}
        onClose={() => setConfirmModal(false)}
        open={confirmModal}
      />
    </>
  );
};
