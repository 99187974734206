import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  MarketplaceContentWrapper,
  MarketplacePageContainer,
  MarketplacePageWrapper
} from '../cards/styles';
import {
  MFilterSection,
  MBuyCardSection,
  MCardGridSection,
  MSellCardSection,
  MViewCardSection,
  MOfferCardSection
} from '../../../modules';
import { CardActionTypes } from '../../../typeroot/types';
import { EmptyCards } from '../../app/category/styles';
import { Button, Loader } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { IMarketplaceListing } from '../../../models/marketplace_listing';
import { getMarketplaceList } from '../../../actions/marketplace_listing';
import {
  useAllRaritiesContext,
  useCategoriesContext,
  useMyInfoContext,
  useMyOfferContext,
  useStatusContext,
  useTriggersContext
} from '../../../context';
import { toast } from 'react-toastify';

export const MarketplacePredictionPage: React.FC = () => {
  const navigate = useNavigate();
  const [side, setSide] = useState<CardActionTypes>('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<IMarketplaceListing>();
  const [listings, setListings] = useState<IMarketplaceListing[] | null>(null);
  const [filteredListings, setFilteredListings] = useState<
    IMarketplaceListing[] | null
  >(null);
  const { myInfoContext } = useMyInfoContext();
  const { myOfferContext, setMyOfferContext } = useMyOfferContext();
  const { allRaritiesContext } = useAllRaritiesContext();
  const { statusContext } = useStatusContext();
  const { categoriesContext } = useCategoriesContext();
  const { triggersContext } = useTriggersContext();
  const [selectedNftCollectionId, setSelectedNftCollectionId] =
    useState<number>(1);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);

  const [selectedTriggers, setSelectedTriggers] = useState<number[]>([]);
  const [selectedRarity, setSelectedRarity] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);

  const handleCardClick = (item: any, action: CardActionTypes) => {
    if (!listings) return;
    for (const l of listings) {
      if (l.nft_card_prediction_id === item.id) {
        setSelectedItem({ ...item, marketplace_listing_id: l.id });
        setSide(action);
        return;
      }
    }
  };

  const handleSideClose = () => {
    setSide('');
  };

  const getPageData = async () => {
    setIsLoading(true);
    const response = await getMarketplaceList({
      card_collection_id: 1,
      nft_type_ids: [7]
    });

    if (response?.data) {
      setListings(response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, [myInfoContext]);

  // useEffect(() => {
  //   if (triggersContext) {
  //     setSelectedTriggers([...triggersContext.keys()]);
  //   }
  // }, [triggersContext]);

  const handleOfferConfirm = () => {
    if (selectedItem) {
      const offerCard = listings?.filter(
        (f) => f.id === Number(selectedItem.id)
      )[0];
      if (offerCard) {
        setMyOfferContext([...myOfferContext, offerCard]);
        handleSideClose();
      } else {
        toast.error('Something went wrong!!!');
      }
    }
  };

  useEffect(() => {
    if (!listings) {
      return;
    }

    if (statusContext && allRaritiesContext && categoriesContext) {
      const categories = selectedCategory.map((c) => {
        const categoryObj = categoriesContext.get(c);
        return categoryObj?.name.toLowerCase();
      });

      const triggers = selectedTriggers.map((c: any) => {
        const triggersObj = triggersContext.get(c);
        return triggersObj.name;
      });

      const rarities = selectedRarity.map((r) => {
        const rarityObj = allRaritiesContext.get(r);
        return rarityObj?.id;
      });

      const statuses = selectedStatus.map((s) => {
        const statusObj = statusContext.get(s);
        return statusObj?.name;
      });

      const newListings = [];

      for (const listing of listings) {
        if (categories.length > 0) {
          if (
            !categories.includes(
              listing.nft_card_prediction?.nft_identity?.category?.toLowerCase()
            )
          ) {
            continue;
          }
        }

        if (selectedNftCollectionId !== 1) {
          continue;
        }

        if (triggers.length > 0) {
          if (
            !triggers.some(
              (element: any) =>
                listing.nft_card_prediction?.triggers?.includes(element)
            )
          ) {
            continue;
          }
        }

        if (rarities.length > 0) {
          if (!rarities.includes(listing.nft_card_prediction?.rarity)) {
            continue;
          }
        }

        if (statuses.length === 1) {
          if (listing.is_listed === false && statuses[0] === 'For Sale') {
            continue;
          }
          if (listing.is_listed === true && statuses[0] === 'Not for sale') {
            continue;
          }
        }
        newListings.push(listing);
      }
      setFilteredListings(newListings);
    }
  }, [
    listings,
    selectedCategory,
    selectedRarity,
    selectedTriggers,
    selectedStatus,
    statusContext,
    allRaritiesContext,
    categoriesContext,
    triggersContext,
    selectedNftCollectionId
  ]);

  const sortListings = (filteredListings: any[], order: string) => {
    const sortedListings = [...filteredListings].sort((a, b) => {
      if (order === 'asc') {
        return a.nft_card_prediction.rarity - b.nft_card_prediction.rarity;
      } else {
        return b.nft_card_prediction.rarity - a.nft_card_prediction.rarity;
      }
    });

    return setFilteredListings(sortedListings);
  };

  return (
    <AppLayout>
      <MarketplacePageWrapper sidebar={side !== '' ? 'true' : undefined}>
        <MarketplacePageContainer>
          <h2>Predictions</h2>
          <MarketplaceContentWrapper>
            <MFilterSection
              page="predictions"
              onSelectTrigger={(selected) => {
                setSelectedTriggers(selected);
              }}
              onSelectNftCollection={(selected) => {
                setSelectedNftCollectionId(selected);
              }}
              onSelectCategory={(selected) => {
                setSelectedCategory(selected);
              }}
              onSelectStatus={(selected) => {
                setSelectedStatus(selected);
              }}
              onSelectRarity={(selected) => {
                setSelectedRarity(selected);
              }}
              sortListings={sortListings}
              data={filteredListings}
            />
            {listings && listings?.length > 0 ? (
              <MCardGridSection
                data={filteredListings ? filteredListings : listings}
                onCardClick={handleCardClick}
                page="predictions"
              />
            ) : !isLoading ? (
              <EmptyCards>
                <p style={{ maxWidth: '253px' }}>
                  Wow, can you believe no one wants to sell even a single card?
                </p>
                <Button
                  className="buy-button"
                  onClick={() => navigate('/dashboard/predictions')}
                >
                  Sell Card
                </Button>
              </EmptyCards>
            ) : (
              <Loader />
            )}
          </MarketplaceContentWrapper>
        </MarketplacePageContainer>
      </MarketplacePageWrapper>
      {selectedItem && (
        <div id="sidebar" style={{ zIndex: 9 }}>
          <MViewCardSection
            selectedItem={selectedItem}
            open={side === 'view'}
            onClose={handleSideClose}
            page="predictions"
          />
          <MBuyCardSection
            selectedItem={selectedItem}
            open={side === 'buy'}
            onClose={handleSideClose}
            page="predictions"
          />
          <MSellCardSection
            open={side === 'sell'}
            onClose={handleSideClose}
            page="predictions"
            selectedItem={selectedItem}
          />
          <MOfferCardSection
            open={side === 'offer'}
            onClose={handleSideClose}
            onConfirm={handleOfferConfirm}
            selectedItem={selectedItem}
            page="predictions"
          />
        </div>
      )}
    </AppLayout>
  );
};
