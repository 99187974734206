import React, { EventHandler, useEffect, useState } from "react";
import { Modal as ModalWrapper } from "./Modal";
import { WithdrawModalProps } from "../../typeroot/types";
import {
  AvailableAmount,
  ButtonGroup,
  WithdrawInputGroup,
  WithdrawModalWrapper,
} from "./styles";
import { Input } from "../Input";
import { SelectBox } from "../SelectBox";
import { Button } from "../Button";
import { withdrawAccountList } from "./data";
import { useMyInfoContext } from "../../context";
import api from "../../config/api";

export const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onClose,
  onWithdraw,
  open,
}) => {
  const { myInfoContext } = useMyInfoContext();
  const [amount, setAmount] = useState<number>();
  const [account, setAccount] = useState<string | Array<string>>("");
  const [error, setError] = useState("");
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (myInfoContext)
      setBalance(myInfoContext?.balance ? myInfoContext?.balance / 100: 0);
  }, [myInfoContext]);

  const handleWithdraw = async () => {
          const me = await api.get('/me');
          console.log(me);
          setAccount(me.data.email);
    if (!amount || amount === 0) {
      setError('Please enter a valid amount');
    } else if (!account) {
      setError('Please select an account');
    } else if (amount > balance) {
      setError('Amount exceeds available balance');
    } else {
      setError('');

    const response = await api.post('/withdrawal_transaction/', {
        ToAccount: me.data.email,
        amount: amount
      }
    );
    console.log(response);
      onWithdraw(true);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
    } else {
      setAmount(undefined);
    }
  };

const handleAccountChange = (selectedValue: string | Array<string>) => {
    console.log('Selected value type:', typeof selectedValue);
    console.log('Selected value:', selectedValue);
  // If the component can return both string and array, handle both cases
  if (typeof selectedValue === 'string') {
    setAccount(selectedValue);
  } else {
    // Handle the case where selectedValue is an array
    // For example, set only the first selected value, or handle as needed
    setAccount(selectedValue[0]);
  }
};


  const handleClose = () => {
    setAmount(undefined);
    setAccount("");
    setError("");
    onClose();
  };


  return (
    <ModalWrapper onClose={onClose} open={open} width={349}>
      <WithdrawModalWrapper>
        <h3>Withdraw</h3>
        <WithdrawInputGroup>
          <Input
            label="Set the amount"
            value={amount}
            error={error}
            placeholder="0"
            onChange={handleInputChange}
          />
          <SelectBox
            border
            onChange={handleAccountChange}
            value={withdrawAccountList[0].value}
            label="Withdraw to"
            placeholder="Select the account"
            options={withdrawAccountList}
          />
        </WithdrawInputGroup>
        <AvailableAmount>
          <span>Available</span>
          <p>
            {balance.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}{' '}
            <span>USD</span>
          </p>
        </AvailableAmount>
        <ButtonGroup>
          <Button
            onClick={handleWithdraw}
            // disabled={account === ""}
          >
            Confirm Withdraw
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </ButtonGroup>
      </WithdrawModalWrapper>
    </ModalWrapper>
  );
};
