import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  ButtonGroup,
  DatePageContainer,
  DatePageTitleWrapper,
  DatesPageWrapper,
  EmptyCards,
  HideOnMobile
} from '../dates/styles';
import { Button, SellConfirmModal, Loader } from '../../../components';
import {
  CardGridSection,
  IdentitiesFilterSection,
  SellDateCardSection,
  ViewDateCardSection
} from '../../../modules';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMyNftCardIdentity } from '../../../actions/nft_card_identity';
import { newMarketplaceList } from '../../../actions/marketplace_listing';
import { INftCardIdentity } from '../../../models/nft_card_identity';

import { NftCardIdentityFilters } from '../../../models/filters';
import { DatePageContent } from '../category/styles';
import { CancelListingModal } from '../../../components/Modals/CancelListing';
import { INftCardPrediction } from '../../../models/nft_card_prediction';
import { ICardPack } from '../../../models/card_pack';
import { useAuthContext } from '../../../context';
import { Modal } from '../../../components/Modals/Modal';

export const IdentitiesPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const { authContext } = useAuthContext();
  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [isView, setIsView] = useState<'view' | 'sell' | ''>('');
  const [modal, setModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [identityNfts, setIdentityNfts] = useState<INftCardIdentity[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [cancelIdentityNft, setCancelIdentityNft] =
    useState<INftCardIdentity>();

  const [filters, setFilters] = useState<NftCardIdentityFilters>({
    card_series_id: null,
    card_collection_id: [1],
    status: null,
    rarities: null,
    celebrities: null,
    categories: null
  });

  useEffect(() => {
    if (params.get('id')) {
      setIsView('view');
    }
  }, [params]);

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
  }, []);

  const getPageData = async () => {
    setIsLoading(true);
    const response = await getMyNftCardIdentity(null);
    if (response?.data) {
      setIdentityNfts(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleSellConfirm = async (
    id: number,
    collection_id: number,
    price: number
  ) => {
    const newMarketplace = {
      nft_type_id: 6,
      nft_card_identity_id: id,
      price: Math.round(price * 100)
    };
    const response = await newMarketplaceList(newMarketplace);
    if (response.success) {
      setModal(true);
      setIsView('');
    }
  };

  const handleView = (item: any) => {
    setSelectedItem(item);
    setIsView('view');
  };

  const handleCraft = (item: any) => {
    if (item?.id) navigate(`/crafting/predictions?id=${item.id}&type=identity`);
  };

  const handleSell = (item: any) => {
    setSelectedItem(item);
    setIsView('sell');
  };

  const handleCancel = (
    item: INftCardIdentity | INftCardPrediction | ICardPack
  ) => {
    setCancelIdentityNft(item as INftCardIdentity);
    setCancelModal(true);
  };

  // filter option click
  const handleOptionClick = async (
    filterType: string,
    selectedOptions: string[]
  ) => {
    setIsLoadingFilter(true);

    let newFilters: NftCardIdentityFilters = {
      card_series_id: filters.card_series_id,
      card_collection_id: filters.card_collection_id,
      status: filters.status,
      rarities: filters.rarities,
      categories: filters.categories,
      celebrities: filters.celebrities
    };
    console.log('newFilters', newFilters);

    switch (filterType) {
      case 'Category':
        newFilters.categories = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'All Rarities':
        newFilters.rarities = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'Status':
        newFilters.status = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
      case 'Collections':
        newFilters.card_collection_id = selectedOptions.map((v) => {
          return Number(v);
        });
        break;
    }

    setFilters(newFilters);

    let res = await getMyNftCardIdentity(newFilters);
    if (res?.data) {
      setIdentityNfts(res?.data as INftCardIdentity[]);
    }
    setIsLoadingFilter(false);
  };

  const getTimeStemp = (date: string) => {
    const now = new Date(date);
    const timestamp = now.getTime();
    return timestamp;
  };

  const clickSelect = async (sortSelectOption: string) => {
    setIsLoading(true);
    if (sortSelectOption == 'Date-High-Low') {
      setIsLoading(true);
      const response = await getMyNftCardIdentity(null);
      if (response?.data) {
        response.data.sort((a: any, b: any) => {
          if (a.month === b.month) return b.day - a.day;
          if (a.year === b.year) return b.month - a.month;
          return b.year - a.year;
        });
        setIdentityNfts(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Date-Low-High') {
      setIsLoading(true);
      const response = await getMyNftCardIdentity(null);
      if (response?.data) {
        response.data.sort((a: any, b: any) => {
          if (a.month === b.month) return a.day - b.day;
          if (a.year === b.year) return a.month - b.month;
          return a.year - b.year;
        });
        setIdentityNfts(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Rarity High-Low') {
      setIsLoading(true);
      const response = await getMyNftCardIdentity(null);
      if (response?.data) {
        response?.data.sort((a: any, b: any) => b.rarity - a.rarity);
        setIdentityNfts(response?.data);
        setIsLoading(false);
      }
    } else if (sortSelectOption == 'Rarity Low-High') {
      setIsLoading(true);
      const response = await getMyNftCardIdentity(null);
      if (response?.data) {
        response?.data.sort((a: any, b: any) => a.rarity - b.rarity);
        setIdentityNfts(response?.data);
        setIsLoading(false);
      }
    }
  };

  return (
    <AppLayout>
      <SellConfirmModal open={modal} onClose={() => setModal(false)} />
      <Modal open={filterModal} onClose={() => setFilterModal(false)}>
        <IdentitiesFilterSection
          filters={filters}
          onClick={handleOptionClick}
          clickSelect={clickSelect}
        />
      </Modal>
      {cancelIdentityNft && (
        <CancelListingModal
          open={cancelModal}
          onClose={() => setCancelModal(false)}
          nftCard={cancelIdentityNft}
          cardType="Identity"
        />
      )}
      {authContext?.isAuthenticated ? (
        <DatesPageWrapper isview={isView ? 'true' : undefined}>
          <DatePageContainer>
            <DatePageTitleWrapper>
              <h3>Identities</h3>
            </DatePageTitleWrapper>
            <DatePageContent>
              {!isLoadingFilter && identityNfts && identityNfts?.length > 0 ? (
                <>
                  <ButtonGroup>
                    <Button
                      className="craft-button"
                      onClick={() => navigate('/crafting/identities')}
                    >
                      Craft Identity
                    </Button>
                    <Button
                      className="filter-button"
                      onClick={() => setFilterModal(true)}
                    >
                      Filters
                    </Button>
                  </ButtonGroup>
                  <HideOnMobile>
                    <IdentitiesFilterSection
                      filters={filters}
                      onClick={handleOptionClick}
                      clickSelect={clickSelect}
                    />
                  </HideOnMobile>
                  <CardGridSection
                    identityData={identityNfts}
                    onCraft={handleCraft}
                    onSell={handleSell}
                    cardType="identity"
                    onView={handleView}
                    onCancel={handleCancel}
                  />
                </>
              ) : !isLoading && !isLoadingFilter && identityNfts ? (
                <>
                  <ButtonGroup>
                    <Button
                      className="craft-button"
                      onClick={() => navigate('/crafting/identities')}
                    >
                      Craft Identity
                    </Button>
                    <Button
                      className="filter-button"
                      onClick={() => setFilterModal(true)}
                    >
                      Filters
                    </Button>
                  </ButtonGroup>
                  <HideOnMobile>
                    <IdentitiesFilterSection
                      filters={filters}
                      onClick={handleOptionClick}
                      clickSelect={clickSelect}
                    />
                  </HideOnMobile>
                  <CardGridSection
                    identityData={identityNfts}
                    onCraft={handleCraft}
                    onSell={handleSell}
                    cardType="identity"
                    onView={handleView}
                  />
                </>
              ) : !isLoading && identityNfts == null ? (
                <EmptyCards>
                  <div className="trigeres">
                    <h3>No Identities Yet</h3>
                    <p>
                      Identities are cards created by combining a Day-Month
                      card, a Year card and a Category card. Identities are
                      combined with Trigger cards to craft Predictions.
                    </p>
                    <Button
                      className="buy-button"
                      onClick={() => navigate('/crafting/identities')}
                    >
                      Craft Identity
                    </Button>
                  </div>
                </EmptyCards>
              ) : isLoading ? (
                <Loader />
              ) : null}
            </DatePageContent>
            {identityNfts && identityNfts?.length > 0 ? (
              <>
                <ViewDateCardSection
                  isView={isView === 'view'}
                  cardType="identity"
                  item={selectedItem}
                  onClose={() => {
                    setIsView('');
                    navigate('/dashboard/identities');
                  }}
                />
                <SellDateCardSection
                  onSellConfirm={handleSellConfirm}
                  cardType="identity"
                  isView={isView === 'sell'}
                  item={selectedItem}
                  onClose={() => {
                    setIsView('');
                    navigate('/dashboard/identities');
                  }}
                />
              </>
            ) : isLoadingFilter ? (
              <h1 className="setText" hidden>
                No Records Found
              </h1>
            ) : null}
          </DatePageContainer>
        </DatesPageWrapper>
      ) : (
        <EmptyCards className="login">
          <p className="login">Log in to start playing</p>
          <Button className="buy-button" onClick={() => navigate('/signin')}>
            Login Now
          </Button>
        </EmptyCards>
      )}
    </AppLayout>
  );
};
