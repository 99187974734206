import React, { useState, useEffect } from 'react';
import {
  CardBottomWrapper,
  PredictionCardWrapper,
  TriggersToolTip
} from './styles';
import {
  CardButton,
  CardButtonGroup,
  CardOverlayWrapper
} from '../DateCard/styles';

import {
  useCelebritiesContext,
  useMyInfoContext,
  useAuthContext
} from '../../context';
import { ICelebrity } from '../../models/celebrity';
import { updateMyNftCardIdentity } from '../../actions/nft_card_identity';
import { CardImgWrapper, StatusWrapper } from '../MarketCard/styles';
import { checkRarity } from '../../utils/helperFunctions';
import { Loader } from '../Loader';
import { useNavigate } from 'react-router-dom';
import { clearLine } from 'readline';
import { PredictionCardProps, SelectOptionProps } from '../../typeroot/types';

export const PredictionCard: React.FC<PredictionCardProps> = ({
  dashbordstyle,
  celebrity_name,
  cardType,
  item,
  id = 0,
  image,
  category,
  day,
  month,
  rarity,
  isListed,
  year,
  icon,
  is_crafted,
  height,
  isNotHover,
  triggers,
  status,
  is_claimed,
  onClick,
  onCraft,
  onSell,
  onCard,
  onView,
  onBuy,
  onClaimSubmit,
  onCancel
}) => {
  const { myInfoContext } = useMyInfoContext();
  const { celebritiesContext } = useCelebritiesContext();
  const { authContext } = useAuthContext();
  const navigate = useNavigate();

  const chooseCelebrity = async (v: SelectOptionProps) => {
    let c = (celebritiesContext as Map<number, ICelebrity>).get(
      Number(v.value)
    );

    if (c) {
      let res = await updateMyNftCardIdentity(item?.id, c?.id);
    }
  };

  const [identityMatches, setIdentityMatches] = useState<
    { label: string; value: string }[] | null
  >(null);

  useEffect(() => {
    if (celebritiesContext) {
      let matches: { label: string; value: string }[] = [];
      (celebritiesContext as Map<number, ICelebrity>).forEach((v) => {
        if (
          v.birth_day === day &&
          v.birth_month === month &&
          v.birth_year === year &&
          v.category === category
        )
          matches.push({ label: v.name, value: v.id.toString() });
      });

      setIdentityMatches(matches);
    }
  }, [celebritiesContext]);

  return (
    <PredictionCardWrapper
      onClick={onClick}
      height={height}
      isnothover={isNotHover && celebrity_name ? 'true' : undefined}
    >
      {item?.nft_card_triggers && item.nft_card_triggers.length > 0 && (
        <TriggersToolTip>
          <div className="hover">{item.nft_card_triggers.length}T</div>
          <div className="wrapper">
            <p className="heading">Triggers</p>
            {item.nft_card_triggers.map((t: any, i: number) => (
              <p key={i} className="item">
                {t.trigger} - {t.tier}
              </p>
            ))}
          </div>
        </TriggersToolTip>
      )}
      <CardImgWrapper dashbordstyle={dashbordstyle}>
        {(rarity || rarity === 0) && item ? (
          <div>
            <img
              className="prediction-gif"
              src={`/assets/nfts/rarity/Prediction-${checkRarity(rarity)}.gif`}
              alt="nft"
            />
            <div
              className={`${checkRarity(
                rarity
              )} info-nft prediction-text-block`}
            >
              <h4 className={checkRarity(rarity)}>
                {String(item?.nft_identity?.month).padStart(2, '0')}.
                {String(item?.nft_identity?.day).padStart(2, '0')}.
                {item?.nft_identity?.year}
              </h4>
              <h4 className={checkRarity(rarity)}>
                {item?.nft_identity?.category}
              </h4>
            </div>
          </div>
        ) : (
          <Loader />
        )}

        <StatusWrapper>
          {isListed !== undefined && (
            <span>{isListed ? 'For Sale' : 'Not For Sale'}</span>
          )}
        </StatusWrapper>
      </CardImgWrapper>
      <CardBottomWrapper>{celebrity_name}</CardBottomWrapper>

      {/* <CardTopWrapper>
        <CardDateWrapper dashbordstyle={dashbordstyle}>
          {monthContext && day && (
            <span className="date">
              {day && month
                ? `${day}/${(monthContext as Map<number, string>).get(month)}`
                : "Month/Day"}
            </span>
          )}

          {year && <span className="year">{year}</span>}
        </CardDateWrapper>
      </CardTopWrapper> */}
      <CardOverlayWrapper className="overlay">
        <CardButtonGroup>
          {!is_crafted &&
            onCraft &&
            item?.owner_id === myInfoContext?.id &&
            cardType === 'identity' && (
              <CardButton
                onClick={() =>
                  !authContext?.isAuthenticated
                    ? navigate('/signin')
                    : onCraft(item)
                }
              >
                Craft Prediction
              </CardButton>
            )}
          {onView && <CardButton onClick={() => onView(item)}>View</CardButton>}
          {item?.owner_id === myInfoContext?.id &&
            !is_claimed &&
            onClaimSubmit && (
              <CardButton
                onClick={() =>
                  !authContext?.isAuthenticated
                    ? navigate('/signin')
                    : onClaimSubmit(item)
                }
              >
                Submit Claim
              </CardButton>
            )}

          {item?.owner_id === myInfoContext?.id && onSell && (
            <CardButton
              onClick={() =>
                !authContext?.isAuthenticated
                  ? navigate('/signin')
                  : onSell(item)
              }
            >
              Sell
            </CardButton>
          )}
          {item?.owner_id !== myInfoContext?.id && onBuy && (
            <CardButton
              onClick={() =>
                !authContext?.isAuthenticated
                  ? navigate('/signin')
                  : onBuy(item)
              }
            >
              Buy
            </CardButton>
          )}
          {item?.owner_id === myInfoContext?.id &&
            onCancel &&
            item?.nft_card_prediction?.marketplace_listing && (
              <CardButton
                onClick={() =>
                  !authContext?.isAuthenticated
                    ? navigate('/signin')
                    : onCancel(item)
                }
              >
                Cancel Listing
              </CardButton>
            )}

          {onCard && (
            <>
              <CardButton onClick={() => onCard(item, 'view')}>View</CardButton>
              {isListed && item?.owner_id !== myInfoContext?.id && (
                <CardButton
                  onClick={() =>
                    !authContext?.isAuthenticated
                      ? navigate('/signin')
                      : onCard(item, 'buy')
                  }
                >
                  Buy
                </CardButton>
              )}
              {!isListed && item?.owner_id !== myInfoContext?.id && (
                <CardButton
                  onClick={() =>
                    !authContext?.isAuthenticated
                      ? navigate('/signin')
                      : onCard(item, 'offer')
                  }
                >
                  Make an Offer
                </CardButton>
              )}
            </>
          )}
        </CardButtonGroup>
      </CardOverlayWrapper>
    </PredictionCardWrapper>
  );
};
