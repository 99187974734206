export const nft_card_year_data = [
  {
    id: 0,
    rarity: 0,
    year: 2023,
    owner_id: 2,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 1,
    rarity: 2,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 2,
    rarity: 1,
    year: 2023,
    owner_id: 4,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 3,
    rarity: 2,
    year: 2023,
    owner_id: 1,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 4,
    rarity: 1,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 5,
    rarity: 0,
    year: 2023,
    owner_id: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 6,
    rarity: 1,
    year: 2023,
    owner_id: 1,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 7,
    rarity: 2,
    year: 2023,
    owner_id: 3,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 8,
    rarity: 0,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 9,
    rarity: 0,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 10,
    rarity: 1,
    year: 2023,
    owner_id: 2,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 11,
    rarity: 0,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 12,
    rarity: 2,
    year: 2023,
    owner_id: 1,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 13,
    rarity: 1,
    year: 2023,
    owner_id: 4,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 14,
    rarity: 1,
    year: 2023,
    owner_id: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 15,
    rarity: 0,
    year: 2023,
    owner_id: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 16,
    rarity: 2,
    year: 2023,
    owner_id: 1,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
];

export const nft_card_crafting_data = [
  {
    id: 17,
    is_crafted: true,
    owner_id: 2,
    rarity: 1,
    image: "/assets/nfts/1.png",
  },
  {
    id: 18,
    is_crafted: false,
    owner_id: 3,
    rarity: 2,
    image: "/assets/nfts/2.png",
  },
  {
    id: 19,
    is_crafted: true,
    owner_id: 2,
    rarity: 1,
    image: "/assets/nfts/1.png",
  },
  {
    id: 20,
    is_crafted: false,
    owner_id: 1,
    rarity: 0,
    image: "/assets/nfts/2.png",
  },
  {
    id: 21,
    is_crafted: false,
    owner_id: 2,
    rarity: 2,
    image: "/assets/nfts/1.png",
  },
  {
    id: 22,
    is_crafted: false,
    owner_id: 4,
    rarity: 1,
    image: "/assets/nfts/2.png",
  },
  {
    id: 23,
    is_crafted: true,
    owner_id: 1,
    rarity: 0,
    image: "/assets/nfts/1.png",
  },
  {
    id: 24,
    is_crafted: false,
    owner_id: 3,
    rarity: 2,
    image: "/assets/nfts/1.png",
  },
  {
    id: 25,
    is_crafted: true,
    owner_id: 2,
    rarity: 1,
    image: "/assets/nfts/2.png",
  },
  {
    id: 26,
    is_crafted: false,
    owner_id: 1,
    rarity: 0,
    image: "/assets/nfts/1.png",
  },
  {
    id: 27,
    is_crafted: true,
    owner_id: 2,
    rarity: 2,
    image: "/assets/nfts/2.png",
  },
  {
    id: 28,
    is_crafted: true,
    owner_id: 2,
    rarity: 1,
    image: "/assets/nfts/1.png",
  },
  {
    id: 29,
    is_crafted: false,
    owner_id: 4,
    rarity: 2,
    image: "/assets/nfts/2.png",
  },
  {
    id: 30,
    is_crafted: false,
    owner_id: 1,
    rarity: 0,
    image: "/assets/nfts/1.png",
  },
  {
    id: 31,
    is_crafted: false,
    owner_id: 3,
    rarity: 1,
    image: "/assets/nfts/1.png",
  },
  {
    id: 32,
    is_crafted: true,
    owner_id: 1,
    rarity: 0,
    image: "/assets/nfts/2.png",
  },
];

export const nft_card_day_month_data = [
  {
    id: 33,
    day: 14,
    month: 3,
    owner_id: 3,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 34,
    day: 28,
    month: 9,
    owner_id: 1,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 35,
    day: 10,
    month: 7,
    owner_id: 2,
    rarity: 1,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 36,
    day: 2,
    month: 10,
    owner_id: 2,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 37,
    day: 8,
    month: 12,
    owner_id: 3,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 38,
    day: 12,
    month: 1,
    owner_id: 4,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 39,
    day: 23,
    month: 12,
    owner_id: 2,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 40,
    day: 24,
    month: 4,
    owner_id: 3,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 41,
    day: 13,
    month: 3,
    owner_id: 1,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 42,
    day: 19,
    month: 1,
    owner_id: 3,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 43,
    day: 5,
    month: 10,
    owner_id: 4,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 44,
    day: 16,
    month: 8,
    owner_id: 1,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 45,
    day: 9,
    month: 6,
    owner_id: 1,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 46,
    day: 11,
    month: 7,
    owner_id: 3,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 47,
    day: 17,
    month: 3,
    owner_id: 4,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 48,
    day: 30,
    month: 11,
    owner_id: 3,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
];

export const nft_card_category_data = [
  {
    id: 49,
    category: "Category1",
    owner_id: 2,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 50,
    category: "Category2",
    owner_id: 4,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/1.png",
  },
  {
    id: 51,
    category: "Category3",
    owner_id: 4,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 52,
    category: "Category4",
    owner_id: 1,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 53,
    category: "Category5",
    owner_id: 4,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 54,
    category: "Category6",
    owner_id: 1,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 55,
    category: "Category7",
    owner_id: 3,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 56,
    category: "Category8",
    owner_id: 3,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 57,
    category: "Category9",
    owner_id: 2,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 58,
    category: "Category10",
    owner_id: 4,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 59,
    category: "Category11",
    owner_id: 1,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 60,
    category: "Category12",
    owner_id: 3,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 61,
    category: "Category13",
    owner_id: 2,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
  {
    id: 62,
    category: "Category14",
    owner_id: 2,
    rarity: 1,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 63,
    category: "Category15",
    owner_id: 4,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 64,
    category: "Category16",
    owner_id: 1,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/1.png",
  },
];

export const nft_card_trigger_data = [
  {
    id: 65,
    trigger: "Trigger Name1",
    owner_id: 2,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 66,
    trigger: "Trigger Name2",
    owner_id: 1,
    rarity: 1,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 67,
    trigger: "Trigger Name3",
    owner_id: 3,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 68,
    trigger: "Trigger Name4",
    owner_id: 2,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 69,
    trigger: "Trigger Name5",
    owner_id: 1,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 70,
    trigger: "Trigger Name6",
    owner_id: 3,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 71,
    trigger: "Trigger Name7",
    owner_id: 2,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 72,
    trigger: "Trigger Name8",
    owner_id: 4,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 73,
    trigger: "Trigger Name9",
    owner_id: 1,
    rarity: 2,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 74,
    trigger: "Trigger Name10",
    owner_id: 2,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 75,
    trigger: "Trigger Name11",
    owner_id: 1,
    rarity: 0,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 76,
    trigger: "Trigger Name12",
    owner_id: 2,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 77,
    trigger: "Trigger Name13",
    owner_id: 3,
    rarity: 2,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 78,
    trigger: "Trigger Name14",
    owner_id: 1,
    rarity: 1,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
  {
    id: 79,
    trigger: "Trigger Name15",
    owner_id: 2,
    rarity: 1,
    is_crafted: true,
    image: "/assets/nfts/2.png",
  },
  {
    id: 80,
    trigger: "Trigger Name16",
    owner_id: 4,
    rarity: 0,
    is_crafted: false,
    image: "/assets/nfts/2.png",
  },
];

export const nft_card_prediction_data = [
  {
    id: 81,
    rarity: 0,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 4,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 82,
    rarity: 1,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 3,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 83,
    rarity: 0,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 4,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 84,
    rarity: 1,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 1,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 85,
    rarity: 2,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 4,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 86,
    rarity: 1,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 2,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 87,
    rarity: 1,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 4,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 88,
    rarity: 0,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 1,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 89,
    rarity: 2,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 3,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 90,
    rarity: 1,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 2,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 91,
    rarity: 0,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 1,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 92,
    rarity: 2,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 1,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 93,
    rarity: 2,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 2,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 94,
    rarity: 1,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 3,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 95,
    rarity: 2,
    is_claimed: false,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 2,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 96,
    rarity: 0,
    is_claimed: true,
    triggers: ["Trigger Name1", "Trigger Name2", "Trigger Name3"],
    owner_id: 3,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
];

export const nft_card_identity_data = [
  {
    id: 97,
    month: 12,
    year: 2023,
    is_crafted: true,
    owner_id: 4,
    category: "Category",
    rarity: 2,
    day: 14,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 98,
    month: 4,
    year: 2023,
    is_crafted: false,
    owner_id: 1,
    category: "Category",
    rarity: 0,
    day: 18,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 99,
    month: 5,
    year: 2023,
    is_crafted: false,
    owner_id: 3,
    category: "Category",
    rarity: 0,
    day: 7,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 100,
    month: 9,
    year: 2023,
    is_crafted: true,
    owner_id: 2,
    category: "Category",
    rarity: 1,
    day: 20,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 101,
    month: 3,
    year: 2023,
    is_crafted: false,
    owner_id: 2,
    category: "Category",
    rarity: 0,
    day: 28,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 102,
    month: 10,
    year: 2023,
    is_crafted: true,
    owner_id: 4,
    category: "Category",
    rarity: 2,
    day: 30,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 103,
    month: 11,
    year: 2023,
    is_crafted: false,
    owner_id: 3,
    category: "Category",
    rarity: 1,
    day: 14,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 104,
    month: 3,
    year: 2023,
    is_crafted: false,
    owner_id: 1,
    category: "Category",
    rarity: 1,
    day: 19,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 105,
    month: 7,
    year: 2023,
    is_crafted: true,
    owner_id: 3,
    category: "Category",
    rarity: 2,
    day: 9,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 106,
    month: 8,
    year: 2023,
    is_crafted: true,
    owner_id: 1,
    category: "Category",
    rarity: 0,
    day: 12,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 107,
    month: 5,
    year: 2023,
    is_crafted: false,
    owner_id: 2,
    category: "Category",
    rarity: 2,
    day: 16,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 108,
    month: 1,
    year: 2023,
    is_crafted: false,
    owner_id: 1,
    category: "Category",
    rarity: 0,
    day: 8,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 109,
    month: 6,
    year: 2023,
    is_crafted: true,
    owner_id: 4,
    category: "Category",
    rarity: 0,
    day: 2,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 110,
    month: 11,
    year: 2023,
    is_crafted: false,
    owner_id: 1,
    category: "Category",
    rarity: 0,
    day: 8,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/1.png",
  },
  {
    id: 111,
    month: 10,
    year: 2023,
    is_crafted: false,
    owner_id: 3,
    category: "Category",
    rarity: 1,
    day: 22,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
  {
    id: 112,
    month: 4,
    year: 2023,
    is_crafted: true,
    owner_id: 2,
    category: "Category",
    rarity: 2,
    day: 27,
    celebrity_name: "Celebrity Name",
    image: "/assets/nfts/2.png",
  },
];

export const celebrity_data = [
  {
    id: 1,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity1",
    birth_day: 15,
    birth_month: 1,
    birth_year: 2023,
  },
  {
    id: 2,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity2",
    birth_day: 4,
    birth_month: 7,
    birth_year: 2023,
  },
  {
    id: 3,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity3",
    birth_day: 23,
    birth_month: 4,
    birth_year: 2023,
  },
  {
    id: 4,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity4",
    birth_day: 28,
    birth_month: 12,
    birth_year: 2023,
  },
  {
    id: 5,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity5",
    birth_day: 2,
    birth_month: 11,
    birth_year: 2023,
  },
  {
    id: 6,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity6",
    birth_day: 9,
    birth_month: 4,
    birth_year: 2023,
  },
  {
    id: 7,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity7",
    birth_day: 8,
    birth_month: 7,
    birth_year: 2023,
  },
  {
    id: 8,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity8",
    birth_day: 21,
    birth_month: 10,
    birth_year: 2023,
  },
  {
    id: 9,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity9",
    birth_day: 18,
    birth_month: 12,
    birth_year: 2023,
  },
  {
    id: 10,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity10",
    birth_day: 10,
    birth_month: 3,
    birth_year: 2023,
  },
  {
    id: 11,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity11",
    birth_day: 26,
    birth_month: 2,
    birth_year: 2023,
  },
  {
    id: 12,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity12",
    birth_day: 21,
    birth_month: 8,
    birth_year: 2023,
  },
  {
    id: 13,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity13",
    birth_day: 6,
    birth_month: 4,
    birth_year: 2023,
  },
  {
    id: 14,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity14",
    birth_day: 3,
    birth_month: 12,
    birth_year: 2023,
  },
  {
    id: 15,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity15",
    birth_day: 4,
    birth_month: 11,
    birth_year: 2023,
  },
  {
    id: 16,
    categories: ["Category1", "Category2", "Category3"],
    name: "Celebrity16",
    birth_day: 14,
    birth_month: 9,
    birth_year: 2023,
  },
];

export const myNFTsData = {
  nft_card_category_data: nft_card_category_data.filter(
    (f) => f.owner_id === 3
  ),
  nft_card_crafting_data: nft_card_crafting_data.filter(
    (f) => f.owner_id === 3
  ),
  nft_card_day_month_data: nft_card_day_month_data.filter(
    (f) => f.owner_id === 3
  ),
  nft_card_identity_data: nft_card_identity_data.filter(
    (f) => f.owner_id === 3
  ),
  nft_card_prediction_data: nft_card_prediction_data.filter(
    (f) => f.owner_id === 3
  ),
  nft_card_trigger_data: nft_card_trigger_data.filter((f) => f.owner_id === 3),
  nft_card_year_data: nft_card_year_data.filter((f) => f.owner_id === 3),
};
