import { styled } from 'styled-components';

export const FilterSectionWrapper = styled.div`
  & > h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`;

export const FilterSectionGrid = styled.div`
  display: flex;
  justify-content: space-between;
  /* & > :not(:first-child) {
    margin-left: 20px;
  } */
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
  gap: 20px;
`;

export const CardGridWrapper = styled.div`
  margin-top: 36px;
  display: grid;
  grid-gap: 42px;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`;

export const MSidebarWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 150px;
  width: 100%;
  max-width: 400px;
  height: calc(100% - 150px);
  right: ${({ open }) => (open ? '5px' : '-400px')};
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: calc(100% - 76px);
    top: 76px;
  }
`;

export const MSidebarContainer = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 33px 52px;
  width: 100%;
  h2 {
    color: #0e4fbc;
    font-family: vipnagorgialla;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 32px;
    font-weight: 100;
    width: 100%;
  }
  .sell-confirm-button {
    height: 45px;
    margin-top: 30px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    margin-top: -15px;
    margin-bottom: 25px;
  }
  & > .owner {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
    opacity: 1;
  }
`;

export const ViewCardWrapper = styled.div`
  max-width: 250px;
  width: 100%;
  margin: auto;
`;

export const MyBalanceWrapper = styled.div`
  display: flex;
  margin-bottom: 33px;
  align-items: center;
  padding: 23px 0;
  position: relative;
  color: #0e4fbc;
  & > * {
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -52px;
    right: -52px;
    background: #f9faff;
    /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15); */
  }
`;

export const IconWRapper = styled.div`
  margin-right: 16px;
`;

export const BalanceInfo = styled.div`
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
  }
  h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const SummaryCard = styled.div<{ bg: string }>`
  min-width: 97.03px;
  height: 117px;
  background: ${({ bg }) =>
    bg
      ? `url(${bg}) no-repeat, #CDCDCD`
      : 'linear-gradient(0deg, #CDCDCD, #CDCDCD), url(.png), #FFFFFF'};
  background-blend-mode: luminosity, normal;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin-right: 25px;
  span {
    max-width: fit-content;
    height: 11.94px;
    border-radius: 0 2px;
    font-weight: 500;
    font-size: 5.57px;
    line-height: 6.75px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #615e5e;
    margin-left: auto;
    padding: 3px 9px;
  }
`;

export const PriceInputWrapper = styled.div<{
  iserror?: string;
  suffix?: string;
}>`
  border: 1px solid
    ${({ iserror }) => (iserror ? '#ff0000' : 'rgba(14, 79, 188, 0.4)')};
  border-radius: 6px;
  background: rgba(14, 79, 188, 0.05);
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  input {
    height: 40px;
    width: 100%;
    color: ${({ iserror }) => (iserror ? '#ff0000' : '#0e4fbc')};
    outline: none;
    padding: 0 22px;
    padding-right: ${({ suffix }) =>
      suffix === 'code' ? '95px' : suffix === 'password' ? '40px' : '16px'};
    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    background-color: transparent;
    &::placeholder {
      color: #0e4fbc;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      opacity: 0.4;
    }
    &:focus {
      background: #fff;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  div {
    position: absolute;
    display: flex;
    width: fit-content;
    cursor: pointer;
    right: 10px;
    &.code {
      width: 83px;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #0c092a;
      opacity: 0.2;
    }

    &.currency {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: ${({ iserror }) => (iserror ? '#ff0000' : '#0e4fbc')};
    }
  }

  &:before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 600;
    color: #333;
    color: #0e4fbc;
  }
`;

export const SubmitButton = styled.button<{
  disabled?: boolean;
  variant?: string;
}>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ variant }) =>
    variant === 'outlined' ? '#fff' : '#0e4fbc'};
  border: 1px solid
    ${({ variant }) => (variant === 'outlined' ? '#000' : '#0e4fbc')};
  /* border-radius: 8px; */
  border-radius: 100px;
  /* background: #0e4fbc; */
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ variant }) => (variant === 'outlined' ? '#000' : '#fff')};
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  & > :not(:first-child) {
    margin-left: 10px;
  }
`;
export const CardTooltip = styled.div<{
  dashbordstyle?: string;
}>`
  position: sticky;
  bottom: 20px;
  right: 20px;
  &.left {
    left: 4px;
    right: auto;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    height: 10px;
  }
`;

export const TooltipContent = styled.div`
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 10px 5px;
  }
  & > div {
    overflow-y: scroll;
    height: 100%;
  }
  position: absolute;
  background-color: #fff;
  right: -10px;
  top: -180px;
  z-index: 10;
  width: 226px;

  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 15px 12px;

  .OptionHeading {
    padding-bottom: 8px;
  }

  .sOption {
    padding: 4px 6px;
    &:hover {
      background: #eff8ff;
    }
  }
`;

export const TooltipItem = styled.div`
  padding: 5px 0;
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const SortButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* width: 51px; */
  max-width: 40px;
  min-width: 40px !important;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #0e4fbc;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
`;
