import React from 'react';
import { AuthFormWrapper, CheckEmailWrapper, ResendEmail } from './styles';
import { checkEmailFormProps } from '../../typeroot/types';

export const CheckEmailForm: React.FC<checkEmailFormProps> = ({
  title,
  desc,
  onResend
}) => {
  return (
    <AuthFormWrapper>
      <CheckEmailWrapper>
        {/* <IconMailbox /> */}
        <img src="/assets/mail.png" alt="" />
        <h3>{title}</h3>
        <p>{desc}</p>
        <ResendEmail>
          {'Didn’t receive an email? '}{' '}
          <span onClick={onResend}>Resend now</span>
        </ResendEmail>
      </CheckEmailWrapper>
    </AuthFormWrapper>
  );
};
