import React, { useEffect, useState } from 'react';
import { AppLayout } from '../../../layout/AppLayout';
import {
  MarketplaceContentWrapper,
  MarketplacePageContainer,
  MarketplacePageWrapper
} from './styles';
import {
  MBuyCardSection,
  MCardGridSection,
  MFilterSection,
  MOfferCardSection,
  MSellCardSection,
  MViewCardSection
} from '../../../modules';
import { CardActionTypes } from '../../../typeroot/types';
import { EmptyCards } from '../../app/category/styles';
import { Button, Loader } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { IMarketplaceListing } from '../../../models/marketplace_listing';
import {
  RequestSearchMarketplaceListingParams,
  getMarketplaceList,
  cardCollection
} from '../../../actions/marketplace_listing';
import { useMyInfoContext, useMyOfferContext } from '../../../context';
import { ToastContainer, toast } from 'react-toastify';
import { loadMoonPay } from '@moonpay/moonpay-js';
import api from '../../../config/api';

export const MarketplacePage: React.FC = () => {
  const navigate = useNavigate();
  const [side, setSide] = useState<CardActionTypes>('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [collection, setCollection] = useState(null);
  const [listings, setListings] = useState<IMarketplaceListing[] | null>(null);
  const { myOfferContext, setMyOfferContext } = useMyOfferContext();
  const { myInfoContext } = useMyInfoContext();
  const [selectedId, setSelectedId] = useState<number | string>('');
  const [selectedNftCollectionId, setSelectedNftCollectionId] =
    useState<number>(1);
  const [selectedNftTypeIds, setSelectedNftTypeIds] = useState<number[]>([
    1, 2, 3, 4, 5
  ]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([0, 1]);
  const [selectedRarity, setSelectedRarity] = useState<number[]>([0, 1, 2]);

  // const showMoonpay = async () => {
  //   let moonPay = await loadMoonPay();
  //   if (moonPay) {
  //     const moonPaySdk = moonPay({
  //       flow: 'nft',
  //       environment: 'sandbox',
  //       variant: 'overlay',
  //       params: {
  //         apiKey: 'pk_test_PaUTi3HVAHvclaZTMJS0TNTfMIrpPj',
  //         contractAddress: '6',
  //         tokenId: '109',
  //         walletAddress: 'test',
  //         signature: ''
  //       },
  //       debug: true
  //     });

  //     if (moonPaySdk) {
  //       const urlForSignature = moonPaySdk.generateUrlForSigning();
  //       api
  //         .post('/webhook/moonpay/sign', { url: urlForSignature })
  //         .then((res) => {
  //           console.log(res);
  //           moonPaySdk.updateSignature(res.data.signature);
  //           moonPaySdk.show();
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       console.log('error showing moonpay');
  //     }
  //   }
  // };

  const handleCardClick = (item: any, action: CardActionTypes) => {
    if (!listings) return;

    let match: any = {
      id: 1
    };

    for (const l of listings) {
      if (item.nft_card_year_id >= 0) {
        if (l.nft_card_year_id === item.nft_card_year_id) {
          match = l;
          break;
        }
      } else if (item.nft_card_day_month_id >= 0) {
        if (l.nft_card_day_month_id === item.nft_card_day_month_id) {
          match = l;
          break;
        }
      } else if (item.nft_card_category_id >= 0) {
        if (l.nft_card_category_id === item.nft_card_category_id) {
          match = l;
          break;
        }
      } else if (item.nft_card_trigger_id >= 0) {
        if (l.nft_card_trigger_id === item.nft_card_trigger_id) {
          match = l;
          break;
        }
      } else if (item.nft_card_crafting_id >= 0) {
        if (l.nft_card_crafting_id === item.nft_card_crafting_id) {
          match = l;
          break;
        }
      }
    }
    setSelectedId(match.id);
    setSelectedItem({ ...item, marketplace_listing_id: match.id });
    setSide(action);
  };

  const handleSideClose = () => {
    setSide('');
    setSelectedId('');
  };

  useEffect(() => {
    // showMoonpay()
  }, []);

  const getPageData = async (params: RequestSearchMarketplaceListingParams) => {
    setIsLoading(true);
    if (selectedNftTypeIds) {
      const response = await getMarketplaceList(params);

      if (response.data) {
        let filteredData = [];

        for (const d of response.data) {
          if (selectedRarity.length > 0) {
            if (selectedRarity.includes(d?.nft_card_day_month?.rarity)) {
              filteredData.push(d);
            }
            if (selectedRarity.includes(d?.nft_card_year?.rarity)) {
              filteredData.push(d);
            }
            if (selectedRarity.includes(d?.nft_card_category?.rarity)) {
              filteredData.push(d);
            }
            if (selectedRarity.includes(d?.nft_card_crafting?.rarity)) {
              filteredData.push(d);
            }
            if (selectedRarity.includes(d?.nft_card_trigger?.rarity)) {
              filteredData.push(d);
            }
          }
        }
        const listedFiltered = filteredData.filter((f) => {
          if (f.is_listed) {
            return f;
          }
        });
        filteredData.length > 0
          ? setListings(listedFiltered)
          : setListings(response.data);
      }
      setIsLoading(false);
      return response.data;
    }
  };
  const getCollection = async () => {
    const response = await cardCollection();
    if (response?.data) {
      let n = response.data.find((i: any) => i.id === selectedNftCollectionId);
      if (n) {
        setCollection(n.name);
      }
    }
  };

  useEffect(() => {
    getPageData({
      card_collection_id: selectedNftCollectionId || 0,
      nft_type_ids: selectedNftTypeIds,
      status: selectedStatus
    });
    getCollection();
  }, [
    selectedStatus,
    selectedNftTypeIds,
    selectedNftCollectionId,
    selectedRarity
  ]);

  const handleOfferConfirm = () => {
    const offerCard = listings?.filter((f) => f.id === Number(selectedId))[0];
    if (offerCard) {
      setMyOfferContext([
        ...myOfferContext,
        {
          ...offerCard,
          buyer: myInfoContext?.username,
          buyer_id: myInfoContext?.id,
          date: Date.now(),
          status: 0
        }
      ]);
      handleSideClose();
    } else {
      toast.error('Something went wrong!!!');
    }
  };

  return (
    <AppLayout>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <MarketplacePageWrapper sidebar={side !== '' ? 'true' : undefined}>
        <MarketplacePageContainer>
          <h2>Cards</h2>
          <MarketplaceContentWrapper>
            <MFilterSection
              onSelectNftCollection={(selected) => {
                setSelectedNftCollectionId(selected);
              }}
              onSelectStatus={(selected) => {
                setSelectedStatus(selected);
              }}
              onSelectRarity={(selected) => {
                setSelectedRarity(selected);
              }}
              onSelectCardTypes={(selected) => {
                setSelectedNftTypeIds(selected);
                getPageData({
                  card_collection_id: selectedNftCollectionId,
                  nft_type_ids: selected,
                  status: selectedStatus
                });
              }}
            />
            {listings && listings?.length > 0 ? (
              <MCardGridSection data={listings} onCardClick={handleCardClick} />
            ) : !isLoading ? (
              <EmptyCards>
                <p style={{ maxWidth: '253px' }}>
                  Wow, can you believe no one wants to sell even a single card?
                </p>
                <Button
                  className="buy-button"
                  onClick={() => navigate('/dashboard/categories')}
                >
                  Sell Card
                </Button>
              </EmptyCards>
            ) : (
              <Loader />
            )}
          </MarketplaceContentWrapper>
        </MarketplacePageContainer>
      </MarketplacePageWrapper>

      {selectedItem && (
        <div id="sideview">
          <MViewCardSection
            open={side === 'view'}
            selectedItem={selectedItem}
            onClose={handleSideClose}
            collection={collection}
          />
          <MBuyCardSection
            open={side === 'buy'}
            selectedItem={selectedItem}
            onClose={handleSideClose}
            data={listings}
            setData={setListings}
            collection={collection}
          />
          <MSellCardSection
            open={side === 'sell'}
            onClose={handleSideClose}
            selectedItem={selectedItem}
            collection={collection}
          />
          <MOfferCardSection
            open={side === 'offer'}
            onConfirm={handleOfferConfirm}
            selectedItem={selectedItem}
            onClose={handleSideClose}
            collection={collection}
          />
        </div>
      )}
    </AppLayout>
  );
};
