import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsivePagination from 'react-responsive-pagination';
import { AppLayout } from '../../../layout/AppLayout';
import {
  CardTitle,
  DashboardCardGrid,
  DashboardCardWrapper,
  DashboardContainer,
  DashboardListGrid,
  DashboardPageWrapper,
  EmptyCardWrapper,
  SeeMoreButton
} from './styles';
import {
  Button,
  FeedItem,
  PredictionCard,
  IdentityCard,
  SellConfirmModal,
  Loader,
  DashboardTitleBG
} from '../../../components';
import { ToastContainer, toast } from 'react-toastify';
import {
  useAuthContext,
  useMonthContext,
  useMyFeedContext,
  useMyOfferContext
} from '../../../context';
import { SellDateCardSection, ViewDateCardSection } from '../../../modules';
import {
  getMarketplaceList,
  newMarketplaceList
} from '../../../actions/marketplace_listing';
import { getMyNftCardIdentity } from '../../../actions/nft_card_identity';
import { INftCardIdentity } from '../../../models/nft_card_identity';
import { INftCardPrediction } from '../../../models/nft_card_prediction';
import { getMyNftCardPrediction } from '../../../actions/nft_card_prediction';
import api from '../../../config/api';
import { IArticle } from '../../../models/article';
import { ClaimSubmitModal } from '../../../components/Modals/ClaimSubmitModal';
import MyOfferCard from '../../../components/MyOfferCard';

export const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { myFeedContext, setMyFeedContext } = useMyFeedContext();
  const { myOfferContext, setMyOfferContext } = useMyOfferContext();
  const { authContext } = useAuthContext();

  const [currentUser, setCurrentUser] = useState<string | null>('');
  const [pageAllFeeds, setPageAllFeeds] = useState<IArticle[]>([]);
  const [afCurrentPage, setAFCurrentPage] = useState(1);
  const [pageMyFeeds, setPageMyFeeds] = useState<IArticle[]>([]);
  const [myCurrentPage, setMYCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isView, setIsView] = useState<'view' | 'sell' | ''>('');
  const [cardType, setCardType] = useState('');

  const [modal, setModal] = useState(false);
  const [isLoadingPrediction, setIsLoadingPrediction] = useState(false);
  const [isLoadingIdentity, setIsLoadingIdentity] = useState(false);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [isLoadingFeed, setIsLoadingFeed] = useState(false);
  const [isLoadingMyFeed, setIsLoadingMyFeed] = useState(false);

  const [identityNfts, setIdentityNfts] = useState<INftCardIdentity[]>([]);
  const [predictionNfts, setPredictionNfts] = useState<INftCardPrediction[]>(
    []
  );
  const [feedData, setFeedData] = useState<IArticle[]>([]);
  const [myFeedData, setMyFeedData] = useState<IArticle[]>([]);

  const { monthContext } = useMonthContext();

  useEffect(() => {
    setCurrentUser(localStorage.getItem('auth'));
  }, [authContext]);

  useEffect(() => {
    setPageAllFeeds(feedData.slice(0, 5));
  }, [feedData]);

  useEffect(() => {
    setPageMyFeeds(myFeedData?.slice(0, 5));
    setMyFeedContext(myFeedData);
  }, [myFeedData]);

  const handlePagination = (number: number, key: string) => {
    if (key === 'all') {
      setAFCurrentPage(number);
      setPageAllFeeds(feedData.slice(5 * (number - 1), 5 * (number - 1) + 5));
    } else if (key === 'my') {
      setMYCurrentPage(number);
      setPageMyFeeds(myFeedData.slice(5 * (number - 1), 5 * (number - 1) + 5));
    }
  };

  const handleSellConfirm = async (
    id: number | string,
    collection_id: string | number,
    price: string | number
  ) => {
    const newMarketplace = {
      card_collection_id: collection_id,
      nft_type_id: cardType === 'prediction' ? 7 : 6,
      nft_card_identity_id: cardType === 'identity' ? id : null,
      nft_card_prediction_id: cardType === 'prediction' ? id : null,
      price: Math.round(
        (typeof price === 'string' ? parseFloat(price) : price) * 100
      )
    };
    const response = await newMarketplaceList(newMarketplace);

    setModal(true);
    setIsView('');
  };

  const handleView = (item: any) => {
    setSelectedItem(item);
    setCardType(item?.triggers ? 'prediction' : 'identity');
    setIsView('view');
  };

  const handleSell = (item: any) => {
    setSelectedItem(item);
    setCardType(item?.triggers ? 'prediction' : 'identity');
    setIsView('sell');
  };

  const handleCraft = (item: any) => {
    if (item?.id) navigate(`/crafting/predictions?id=${item.id}&type=identity`);
  };

  const loadIdentities = async () => {
    setIsLoadingIdentity(true);
    let p_resp = await getMyNftCardIdentity(null);
    if (p_resp?.data) {
      p_resp.data.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      setIdentityNfts(p_resp.data);
    }
    setIsLoadingIdentity(false);
  };

  const loadPredictions = async () => {
    setIsLoadingPrediction(true);
    let p_resp = await getMyNftCardPrediction(null);
    if (p_resp?.data) {
      setPredictionNfts(p_resp.data);
    }
    setIsLoadingPrediction(false);
  };

  const loadOffers = async () => {
    setIsLoadingOffers(true);

    let offers: any = [];

    try {
      const marketplaceOffer = await api.get('/me/marketplace_offer_by_owner');
      const marketplaceListing = await getMarketplaceList({
        card_collection_id: 1,
        limit: 100,
        offset: 0,
        nft_type_ids: [1, 2, 3, 4, 5, 6, 7],
        rarity: [0, 1, 2],
        status: [0, 1]
      });
      //  fetch user here

      if (marketplaceListing && marketplaceOffer) {
        for (const offer of marketplaceOffer.data) {
          for (const listing of marketplaceListing.data) {
            if (offer.marketplace_listing_id === listing.id) {
              offers.push({
                ...listing,
                buyer_id: offer.buyer_id,
                marketplace_offer_id: offer.id,
                amount: offer.amount,
                status: offer
              });
            }
          }
          // match user.id to buyer_id
        }
        setMyOfferContext(offers);
      }
    } catch (e: any) {
      toast.error(e);
    }

    setIsLoadingOffers(false);
  };

  const loadFeed = async () => {
    setIsLoadingFeed(true);

    try {
      let res = await api.get('/feed');
      if (res.data) {
        setFeedData(res.data);
      }
    } catch (e: any) {
      toast.error(e);
    }

    setIsLoadingFeed(false);
  };

  const loadMyFeed = async () => {
    setIsLoadingMyFeed(true);

    try {
      let res = await api.get('/feed/personalised');
      if (res.data) {
        const filtered = res.data.filter((item: any) => {
          if (item?.created_at) {
            return item;
          }
        });
        setMyFeedData(filtered);
      }
    } catch (e: any) {
      toast.error(e);
    }

    setIsLoadingMyFeed(false);
  };

  const loadNfts = async () => {
    loadIdentities();
    loadPredictions();
    loadFeed();
    loadMyFeed();
    loadOffers();
  };

  useEffect(() => {
    if (localStorage.getItem('auth')) {
      loadNfts();
    }
  }, []);

  // const [showingMoonpay, setShowingMoonpay] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!showingMoonpay && typeof window !== 'undefined' && typeof window.MoonPayWebSdk !== 'undefined') {
  //     const moonpaySdk = window.MoonPayWebSdk.init({
  //       flow: 'nft',
  //       environment: 'sandbox',
  //       variant: 'overlay',
  //       params: {
  //         apiKey: 'pk_test_PaUTi3HVAHvclaZTMJS0TNTfMIrpPj',
  //         contractAddress: 'category',
  //         tokenId: '__14'
  //       }
  //     }
  //     );
  //     console.log("moonpay initialised")
  //     moonpaySdk.show()
  //     setShowingMoonpay(true)
  //   }
  // }, [window.MoonPayWebSdk])

  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [cardPrediction, setCardPrediction] = useState<INftCardPrediction>();

  const onClickSubmitClaim = (cardPrediction: INftCardPrediction) => {
    if (cardPrediction.nft_card_triggers?.length) {
      setCardPrediction(cardPrediction);
      setOpenClaimModal(true);
    }
  };

  return (
    <AppLayout>
      <SellConfirmModal
        open={modal}
        onClose={() => setModal(false)}
        key="sell-confirm-modal"
      />
      <ClaimSubmitModal
        open={openClaimModal}
        onClose={() => setOpenClaimModal(false)}
        cardPrediction={cardPrediction}
        key="claim-submit-modal"
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <DashboardPageWrapper>
        {/* <>
          {myOfferContext.length > 0 && (
            <DashboardCardWrapper>
              <CardTitle>
                <div>
                  <DashboardTitleBG />
                </div>
                <span>My Offers</span>
              </CardTitle>
              <DashboardContainer>
                {myOfferContext?.length > 0 && currentUser ? (
                  <>
                    <SeeMoreButton
                      onClick={() => navigate('/dashboard/myoffer')}
                    >
                      See More
                    </SeeMoreButton>
                    <DashboardCardGrid>
                      {myOfferContext.map((item: any, key: number) => {
                        if (key < 4) {
                          return (
                            <MyOfferCard
                              loadOffers={loadOffers}
                              item={item}
                              key={key}
                              isView={isView}
                              onView={handleView}
                            />
                          );
                        }
                        return null;
                      })}
                    </DashboardCardGrid>

                    <ViewDateCardSection
                      isView={isView === 'view'}
                      cardType={cardType}
                      item={selectedItem}
                      onClose={() => {
                        setIsView('');
                      }}
                    />
                    <SellDateCardSection
                      onSellConfirm={handleSellConfirm}
                      cardType={cardType}
                      isView={isView === 'sell'}
                      item={selectedItem}
                      onClose={() => {
                        setIsView('');
                      }}
                    />
                  </>
                ) : !isLoadingIdentity ? (
                  <EmptyCardWrapper>
                    <p>
                      Combine a Year card, a Day & Month, and a Category card to
                      craft an Identity.
                    </p>
                    <img src="/assets/identities-empty.png" alt="" />
                    {currentUser && (
                      <Button
                        className="dashboard-card-button"
                        onClick={() => navigate('/crafting/identities')}
                      >
                        Craft an Identity Now
                      </Button>
                    )}
                  </EmptyCardWrapper>
                ) : (
                  <Loader />
                )}
              </DashboardContainer>
            </DashboardCardWrapper>
          )}
        </> */}
        <DashboardCardWrapper>
          <CardTitle>
            <div>
              <DashboardTitleBG />
            </div>
            <span>My Identities</span>
          </CardTitle>
          <DashboardContainer>
            {identityNfts?.length > 0 && currentUser && monthContext ? (
              <React.Fragment>
                <SeeMoreButton
                  onClick={() => navigate('/dashboard/identities')}
                >
                  See More
                </SeeMoreButton>
                <DashboardCardGrid>
                  {/* {identityNfts */}
                  {identityNfts
                    ?.slice(0, 4) //////////////////// Have to add some filter by collection id
                    .map((item: any, key: number) => {
                      return (
                        <IdentityCard
                          dashbordstyle={'true'}
                          height={'225'}
                          isNotHover={true}
                          day={item.day}
                          month={item.month}
                          year={item.year}
                          rarity={item.rarity}
                          item={item}
                          {...item}
                          key={key}
                          onSell={handleSell}
                          cardType="identity"
                          onView={handleView}
                          onCraft={handleCraft}
                        />
                      );
                    })}
                </DashboardCardGrid>

                <ViewDateCardSection
                  isView={isView === 'view'}
                  cardType={cardType}
                  item={selectedItem}
                  onClose={() => {
                    setIsView('');
                  }}
                />
                <SellDateCardSection
                  onSellConfirm={handleSellConfirm}
                  cardType={cardType}
                  isView={isView === 'sell'}
                  item={selectedItem}
                  onClose={() => {
                    setIsView('');
                  }}
                />
              </React.Fragment>
            ) : !isLoadingIdentity ? (
              <EmptyCardWrapper>
                <p>
                  Combine a Year card, a Day & Month, and a Category card to
                  craft an Identity.
                </p>
                <img src="/assets/identities-empty.png" alt="" />
                {currentUser && (
                  <Button
                    className="dashboard-card-button"
                    onClick={() => navigate('/crafting/identities')}
                  >
                    Craft an Identity Now
                  </Button>
                )}
              </EmptyCardWrapper>
            ) : (
              <Loader />
            )}
          </DashboardContainer>
        </DashboardCardWrapper>
        <DashboardCardWrapper>
          <CardTitle>
            <div>
              <DashboardTitleBG />
            </div>
            <span>My Predictions</span>
          </CardTitle>
          <DashboardContainer>
            {predictionNfts.length > 0 && currentUser ? (
              <React.Fragment>
                <SeeMoreButton
                  onClick={() => navigate('/dashboard/predictions')}
                >
                  See More
                </SeeMoreButton>
                <DashboardCardGrid>
                  {predictionNfts
                    ?.slice(0, 4) //////////////////// Have to add some filter by collection id
                    .map((item: any, key: number) => {
                      return (
                        <PredictionCard
                          dashbordstyle={'true'}
                          height={'225'}
                          isNotHover={true}
                          item={item}
                          {...item}
                          rarity={item.rarity}
                          key={key}
                          onSell={handleSell}
                          cardType="prediction"
                          onView={handleView}
                          onClaimSubmit={() => {
                            onClickSubmitClaim(item);
                          }}
                        />
                      );
                    })}
                </DashboardCardGrid>
              </React.Fragment>
            ) : !isLoadingPrediction ? (
              <EmptyCardWrapper>
                <p>
                  Add one or more Triggers to an Identity to craft a Prediction
                </p>
                <img src="/assets/prediction-empty.png" alt="" />
                {currentUser && (
                  <Button
                    className="dashboard-card-button"
                    onClick={() => navigate('/crafting/predictions')}
                  >
                    Craft a Prediction Now
                  </Button>
                )}
              </EmptyCardWrapper>
            ) : (
              <Loader />
            )}
          </DashboardContainer>
        </DashboardCardWrapper>
        {myFeedData?.length > 0 ? (
          <DashboardCardWrapper>
            <CardTitle>
              <div>
                <DashboardTitleBG />
              </div>
              <span>My Feed</span>
            </CardTitle>
            <DashboardContainer>
              {pageMyFeeds?.length > 0 && (
                <DashboardListGrid>
                  {pageMyFeeds.map((item, key) => {
                    if (!item?.created_at) {
                      return null;
                    }
                    return <FeedItem {...item} key={key} />;
                  })}
                </DashboardListGrid>
              )}
              <ResponsivePagination
                maxWidth={272}
                current={myCurrentPage}
                total={Math.ceil(Number(myFeedData?.length / 5))}
                onPageChange={(page) => handlePagination(page, 'my')}
              />
            </DashboardContainer>
          </DashboardCardWrapper>
        ) : (
          <DashboardCardWrapper>
            <CardTitle>
              <div>
                <DashboardTitleBG />
              </div>
              <span>My Feed</span>
            </CardTitle>
            <DashboardContainer>
              <DashboardListGrid>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 600,
                    paddingTop: '24px'
                    // color: 'red'
                  }}
                >
                  No articles match the identities in your inventory.
                </div>
              </DashboardListGrid>

              <ResponsivePagination
                maxWidth={272}
                current={myCurrentPage}
                total={Math.ceil(Number(myFeedData?.length / 5))}
                onPageChange={(page) => handlePagination(page, 'my')}
              />
            </DashboardContainer>
          </DashboardCardWrapper>
        )}
        {/* {currentUser && feedData?.length > 0 && ( */}
        {feedData?.length > 0 && (
          <DashboardCardWrapper>
            <CardTitle>
              <div>
                <DashboardTitleBG />
              </div>
              <span>TwoTwentyK News Feed</span>
            </CardTitle>
            <DashboardContainer>
              <DashboardListGrid>
                {pageAllFeeds?.map((item, key) => {
                  return <FeedItem {...item} key={key} />;
                })}
              </DashboardListGrid>
              <ResponsivePagination
                maxWidth={272}
                current={afCurrentPage}
                total={Math.ceil(Number(feedData.length / 5))}
                onPageChange={(page) => handlePagination(page, 'all')}
              />
            </DashboardContainer>
          </DashboardCardWrapper>
        )}
        {!authContext?.isAuthenticated && (
          <Button className="login-button" onClick={() => navigate('/signin')}>
            Login Now
          </Button>
        )}
      </DashboardPageWrapper>
    </AppLayout>
  );
};
