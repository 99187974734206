import React from 'react';
import { AppLayout } from '../../layout/AppLayout';
import { BuyPackSection } from '../../modules';

export const BuyPackPage: React.FC = () => {
  return (
    <AppLayout>
      <BuyPackSection />
    </AppLayout>
  );
};
