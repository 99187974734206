import React, { useEffect, useState } from 'react';
import {
  AuthFormDesc,
  AuthFormSubTitle,
  AuthFormWrapper,
  Center,
  FormActionWrapper
} from './styles';
import { AuthFormGroup, AuthFormTitle, Button, Input } from '../../components';
import { resetPassFormValidation } from '../../utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../../config/api';

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/signin');
    }
  }, [params, token, navigate]);

  const [form, setForm] = useState({ password: '', rPassword: '' });
  const [error, setError] = useState({ password: '', rPassword: '' });
  const [success, setSuccess] = useState(false);

  const handleClick = async () => {
    const { isValid, errors } = resetPassFormValidation(form);
    setError(errors);
    
    if (isValid) {
      try {
        const res = await api.post(`/auth/reset_password/${token}`, {
          password: form.password
        });
        setSuccess(true);
        return { success: res.data.success };
      } catch (error) {
        return { success: false, message: 'Server Error!' };
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <AuthFormWrapper>
      {success ? (
        <>
          <AuthFormTitle>Create a new password</AuthFormTitle>
          <Center>
            <AuthFormSubTitle>Success!</AuthFormSubTitle>
            <AuthFormDesc>Please login with your new password.</AuthFormDesc>
          </Center>
        </>
      ) : (
        <>
          <AuthFormTitle>Create a new password</AuthFormTitle>
          <AuthFormDesc>Please enter your new password</AuthFormDesc>
          <AuthFormGroup>
            <Input
              label="Password"
              name="password"
              error={error.password}
              onChange={handleChange}
              value={form.password}
              type="password"
              placeholder="Enter password"
            />
            <Input
              label="Confirm Password"
              name="rPassword"
              error={error.rPassword}
              onChange={handleChange}
              value={form.rPassword}
              type="password"
              placeholder="Enter password"
            />
          </AuthFormGroup>
        </>
      )}
      <FormActionWrapper>
        {success ? (
          <Button onClick={() => navigate('/signin')}>Login</Button>
        ) : (
          <Button onClick={handleClick}>Reset</Button>
        )}
      </FormActionWrapper>
    </AuthFormWrapper>
  );
};
