import React, { useState } from 'react';
import {
  BackButton,
  BuyCounterWrapper,
  BuyDetailsContainer,
  BuyDetailsWrapper,
  Counter,
  PaymentMethodWrapper, SummaryContent,
  SummaryWrapper,
  TotalPrice
} from './styles';
import { BuyDetailsProps } from '../../typeroot/types';
import {
  CloseButton,
  PropertiesContent,
  PropertiesHeader,
  PropertiesWrapper,
  PropertyItem
} from '../app/dates/styles';
import {
  BalanceBuyConfirmModal,
  Button,
  BuyCard,
  BuyPackConfirmModal,
  IconArrowDown,
  IconArrowUp,
  IconCoins,
  IconPay,
  UseBalanceBuyModal
} from '../../components';
import { set } from 'react-hook-form';

export const BuyDetailsSection: React.FC<BuyDetailsProps> = ({
  id,
  isView,
  onClose,
  cardSeries,
  rarity,
  onBuyClick,
  onMoonpayClick
}) => {
  const [step, setStep] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [balanceConfirm, setBalanceConfirm] = useState(false);
  const [useBalance, setUseBalance] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [showHideProp, setShowHideProp] = useState<boolean>(true);
  const [showHideSummary, setShowHideSummary] = useState<boolean>(true);
  const [collapsedTwo, setCollapsedTwo] = useState<boolean>(false);

  const toggleShowHideProp = () => setShowHideProp(!showHideProp);
  const toggleShowHideSummary = () => setShowHideSummary(!showHideSummary);
  const toggleCollapsedTwo = () => setCollapsedTwo(!collapsedTwo);


  interface CardSeries {
    guaranteed?: {
      '0'?: CardDetails;
      '1'?: CardDetails;
      '2'?: CardDetails;
    };
  }

  interface CardDetails {
    category?: string;
    day_month?: string;
    trigger?: {
      length: number;
    };
    year?: number;
  }

  const cardSeriesNew: CardSeries = cardSeries;

  const coreCardInfo =
    (Number(cardSeriesNew.guaranteed?.['0']?.category) || 0) +
    (Number(cardSeriesNew.guaranteed?.['0']?.day_month) || 0) +
    (cardSeriesNew.guaranteed?.['0']?.trigger?.length || 0) +
    (Number(cardSeriesNew.guaranteed?.['0']?.year) || 0);

  const uncommonCardInfo =
    (Number(cardSeriesNew.guaranteed?.['1']?.category) || 0) +
    (Number(cardSeriesNew.guaranteed?.['1']?.day_month) || 0) +
    (cardSeriesNew.guaranteed?.['1']?.trigger?.length || 0) +
    (Number(cardSeriesNew.guaranteed?.['1']?.year) || 0);

  const rareCardInfo =
    (Number(cardSeriesNew.guaranteed?.['2']?.category) || 0) +
    (Number(cardSeriesNew.guaranteed?.['2']?.day_month) || 0) +
    (cardSeriesNew.guaranteed?.['2']?.trigger?.length || 0) +
    (Number(cardSeriesNew.guaranteed?.['2']?.year) || 0);

  const handleBuy = () => {
    if (step === 0) {
      setStep((prev) => prev + 1);
    }
  };

  const handleBuyConfirm = () => {
    setBalanceConfirm(false);
    setConfirm(true);
  };

  const handleBalanceBuy = () => {
    setBalanceConfirm(true);
    setUseBalance(false);
  };

  const handleUseBalance = () => {
    setStep(0);
    setUseBalance(true);
  };

  const handleFromAccount = () => {
    try {
      onMoonpayClick();
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmClose = () => {
    setConfirm(false);
    setStep(0);
    onClose();
  };

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

  return (
    <BuyDetailsWrapper
      style={{ overflowY: isView ? 'hidden' : 'auto' }}
      isview={isView ? 'true' : undefined}
    >
      {step === 0 && (
        <BuyDetailsContainer>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <BuyCard
            cardType={cardSeries.name}
            price={50}
            preview={true}
            onCardClick={() => {}}
            cardSeries={cardSeries}
            rarity={rarity}
          />
          <PropertiesWrapper>
            <PropertiesHeader onClick={toggleShowHideProp}>
              <span>Properties</span>
              {showHideProp ? <IconArrowDown /> : <IconArrowUp />}
            </PropertiesHeader>
              {showHideProp && (
            <PropertiesContent>
              <PropertyItem>
                <p>Pack Rarity</p>
                <span>{cardSeries.name}</span>
              </PropertyItem>
              <PropertyItem>
                <p>Collection</p>
                <span>{cardSeries.card_collection?.name}</span>
              </PropertyItem>
            </PropertiesContent>
              )}
          </PropertiesWrapper>
          <PropertiesWrapper>
            <PropertiesHeader onClick={toggleCollapsedTwo}>
              <span>Pack Contents</span>
              <IconArrowDown />
            </PropertiesHeader>
            {collapsedTwo && (
            <PropertiesContent>
              <PropertyItem>
                <p>{rareCardInfo} Rare Cards</p>
              </PropertyItem>
              <PropertyItem>
                <p>{uncommonCardInfo} Uncommon Cards</p>
              </PropertyItem>
              <PropertyItem>
                <p>{coreCardInfo} Core Cards</p>
              </PropertyItem>
              <PropertyItem>
                <p>{cardSeries.crafting_cards} Crafting Card</p>
              </PropertyItem>
            </PropertiesContent>
            )}
          </PropertiesWrapper>

          <BuyCounterWrapper>
            <p>
              Price <span>${(cardSeries.cost_usd / 100).toFixed(2)} USD</span>
            </p>
            <Counter>
              <div
                onClick={() => {
                  if (quantity > 1) setQuantity(quantity - 1);
                }}
              >
                -
              </div>
              <span>{quantity}</span>
              <div
                onClick={() => {
                  setQuantity(quantity + 1);
                }}
              >
                +
              </div>
            </Counter>
          </BuyCounterWrapper>

          <PropertiesWrapper>
            <PropertiesHeader onClick={toggleShowHideSummary}>
              <span>Price Summary</span>
              {showHideSummary ? <IconArrowDown /> : <IconArrowUp />}
            </PropertiesHeader>
            {showHideSummary && (
            <PropertiesContent>
              <PropertyItem>
                <p>Asking price</p>
                <span>${(cardSeries.cost_usd / 100).toFixed(2)} USD</span>
              </PropertyItem>
              <PropertyItem>
                <p>Quantity</p>
                <span>{quantity}</span>
              </PropertyItem>
            </PropertiesContent>
            )}
          </PropertiesWrapper>
          <TotalPrice>
            <p>Total</p>
            <span>${((quantity * cardSeries.cost_usd) / 100).toFixed(2)}</span>
          </TotalPrice>
          <Button className="buy-confirm-button" onClick={handleBuy}>
            Buy Now
          </Button>
        </BuyDetailsContainer>
      )}
      {step === 1 && (
        <BuyDetailsContainer>
          <BackButton onClick={() => setStep(0)}>{'< Back'}</BackButton>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <SummaryWrapper>
            <h3>Summary</h3>
            <SummaryContent>
              <img src="/assets/buy.png" alt="" />
              <div>
                <div>
                  <p>{capitalizeFirstLetter(cardSeries?.name ?? '')}</p>
                  <span>Qty: {quantity}</span>
                </div>
                <div>
                  <p>Total Price</p>
                  <span>
                    ${((quantity * cardSeries.cost_usd) / 100).toFixed(2)} USD
                  </span>
                </div>
              </div>
            </SummaryContent>
          </SummaryWrapper>
          <PaymentMethodWrapper>
            <h3>Payment Method</h3>
            <Button className="pay-button" onClick={handleUseBalance}>
              <IconCoins />
              <span>Use my TwoTwentyK Balance</span>
            </Button>
            <Button className="pay-button" onClick={handleFromAccount}>
              <IconPay />
              <span>Top my wallet</span>
            </Button>
          </PaymentMethodWrapper>
        </BuyDetailsContainer>
      )}
      <BuyPackConfirmModal open={confirm} onClose={handleConfirmClose} />
      <UseBalanceBuyModal
        onBuyClick={handleBalanceBuy}
        open={useBalance}
        onClose={() => setUseBalance(false)}
        price={((quantity * cardSeries.cost_usd) / 100).toFixed(2)}
      />
      <BalanceBuyConfirmModal
        price={((quantity * cardSeries.cost_usd) / 100).toFixed(2)}
        onConfirm={() => {
          onBuyClick(cardSeries, quantity, 1);
          setBalanceConfirm(false);
        }}
        open={balanceConfirm}
        onClose={() => setBalanceConfirm(false)}
      />
    </BuyDetailsWrapper>
  );
};
