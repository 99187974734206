import React, { useEffect, useState } from 'react';
import {
  CraftIdentityModalProps,
  SelectOptionProps
} from '../../typeroot/types';
import { Modal as ModalWrapper } from './Modal';
import { ModalHeader } from './ModalHeader';

import {
  ButtonGroup,
  CraftIdentifyModalWrapper,
  CraftIdentifyModalHeader,
  Checkbox,
  CheckboxWrapper,
  SelectedCardWrapper,
  SelectedCraftCard,
  SelectedCraftCardWrapper,
  RarityTextBox
} from './styles';
import { Button } from '../Button';
import { useCelebritiesContext, useMonthContext } from '../../context';
import { ICelebrity } from '../../models/celebrity';
import { SelectOption } from '../SelectBox/SelectOption';
import {
  checkRarity,
  formatCategory,
  toCapitalCase
} from '../../utils/helperFunctions';

const Card = ({ cardType, selectedCard, heading }: any) => {
  const { monthContext } = useMonthContext();
  return (
    <SelectedCraftCardWrapper key={cardType}>
      <h6
        // style={{
        //   bottom: '12px',
        // }}
      >
        {heading}
      </h6>

      <SelectedCraftCard className="crafting-card">
        {cardType === 'category' ? (
          <img
            src={`/assets/nfts/rarity/${formatCategory(
              selectedCard.category
            )}-${checkRarity(selectedCard.rarity)}.png`}
            alt="nft"
          />
        ) : cardType === 'dayMonth' ? (
          <img
            src={`/assets/nfts/rarity/Month-Day-${checkRarity(
              selectedCard.rarity
            )}-copy.png`}
            alt="nft"
          />
        ) : (
          <img
            src={`/assets/nfts/rarity/${toCapitalCase(cardType)}-${checkRarity(
              selectedCard.rarity
            )}-copy.png`}
            alt="nft"
          />
        )}
        <div
          className="info-nft info-nft-day-month crafting"
          style={{ fontSize: '1.5rem' }}
        >
          {cardType === 'dayMonth' &&
            selectedCard.day &&
            selectedCard.month &&
            monthContext && (
              <h3 className={checkRarity(selectedCard.rarity)}>
                {String(selectedCard.month).padStart(2, '0')}.
                {String(selectedCard.day).padStart(2, '0')}
              </h3>
            )}
          {cardType === 'year' && selectedCard.year && (
            <h3 className={checkRarity(selectedCard.rarity)}>
              {selectedCard.year}
            </h3>
          )}
        </div>
      </SelectedCraftCard>
    </SelectedCraftCardWrapper>
  );
};

export const CraftIdentityModal: React.FC<CraftIdentityModalProps> = ({
  open,
  onClose,
  onCraft,
  selectedCards,
  selectCelebrity,
  selectedCelebrity,
  rarity
}) => {
  const { celebritiesContext } = useCelebritiesContext();

  // const navigate = useNavigate();

  const [checked, setChecked] = useState<boolean>(false);
  const [matches, setMatches] = useState<ICelebrity[]>([]);
  const [optionSelected, setOptionSelected] = useState<boolean>(false);
  const [clearSelect] = useState<boolean>(false);
  const [crafted, setCrafted] = useState<boolean>(false);
  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const handleCraft = () => {
    setCrafted(true);
    return onCraft();
  };

  const chooseCelebrity = (v: SelectOptionProps) => {
    if (v.value === '0') {
      selectCelebrity(null);
      setOptionSelected(true);
    }

    let c = (celebritiesContext as Map<number, ICelebrity>).get(
      Number(v.value)
    );
    if (c) {
      selectCelebrity(c);
      setOptionSelected(true);
    }
  };

  useEffect(() => {
    setChecked(false);
    setOptionSelected(false);

    let celebrities: ICelebrity[] = [];
    if (celebritiesContext) {
      Array.from<[number, ICelebrity]>(celebritiesContext).map(
        ([key, value]) => {
          if (
            value.birth_year === selectedCards.year?.year &&
            value.birth_day === selectedCards.dayMonth?.day &&
            value.birth_month === selectedCards.dayMonth?.month &&
            value.category === selectedCards.category?.category
          )
            celebrities.push(value);
        }
      );
    }
    const uniqueCelebs = celebrities.filter(
      (celeb, index, self) => index === self.findIndex((c) => c.id === celeb.id)
    );
    setMatches(uniqueCelebs);
  }, [
    open,
    celebritiesContext,
    selectedCards.dayMonth?.day,
    selectedCards.dayMonth?.month,
    selectedCards.year?.year,
    selectedCards.category?.category
  ]);

  useEffect(() => {
    setOptions([
      { label: 'None', value: '0' },
      ...matches.map((v) => {
        return { label: v.name, value: String(v.id) };
      })
    ]);
  }, [matches]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      width={750}
      modalHeader={true}
      paddingClass="removePadding"
    >
      <ModalHeader bg={'#F2F5FF'} onClose={onClose}>
        <CraftIdentifyModalHeader>
          <p>Would you like to assign a name to your Identity?</p>
          <SelectOption
            options={options}
            placeholder="Select matching Identity"
            onSelect={chooseCelebrity}
            clear={clearSelect}
          />
        </CraftIdentifyModalHeader>
      </ModalHeader>

      {selectedCards.dayMonth !== null &&
        selectedCards.year !== null &&
        selectedCards.category !== null &&
        selectedCards.crafting !== null && (
          <SelectedCardWrapper>
            <Card
              cardType="dayMonth"
              heading="Month/Day"
              selectedCard={selectedCards.dayMonth}
            />
            <Card
              cardType="year"
              heading="year"
              selectedCard={selectedCards.year}
            />
            <Card
              cardType="category"
              heading="Category"
              selectedCard={selectedCards.category}
            />
            <Card
              cardType="crafting"
              heading="Crafting"
              selectedCard={selectedCards.crafting}
            />
          </SelectedCardWrapper>
        )}
      {matches.length > 0 && optionSelected && (
        <>
          {selectedCelebrity !== null && (
            <RarityTextBox>
              Identity Rarity will be:{' '}
              {rarity === 0 && <span className="core">Core</span>}
              {rarity === 1 && <span className="uncommon">Uncommon</span>}
              {rarity === 2 && <span className="rare">Rare</span>}
            </RarityTextBox>
          )}
        </>
      )}

      {/* <>
        {monthContext && (
          <CardsWrapper>
            <CardGridWrapper>
              <CraftingCardWrapper key={'dayMonth'}>
                <CraftCard onClick={() => {}} bg={'/assets/nfts/2.png'}>
                  {selectedCards.crafting?.rarity === 0 && <span>Core</span>}
                  {selectedCards.crafting?.rarity === 1 && (
                    <span>Uncommon</span>
                  )}
                  {selectedCards.crafting?.rarity === 2 && <span>Rare</span>}
                  <p>
                    {selectedCards.dayMonth?.day}{' '}
                    {(monthContext as Map<number, string>).get(
                      selectedCards.dayMonth?.month
                        ? selectedCards.dayMonth?.month
                        : 1
                    )}
                  </p>
                </CraftCard>
              </CraftingCardWrapper>
              <CraftingCardWrapper key={'year'}>
                <CraftCard onClick={() => {}} bg={'/assets/nfts/2.png'}>
                  {selectedCards.year?.rarity === 0 && <span>Core</span>}
                  {selectedCards.year?.rarity === 1 && <span>Uncommon</span>}
                  {selectedCards.year?.rarity === 2 && <span>Rare</span>}
                  <p>{selectedCards.year?.year}</p>
                </CraftCard>
              </CraftingCardWrapper>
              <CraftingCardWrapper key={'category'}>
                <CraftCard onClick={() => {}} bg={'/assets/nfts/2.png'}>
                  {selectedCards.category?.rarity === 0 && <span>Core</span>}
                  {selectedCards.category?.rarity === 1 && (
                    <span>Uncommon</span>
                  )}
                  {selectedCards.category?.rarity === 2 && <span>Rare</span>}
                  <p>{selectedCards.category?.category}</p>
                </CraftCard>
              </CraftingCardWrapper>
            </CardGridWrapper>
            {matches.length > 0 && !optionSelected && (
              <h3>Please select a matching Identity from the list.</h3>
            )}
            {matches.length > 0 && optionSelected && (
              <>
                {selectedCelebrity !== null && (
                  <RarityTextBox>
                    Identity Rarity will be:{' '}
                    {rarity === 0 && <span className="core">Core</span>}
                    {rarity === 1 && <span className="uncommon">Uncommon</span>}
                    {rarity === 2 && <span className="rarity">Rare</span>}
                  </RarityTextBox>
                )}
                <h3>
                  Your cards will be sliced and diced to create this Identity.
                </h3>
              </>
            )}
            {matches.length === 0 && (
              <h3>
                There are no matching identities for the selected combination of
                cards therefore is it not possible to craft.
              </h3>
            )}
          </CardsWrapper>
        )}
      </> */}
      <CraftIdentifyModalWrapper>
        <ButtonGroup>
          <CheckboxWrapper>
            <Checkbox>
              <input
                id={'crafting'}
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label htmlFor={'crafting'}></label>
            </Checkbox>
            <span>
              Creating this Identity will burn all selected cards. This process
              is irreversible.
            </span>
          </CheckboxWrapper>

          <Button
            disabled={
              !checked || !optionSelected || matches.length === 0 || crafted
            }
            onClick={handleCraft}
          >
            {crafted ? 'Crafting..' : 'Craft Identity'}
          </Button>
          <Button variant={'outlined'} onClick={onClose}>
            No Thanks
          </Button>
        </ButtonGroup>
      </CraftIdentifyModalWrapper>
    </ModalWrapper>
  );
};
