import React from 'react';
import AppleLogin from 'react-apple-login';
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import FacebookLogin from "react-facebook-login";
// import GoogleLogin from "react-google-login";
import { AuthDividerWrapper, SocialButtonsWrapper } from './styles';
import { SocialAuthButton } from '../SocialAuthButton';
import { SocialButtonsGroupProps } from '../../typeroot/types';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getMyInfo } from '../../actions';
import { useAuthContext, useMyInfoContext } from '../../context';
import { initializeWebSocket } from '../../socket/socket';

export const SocialButtonsGroup: React.FC<SocialButtonsGroupProps> = ({
  authType
}) => {
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID as string;
  // const { onGoogleAuthClicked, onAppleAuthClicked, onFacebookAuthClicked } =
  //   useSocialAuth();
  const { setAuthContext } = useAuthContext();
  const { setMyInfoContext } = useMyInfoContext();

  const handleAppleAuth = async (res: any) => {
    try {
      let resp = await api.post('/auth/apple', {
        id_token: res.authorization.id_token
      });
      console.log('apple resp', resp);
      if (resp) {
        if (resp.data.token) {
          api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${resp.data.token}`;
          setAuthContext({
            isAuthenticated: true,
            user: resp.data.token
          });
          const myinfo = await getMyInfo();
          if (myinfo.data) setMyInfoContext(myinfo.data);
          navigate('/dashboard');
        } else {
          throw new Error('No token in API response');
        }
      }
    } catch (e: any) {
      console.log('err', e);
      toast.error(e);
    }
  };

  const navigate = useNavigate();

  const handleGoogleAuth = async (res: any) => {
    try {
      let resp = await api.post('/auth/google', { id_token: res.credential });
      console.log('google resp', resp);
      if (resp) {
        if (resp.data.token) {
          localStorage.setItem('auth', resp.data.token);
          initializeWebSocket();
          api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${resp.data.token}`;
          setAuthContext({
            isAuthenticated: true,
            user: resp.data.token
          });
          const myinfo = await getMyInfo();
          if (myinfo.data) setMyInfoContext(myinfo.data);

          navigate('/dashboard');
        } else {
          throw new Error('No token in API response');
        }
      }
    } catch (e: any) {
      toast.error(e);
    }
  };

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => console.log(tokenResponse),
  //   onError: () => console.log("Login Failed"),
  // });

  // const loadScript = (
  //   src: string,
  //   id: string,
  //   onload: () => void = () => {}
  // ) => {
  //   const existingScript = document.getElementById(id);

  //   if (existingScript != null) return;

  //   const script = document.createElement('script');
  //   script.id = id;
  //   script.src = src;
  //   script.defer = true;
  //   script.async = true;
  //   script.onload = onload;

  //   document.head.appendChild(script);
  // };

  const handleGoogleClick = () => {
    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleGoogleAuth,
      ux_mode: 'redirect'
    });

    google.accounts.id.prompt();
  };

  return (
    <SocialButtonsWrapper>
      {/* <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        
      /> */}
      <SocialAuthButton
        authType={authType}
        socialType="Google"
        onClick={handleGoogleClick}
      />
      {/* <FacebookLogin
        appId="1206500170741412"
        // autoLoad={true}
        fields="name,email,picture"
        callback={(res) => onFacebookAuthClicked(res, authType)}
        render={(renderProps) => (
          <SocialAuthButton
            authType={authType}
            socialType="Facebook"
            onClick={renderProps.onClick}
          />
        )}
      /> */}
      <AppleLogin
        usePopup={true}
        clientId={APPLE_CLIENT_ID}
        redirectURI="https://twotwentyk.gamebuild.co"
        callback={(res) => handleAppleAuth(res)}
        responseType="id_token"
        scope="email"
        render={(renderProps) => (
          <SocialAuthButton
            authType={authType}
            socialType="Apple"
            onClick={renderProps.onClick}
          />
        )}
      />
      <AuthDividerWrapper>
        <span>Or</span>
      </AuthDividerWrapper>
    </SocialButtonsWrapper>
  );
};
